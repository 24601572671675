import React from 'react';

import ContentImage from '../../../assets/image/mobile/content/home_content_download.png'
import Apple from '../../../assets/icon/component/download/apple.svg';
import Google from '../../../assets/icon/component/download/google.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AppStoreLink, PlayStoreLink} from '../../../data/urls';

const MainDownloadComponent = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const pressApple = () => {
        window.open(AppStoreLink, "_blank")        
    }

    const pressGoogle = () => {
        window.open(PlayStoreLink,  "_blank")
    }

    return (
        <div style={styles.component04} >

            <div style={{height : 40}}/>
            
            <div style={styles.textContainer01}>
                <span style={styles.downloadTitleText}>
                    우리 아이 돌봐줄
                </span>

                <span style={styles.downloadTitleText}><span style={styles.downloadTitleBoldText}>집 근처 견주/집사님</span>을</span>
                <span style={styles.downloadTitleText}>
                    지금 바로 만나보세요
                </span>
            </div>

            <div style={{height : 30}}/>
            

            <div style={styles.component05} >
                <img src={Apple} alt="애플다운로드" style={styles.downloadButton} className="downloadButton" onClick={() => pressApple()}/>
                <img src={Google} alt="구글다운로드" style={styles.downloadButton}  className="downloadButton" onClick={() => pressGoogle()}/>
            </div>

            <div style={{height : 40}}/>
            
            <img 
                src={ContentImage}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>
    )
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    component04: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"#C7B4FF",alignItems:"flex-start", justifyContent:"flex-start"},
    component05: {width:"100%", display:"flex", boxSizing:"border-box", flexDirection:"row", alignItems:"center", justifyContent:"space-between",paddingLeft:"24px", paddingRight:"24px"},
    downloadButton: {width:"40.6250vw"},
    componentImage02: {width:"100vw"},
    downloadTitleText: {fontFamily:"NanumSquareNeo", fontSize:"5vw", lineHeight:"7vw", fontWeight:"700", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#1B1D1F"},
    downloadTitleBoldText: {fontFamily:"NanumSquareNeo", fontSize:"6.25vw", lineHeight:"8.75vw", fontWeight:"900", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#1B1D1F"},
  
});

export default MainDownloadComponent