import React from 'react';
import { Helmet } from "react-helmet-async";

const AppLinksScreen = ({...props}) : React.ReactElement => {

    React.useEffect(() => {
        // 앱 시작할때 처음으로 열기
        if (typeof window !== 'undefined') {
            // 네이티브 환경(iOS/Android)에서만 앱 링크를 시도
            const appLink = navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') 
              ? 'com.universepet://' // iOS에 맞는 앱 링크
              : 'universepet://open'; // Android에 맞는 앱 링크

            // ios 되는 링크 https://apps.apple.com/kr/app/id1597844235?uo=4&at=11l6hc&ct=fnd
          
            const fallbackUrl = navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') 
              ? 'https://apps.apple.com/kr/app/id1597844235?uo=4&at=11l6hc&ct=fnd' // iOS에 맞는 앱 링크
              : 'https://play.google.com/store/apps/details?id=com.universepet.universepet'; // Android에 맞는 앱 링크
          
            // 앱 링크 시도
            const timeout = setTimeout(() => {
              if (fallbackUrl) {
                window.location.href = fallbackUrl; // 앱 링크 열리지 않으면 fallback URL로 리다이렉트
              }
            }, 2000); // 2초 후 리다이렉트
          
            // 앱 링크 열기 시도
            window.location.href = appLink;
          
            // 타임아웃 처리 후 fallback URL 열기
            setTimeout(() => {
              clearTimeout(timeout);
              window.location.href = fallbackUrl;
            }, 2000); // fallback URL로 리다이렉트 시도
          } 
          else {
            
          }
    }, [])
 
    return (
        <div className="screen">
            <Helmet>
                <title>우리 주변의 펫, 우주펫</title>
                <meta name="우리 주변의 펫, 우주펫" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta name="description" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우리 주변의 펫, 우주펫"/>
                <meta property="og:url" content="https://spacepet.site/"/>
                <meta property="og:description" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="우리 주변의 펫, 우주펫"/>
            </Helmet>

            테스트중입니다.
        </ div>
    )
}

export default AppLinksScreen;

// adb shell am start -a android.intent.action.VIEW -d "intent://spacepet.site/#Intent;scheme=intent;package=com.universepet.universepet;end;"