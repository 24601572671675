import React from 'react';
import ComponentImage01 from '../../../assets/images/main/web/component-01.png';
import ImageComponent from '../../common/Image.component';
import { useNavigate } from "react-router-dom";
import Logo from '../../../assets/icon/common/logo_color.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainWeb01Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDeskTop = useMediaQuery(theme.breakpoints.down('md'));
    const styles = getStyles(isMobile, isDeskTop); 
    const navigate = useNavigate();

    const onSitter = () => {
        navigate("/sitter");
    }

    const onPartner = () => {
        navigate("/partner");
    }

    return (
       <>
        <div style={styles.webComponent}>                
            <div style={styles.divBoxHeightVh}/>
            <div style={styles.webRowContainer03}>
                <ImageComponent
                    src={Logo}
                    alt=""
                    style={styles.webImage09}
                />

                <div  style={styles.webRowContainer04} >
                    <span style={styles.webTitleButtonText} onClick={() => onSitter()}>
                        이웃시터 지원하기
                    </span>

                    <div style={{width : 10}}/>

                    <span style={styles.webTitleButtonText} onClick={() => onPartner()} >
                        파트너 등록하기
                    </span>
                </div>
            </div>

            <div style={styles.webRowContainer01} >               
                <div style={styles.webTextContainer01} >

                    <div style={{height : 70}}/>
                    <span style={styles.webTitleText} >
                        우리 아이 돌봐줄 사람<br/>
                        여기 다 있어요!
                    </span>

                    <div style={{height : 12}}/>
                    <span style={styles.webContentText} >
                        출장, 여행 등으로 강아지 고양이<br/>
                        돌봐줄 사람 필요할 때 쉽고 빠르게 찾아보세요!
                    </span>

                    <div style={{height : 27}}/>
                    <span style={styles.webContentMainColorBoldText}>
                        #반려인  #펫시터  #펫호텔∙유치원
                    </span>
                </div>

                <ImageComponent
                    src={ComponentImage01}
                    alt="컴포넌트 이미지"
                    style={styles.webImage01}
                />
            </div>
        </div>
       </>
    )
}

const getStyles = (isMobile: boolean, isDeskTop:boolean): Record<string, React.CSSProperties> => ({
    webComponent: {width: "100vw", display: "flex", flexDirection: "column",backgroundColor: "white", alignItems: "flex-start", justifyContent: "flex-start", boxSizing: "border-box", paddingLeft: isDeskTop ? "6.1111vw":"9.3750vw",},
    divBoxHeightVh: {height : "15vh" },
    webRowContainer03: {width: "100vw", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", boxSizing: "border-box",margin:"0px",  paddingRight: isMobile ? "6.1111vw" : "9.3750vw",  },
    webImage09: {width:"4.5833vw", height:"4.5833vw" },
    webRowContainer04: {width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-end",margin:"0px", paddingRight: isMobile ? "6.1111vw" : "9.3750vw", },
    webTitleButtonText: {fontFamily:"Pretendard", fontSize:"1.6667vw", fontWeight:"500", lineHeight:"2.0000vw", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#454A50", padding:"1.3889vw 1.5278vw" },
    webRowContainer01: {width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"space-between", padding:"0px",margin:"0px"},
    webTextContainer01: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"},
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webContentText: {fontFamily:"Pretendard", fontSize:"1.75vw", lineHeight:"2.5vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0", color:"#62686D" },
    webContentMainColorBoldText: {fontFamily:"Pretendard", fontSize:"2.29vw", lineHeight:"2.75vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0", color:"#9F6BFF" },
    webImage01: {width:"60.3472vw", height:"61.1111vw", margin:"0", padding:"0"},
});


export default MainWeb01Component