import React from 'react';

import QuestionColor from '../../../assets/icon/mobile/faq/quertion_color.svg';
import Question from '../../../assets/icon/mobile/faq/question.svg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const MainQnaComponent = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const titles = [
        "돌봄품앗이가 뭔가요?",
        "우주펫은 무료서비스인가요?",
        "현재 오픈된 지역은 어디인가요?",
        "반려동물을 키운 경험이 있거나, 본가에 반려동물이 있는데 서비스 이용이 가능한가요?"
    ];

    const contents = [
        "반려동물 돌봄이 필요할 때, 우리동네 견주/집사님과 돌봄을 주고 받는 것을 의미해요. 우주펫에서 친하게 지낼 이웃 반려인을 찾아보세요.",
        "우주펫의 품앗이 이웃 찾기는 무료 서비스에요. 부담없이 우리동네 반려인들과 소통을 시작해보세요.",
        "우주펫은 전국 어디서든 이용할 수 있어요.",
        "네, 현재 반려동물을 키우고 있지 않더라도 반려동물을 키운 경험만 있다면 우주펫 가입이 가능해요."
    ];

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);

    const press01 = () => {
        setVisible01(!visible01);
    }

    const press02 = () => {
        setVisible02(!visible02);
    }

    const press03 = () => {
        setVisible03(!visible03);
    }

    const press04 = () => {
        setVisible04(!visible04);
    }


    return (
        <div style={styles.faq}>

            <p style={styles.faqTitle} >자주 묻는 질문</p>

            <div style={{height : "9.3750vw"}}/>
            
            <div style={(visible01 ? styles.faqCon01 : styles.faqCon )} onClick={() => press01()}>

                <img src={(visible01)? QuestionColor : Question } alt="로고" style={styles.faqIcon}/>
           
                <div style={styles.faqCon02} >
                    <p style={(visible01 ? styles.faqTitle02 : styles.faqTitle01 )}>{titles[0]}</p>

                    {(visible01)?
                        <p style={styles.faqContent}>{contents[0]}</p>
                    :
                        null
                    }
                </div>
            </div>
            
            <div style={(visible02 ? styles.faqCon01 : styles.faqCon )} onClick={() => press02()}>

                <img src={(visible02)? QuestionColor : Question } alt="로고" style={styles.faqIcon}/>
               
                <div style={styles.faqCon02}>
                    <p  style={(visible02 ? styles.faqTitle02 : styles.faqTitle01 )}>{titles[1]}</p>

                    {(visible02)?
                        <p style={styles.faqContent}>{contents[1]}</p>
                    :
                        null
                    }
                </div>
            </div>
            
            <div style={(visible03 ? styles.faqCon01 : styles.faqCon )} onClick={() => press03()}>

                <img src={(visible03)? QuestionColor : Question } alt="로고" style={styles.faqIcon}/>

                <div style={styles.faqCon02}>
                    <p style={(visible03 ? styles.faqTitle02 : styles.faqTitle01 )}>{titles[2]}</p>

                    {(visible03)?
                        <p style={styles.faqContent}>{contents[2]}</p>
                    :
                        null
                    }
                </div>
            </div>
            
            <div style={(visible04 ? styles.faqCon01 : styles.faqCon )} onClick={() => press04()}>

                <img src={(visible04)? QuestionColor : Question } alt="로고" style={styles.faqIcon}/>

                <div style={styles.faqCon02}>
                    <p style={(visible04 ? styles.faqTitle02 : styles.faqTitle01 )}>{titles[3]}</p>

                    {(visible04)?
                        <p style={styles.faqContent}>{contents[3]}</p>
                    :
                        null
                    }
                </div>
            </div>

        </div>
    )
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    faq: {paddingTop:"12.5vw", paddingBottom:"12.5vw"},
    faqTitle: {marginLeft:"7.5vw", marginTop:"0px", marginBottom:"0px", fontSize:"6.25vw", fontWeight:"700", color:"#1B1D1F"},
    faqCon01: {padding:"4.3750vw 7.5vw 4.3750vw 7.5vw", display:"flex", flexDirection:"row", alignItems:"flex-start", backgroundColor:"#FAFAFB"},
    faqCon: {padding:"4.3750vw 7.5vw 4.3750vw 7.5vw", display:"flex", flexDirection:"row", alignItems:"flex-start", backgroundColor:"#FFFFFF"},
    faqCon02: {display:"flex", flexDirection:"column",alignItems:"flex-start", justifyContent:"flex-start", padding:"0 0 0 0"},
    faqIcon: {width:"6.8750vw", height:"6.8750vw", marginRight:"1.8750vw"},
    faqContent: {fontSize:"4.3750vw", fontWeight:"400", letterSpacing:"-2%", lineHeight:"7vw", color:"#62686D", margin:0, marginTop:"2.5vw"},
    faqTitle02: {fontSize:"5vw", fontWeight:"600", lineHeight:"7vw", letterSpacing:"-1%", color:"#1B1D1F", margin:"0 0 0 0"},
    faqTitle01: {fontSize:"5vw", fontWeight:"400", lineHeight:"7vw", letterSpacing:"-1%", color:"#454A50", margin:"0 0 0 0"},
  
});


export default MainQnaComponent