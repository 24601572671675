import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageComponent from '../../common/Image.component';

import Apple from '../../../assets/icon/component/download/apple.svg';
import Google from '../../../assets/icon/component/download/google.svg';
import ContentImage from '../../../assets/image/mobile/content/home_content_download.png'
import { AppStoreLink, PlayStoreLink,BrowserLink } from '../../../data/urls';
import { isIOS ,isBrowser} from 'react-device-detect';
const MainWebDownloadComponent = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const pressApple = () => {
        if(isBrowser){
            window.open(BrowserLink);
        }else {
            window.open(AppStoreLink, "_blank")
        }
    }

    const pressGoogle = () => {
        if(isBrowser){
            window.open(BrowserLink);
        }else {
            window.open(PlayStoreLink,  "_blank")
        }
    }

    return <div style={styles.webComponent08}>

        <div style={styles.webColContainer01}>
            <span style={styles.webDownloadTitleText}>
                우리 아이 돌봐줄<br/>
                
                <span style={styles.webDownloadSemiTitleText}>집 근처 견주/집사님</span>을<br/>
                지금 바로 만나보세요
            </span>

            <div style={{height : 40}}/>
            <div style={styles.webRowContainer01} >
                <div  onClick={() => pressApple()}>
                    <ImageComponent 
                        src={Apple}
                        alt=""
                        style={styles.webImage08}
                        className='webImage08'
                    />
                </div>

                <div style={{width : 25}}/>
                <div  onClick={() => pressGoogle()}>
                    <ImageComponent 
                        src={Google}
                        alt=""
                        style={styles.webImage08}
                        className='webImage08'
                    />
                </div>
            </div>
        </div>

        <ImageComponent 
            src={ContentImage}
            style={styles.webImage07}
            alt="imageComponent"
        />
    
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent08: {padding : isMobile? "35px 6.1111vw 35px 6.1111vw":"35px 9.3750vw 35px 9.3750vw" , width:"100%", display:"flex", flexDirection: "row", alignItems:"center", justifyContent:"space-between",backgroundColor:"#C7B4FF", boxSizing:"border-box"},
    webColContainer01:{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"},
    webDownloadTitleText:{fontFamily:"NanumSquareNeo", fontSize:"2.22vw", lineHeight:"3.11vw", fontWeight:"700", letterSpacing:"-0.04em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#1B1D1F"},
    webDownloadSemiTitleText:{fontFamily:"NanumSquareNeo", fontWeight:"900", fontSize:"2.78vw", lineHeight:"3.89vw", letterSpacing:"-0.02em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#1B1D1F" },
    webRowContainer01: {width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"space-between", padding:"0px",margin:"0px"},
    webImage08: {width:"18.0556vw", height:"4.7222vw"},
    webImage07: {width:"44.4444vw", height:"26.3889vw"},
});




export default MainWebDownloadComponent;