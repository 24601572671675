import React from 'react';

import TitleLogo from '../../../assets/icon/partner/logo.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component01 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.partnerIntroScreen01} >
        
        <img src={TitleLogo} style={styles.introTitle} alt=""/>
        
        <span style={styles.introTitleTxt}>
            <span style={{color : "#8B4DFF"}}>펫호텔・유치원</span>과<br/>
            돌봄이 필요한 반려인을<br/>
            연결해 드립니다.<br/>
        </span>

        <div></div>
    
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    partnerIntroScreen01: { width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', paddingTop: '5.1563vw', paddingBottom: '5.1563vw', margin: 0, boxSizing: 'border-box' },
    introTitle: { width: '38.0406vw' },
    introTitleTxt: {fontFamily: "Pretendard", fontSize: "8.1250vw", fontWeight: 700, lineHeight: "11.3750vw", letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#1B1D1F"},
});


export default Component01;