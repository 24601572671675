import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageComponent from '../../common/Image.component';


import Component030101 from '../../../assets/images/main/web/component-030101.png';
import Component030102 from '../../../assets/images/main/web/component-030102.png';
import Component030103 from '../../../assets/images/main/web/component-030103.png';
import Component030201 from '../../../assets/images/main/web/component-030201.png';
import Component030202 from '../../../assets/images/main/web/component-030202.png';
import Component030203 from '../../../assets/images/main/web/component-030203.png';
import Component030301 from '../../../assets/images/main/web/component-030301.png';
import Component030302 from '../../../assets/images/main/web/component-030302.png';
import Component030303 from '../../../assets/images/main/web/component-030303.png';

interface Main03ContentComponentProps {
    data: Array<boolean>;
}
  
const MainWeb03ContentComponent : React.FC<Main03ContentComponentProps> = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    
    if (data[0]) {
        return <>            
            <div style={styles.webComponent02}>

                <div style={styles.webRowContainer01} >
                    <div style={styles.webTextContainer01}>
                        <span style={styles.webComponentTitleText} >서로 도와주는 이웃 반려인</span>
    
                        <div style={{height : 74}}/>
                        <span style={styles.webSemiTitleText}>
                            주고받는 돌봄품앗이
                        </span>
    
                        <div style={{height : 6}}/>
                        <span style={styles.webTitleText}>
                            이웃 반려인들과<br/>
                            돌봄  주고받기
                        </span>
    
                        <div style={{height : 16}}/>
                        <span style={styles.webContentText}>
                            여기 품앗이를 원하는 이웃들이 모여있어요.<br/>
                            우리 동네 사람들과 도움을 주고받아 보세요.
                        </span>
                    </div>
    
                    <div style={styles.webColContainer01}>
                        <div style={{height : 11}}/>
                        <ImageComponent
                            src={Component030101}
                            style={styles.webImage03}
                            alt="imageComponent"
                        />

                    </div>
                </div>
            </div>
    
            <div style={styles.webComponent03}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            품앗이 이웃 찾는 방법 
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            우리 동네<br/>
                            품앗이 이웃 찾기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            먼저 품앗이 이웃을 찾는 글을 작성하고,<br/>
                            우리 동네 품앗이 이웃을 만나보세요!
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030102}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                    
                </div>

                <div style={{height : 72}}/>
            </div>
    
            <div style={styles.webComponent02}>

                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            품앗이 일정 공유
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            품앗이 이웃과<br/>
                            돌봐줄 약속 잡기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            품앗이 나눌 이웃을 찾으셨나요?<br/>
                            원하는 날짜에 약속을 잡아보세요!
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030103}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
        </>
    }
    else if (data[1]) {
        return <>
            <div style={styles.webComponent02}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <span style={styles.webComponentTitleText}>전문성을 갖춘 펫시터</span>
    
                        <div style={{height : 74}}/>
    
                        <span style={styles.webSemiTitleText}>
                            우리 동네 전문 이웃시터
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            전문적인 펫시터에게<br/>
                            아이 돌봄 부탁하기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            전문적으로 돌봐주는 펫시터들이 모여있어요.<br/>
                            심사를 통과한 시터들이니 믿고 부탁해 보세요!
                        </span>
                    </div>
    
                    <div style={styles.webColContainer01}>
                        <div style={{height : 11}}/>
    
                        <ImageComponent 
                            src={Component030201}
                            style={styles.webImage03}
                            alt="imageComponent"
                        />
                    </div>
                </div>
            </div>
    
            <div style={styles.webComponent03}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            돌봄 요청 글 작성
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            이웃들에게<br/>
                            돌봄・산책 요청하기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            돌봐줄 사람이 필요하다면 도움을 요청해 보세요.<br/>
                            도와줄 수 있는 사람을 더 빨리 찾을 수 있어요.
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030202}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
    
            <div style={styles.webComponent02}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            이웃 시터 찾는 방법 
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            우리 동네<br/>
                            이웃 시터 찾기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            우주펫에서 원하는 시터를 찾아 연락해 보세요.<br/>
                            <span style={styles.contentBoldText} >방문∙위탁</span>이 가능한 시터들이 있답니다.
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030203}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
        </>
    }
    else {
        return <>
            <div style={styles.webComponent02}>

                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>    
                    <div style={styles.webTextContainer01}>
                        <span style={styles.webComponentTitleText}>체계적인 펫호텔・유치원</span>
    
                        <div style={{height : 74}}/>
    
                        <span style={styles.webSemiTitleText}>
                            펫호텔・유치원 한 눈에 비교
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            원하는 조건의 펫호텔・유치원<br/>
                            한 눈에 찾기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            우리 동네 펫호텔・유치원을 한 눈에 비교할 수 있어<br/>
                            원하는 조건을 쉽고 빠르게 찾을 수 있어요.
                        </span>
                    </div>
    
                    <div style={styles.webColContainer01}>
                        <div style={{height : 11}}/>
    
                        <ImageComponent 
                            src={Component030301}
                            style={styles.webImage03}
                            alt="imageComponent"
                        />
                    </div>
                </div>

            </div>
    
            <div style={styles.webComponent03}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            요금표 정리
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            깔끔하게 정리된<br/>
                            매장별 요금 비교하기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            매장별 요금표 비교 너무 복잡하지 않았나요?<br/>
                            우주펫에서 한 눈에 매장 요금 비교해 보세요!
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030302}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
    
            <div style={styles.webComponent02}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>
    
                        <span style={styles.webSemiTitleText}>
                            쉽고 빠른 예약 요청
                        </span>
    
                        <div style={{height : 6}}/>
    
                        <span style={styles.webTitleText}>
                            간편 문의로<br/>
                            간단하게 예약 요청하기
                        </span>
    
                        <div style={{height : 16}}/>
    
                        <span style={styles.webContentText}>
                            전화로 이것저것 이야기하지 않아도<br/>
                            간단한 정보만으로 10초만에 문의할 수 있어요.
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={Component030303}
                        style={styles.webImage04}
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
        </>
    }
    
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent02: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:"flex-start", boxSizing:"border-box", paddingLeft: isMobile? "6.1111vw":"9.3750vw", paddingRight:isMobile? "0vw":"0vw" },
    webRowContainer01: {width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"space-between", padding:"0px", margin:"0px"},
    webTextContainer01: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"},
    webComponentTitleText: {fontFamily:"Pretendard", fontSize:"2.7778vw", fontWeight:"700", lineHeight:"3.3333vw", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font",textDecorationSkipInk:"none", color:"#9F6BFF"},
    webSemiTitleText: {fontFamily:"Pretendard", fontSize:"2vw", fontWeight:"700", lineHeight:"2.5vw", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font",textDecorationSkipInk:"none", color:"#9F6BFF", padding:"0", margin:"0"},
    webTitleText:{fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left",textUnderlinePosition:"from-font",textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webContentText:{fontFamily:"Pretendard", fontSize:"1.75vw", lineHeight:"2.5vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font",textDecorationSkipInk:"none", padding:"0", margin:"0", color:"#62686D" },
    webColContainer01:{display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"},
    webImage03:{width:"55.5556vw", height:"46.875vw", boxSizing:"border-box", padding:"0px", margin:"0px", marginRight:isMobile? "4.17vw" : "5.21vw"},
    webComponent03:{width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"#F9F5FF",alignItems:"flex-start", justifyContent:"flex-start", boxSizing:"border-box", outline:"none",  padding: isMobile ? "0px 0px 0px 6.1111vw" : "0px 0px 0px 9.3750vw"},
    webImage04:{width:"54.2361vw", height:"61.1111vw", boxSizing:'border-box', padding:"0px", margin:"0px", marginRight: isMobile?"6.1111vw" : "9.375vw" },
    contentBoldText:{fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left",textUnderlinePosition:"from-font",textDecorationSkipInk:"none", padding:"0", margin:"0",  },
});



export default MainWeb03ContentComponent