import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeleteTermScreen = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return (
        <div style={styles.termCon} >
            
            <div style={styles.titleCon} >
                <p style={styles.titleText} >개인정보삭제처리방침</p>
            </div>

            <div style={styles.semiTitleCon} >
                <p style={styles.semiTitleText} >우주펫 개인정보 삭제처리방침</p>

                <p style={styles.contentText} >"주식회사 우주펫(Spacepet LTD)”(이하 “회사”)은 다음과 같은 방법으로 이용자의 개인정보 삭제 방법을 제공합니다.<br/>사용자가 계정 삭제를 요청하기 위해 취해야 할 단계는 다음과 같습니다.</p>
            
            </div>

            <div style={styles.semiTitleCon} >
                
                <p style={styles.semiTitleText} >1. 앱 내 회원탈퇴 기능을 통한 데이터 삭제</p>
                <p style={styles.contentText} >우주펫은 앱 내 회원탈퇴 기능을 통해 유저의 회원 탈퇴를 진행하며 개인정보를 삭제기능을 지원합니다.</p>

                <br/>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>•</p>
                    <p style={styles.contentText}>동의정보 철회를 통한 데이터 삭제</p>                    
                </div>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>1.</p>
                    <p style={styles.contentText}>앱 내 "더보기"탭으로 이동</p>
                </div>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>2.</p>
                    <p style={styles.contentText}>오른쪽 상단 설정(톱니바퀴 아이콘) 터치</p>
                </div>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>3.</p>
                    <p style={styles.contentText}>최하단 "회원탈퇴" 버튼 터치</p>
                </div>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>4.</p>
                    <p style={styles.contentText}>추가 팝업창 내용 확인 뒤 "회원탈퇴" 버튼 터치</p>
                </div>

                <br/>

                <p style={styles.semiTitleText}>2. 이메일을 통한 데이터 삭제</p>
                <p style={styles.contentText}></p>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>•</p>
                    <p style={styles.contentText}>이름 : 고범석<br/>직위 : 개인정보보호 책임자<br/>부서 : 개발팀<br/>연락처 : spacepetofficial@gmail.com</p>
                </div>

                <div style={styles.contentCon} >
                    <p style={styles.contentSequenceNum}>•</p>
                    <p style={styles.contentText}>운영자에게 이메일로 문의주시면 지체없이 조치 해 드리도록 하겠습니다.</p>                    
                </div>
                
                <br/>
            </div>

            <div style={styles.dateCon}>
                <p style={styles.dateText}>공고일자 2023년 08월 11일</p>
                <p style={styles.dateText}>시행일자 2023년 08월 11일</p>
            </div>

        </div>
    )
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    termCon: { display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'start', justifyContent: 'start' },
    titleCon: { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    titleText: { fontWeight: 700, fontSize: '2.5vw', textAlign: 'center', marginBottom: '60px' },
    semiTitleCon: { width: '100%', paddingLeft: '20%', paddingRight: '20%', boxSizing: 'border-box', marginBottom: '20px' },
    semiTitleText: { fontWeight: 600, fontSize: '1vw', marginTop: '0px', marginBottom: '10px' },
    contentCon: { width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyItems: 'flex-start', marginTop: '0px', marginBottom: '0px' },
    contentSequenceNum: { fontWeight: 500, fontSize: '0.9375vw', marginRight: '20px', marginTop: '5px', marginBottom: '5px' },
    contentText: { fontWeight: 500, fontSize: '0.9375vw', marginTop: '5px', marginBottom: '5px' },
    dateCon: { width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '50px', marginBottom: '50px' },
    dateText: { fontWeight: 500, fontSize: '0.9375vw', marginTop: '5px', marginBottom: '5px', textAlign: 'center' }

  
});


export default DeleteTermScreen;