import React from "react";
import { CDN } from "../../../keys";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component02 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

   
    return (
        <> 
            <div style={styles.grdientDiv} >
                <p style={styles.grdientTxt01}>
                    우주펫 파트너가 되면
                </p>

                <div style={{height : "7.9310vh"}}/>

                <p style={styles.grdientTxt02} >
                    우주펫에서만 할 수 있는
                </p>
            </div>

            <p style={styles.titleTxt02} >
                반려인에게 직접 제안
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p style={styles.contentTxt}  >
                돌봄 요청한 고객에게 직접 제안하세요!<br/>
                파트너는 돌봄이 필요한 반려인에게<br/>
                먼저 돌봄을 제안할 수 있어요
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_002.png`} alt="" style={styles.componentImg} />

        </>

    );
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    grdientDiv: { background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #F9F5FF 70%)", width: "100vw", paddingTop: "40px", paddingBottom: "10px", },  
    grdientTxt01: { paddingLeft: "7.5vw", fontFamily: "'Pretendard'", fontSize: "6.2500vw", lineHeight: "7.5000vw", letterSpacing: "-0.01em", fontWeight: 800, textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#8B4DFF", },  
    grdientTxt02: { color: "#9F6BFF", paddingLeft: "7.5vw", margin: 0, fontFamily: "'Pretendard'", fontSize: "5.0000vw", lineHeight: "6.0000vw", fontWeight: 700, letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", },  
    titleTxt02: { padding: 0, margin: 0, paddingLeft: "7.5vw", fontSize: "6.8750vw", fontFamily: "'Pretendard'", fontWeight: 700, letterSpacing: "-3%", lineHeight: "100%", color: "black", whiteSpace: "nowrap", textShadow: "none", },  
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width:"100vw" },
});

export default Component02;