import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ContentImage from '../../../assets/images/main/mobile/component_07.png'
import Component05Image from '../../../assets/images/main/mobile/component_05.png'
import Component06Image from '../../../assets/images/main/mobile/component_06.png'



const Main05Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return (
       <>
        <div style={styles.component}>
            <div style={{height : 60}}/>
            <img src={ContentImage} alt="컨텐츠"  style={styles.componentImage02}/>
        </div>
        
        <div style={styles.component03} >
            <div style={styles.textContainer01} >
                <div style={{height : 40}}/>

                <span style={styles.semiTitleText}>
                    산책친구
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText}>
                    우리아이와 잘 맞는<br/>
                    산책친구 만나기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    우주펫에서 산책 친구를 만나보세요.<br/>
                    거리∙시간대∙보호자 등에 맞게 원하는 친구를 찾을 수<br/>
                    있어요.
                </span>
            </div>

            <img 
                src={Component05Image}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>

        <div style={styles.component}>
            <div style={styles.textContainer01}>
                <div style={{height : 40}}/>

                <span style={styles.semiTitleText}>
                    응가챌린지
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText}>
                    반려동물 배변용품<br/>
                    쓴 만큼 돌려받기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    사용한 배변용품을 인증하고 쓴 만큼 받아보세요.<br/>
                    우주펫이 집 앞으로 배변용품을 보내드립니다!
                </span>
            </div>

            <img 
                src={Component06Image}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>
       </>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    component: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:'flex-start'},
    componentImage02: {width:"100vw"},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    semiTitleText: {fontFamily:"Pretendard", fontSize:"5vw", lineHeight:"6vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:0,margin:0,color:"#9F6BFF"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    contentText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D"},
    component03: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"#F9F5FF", alignItems:"flex-start",justifyContent:"flex-start"},
});

export default Main05Component