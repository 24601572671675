import React from 'react';

import ZeroMoney from '../../../assets/icon/sitter/mobile/zero_money.svg';
import Calendar from '../../../assets/icon/sitter/mobile/calendar.svg';
import IconBook from '../../../assets/icon/sitter/mobile/icon_book.svg';

const Content02BrowserComponent = ({...props}) : React.ReactElement => {

    return <>
    <div style={styles.screen}>
    <span style={styles.titleTxt}>
        왜 우주펫에서 해야하나요?
    </span>
    <div style={styles.screenRow}>
        <div style={{height : 40}}/>

        <div style={styles.screenColumn}>

            <img src={ZeroMoney} style={styles.icon} alt="수수료없음"/>

            <span style={styles.subTitleTxt}>
                수수료 0원
            </span>

            <span style={styles.contentTxt}>
            보호자님에게 받은 돌봄 비용,<br/>
                그대로 가져가세요
            </span>
        </div>

            <div style={{height : 56}}/>
        <div style={styles.screenColumn}>
            <img src={Calendar} style={styles.icon} alt="수수료없음"/>

            <span style={styles.subTitleTxt}>
                자유로운 스케줄 관리
            </span>

            <span style={styles.contentTxt}>
            내가 원하는 날에만<br/>
                돌봄을 할 수 있어요
            </span>
        </div>
            <div style={{height : 56}}/>

        <div style={styles.screenColumn}>
            <img src={IconBook} style={styles.icon} alt="수수료없음"/>

            <span style={styles.subTitleTxt}>
            우주펫 시터 입문 교육 제공
            </span>
    
            <span style={styles.contentTxt}>
            반려동물과 펫시팅에 대한<br/>
                기본 지식을 갖출 수 있어요
            </span>
        </div>
        </div>
    </div>
    </>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "center",
        paddingTop : 50,
        paddingBottom : 59,
        paddingLeft:"6.1111vw",
        paddingRight:"6.1111vw",
    },
    screenRow : {
        display : "flex",
        flexDirection : "row",
      
    },
    screenColumn : {
        width : 516,
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "center",
        paddingTop : 50,
        paddingBottom : 59,
        paddingRight : 60,
    },
    titleTxt : {
        fontSize : 40,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft : 50,
        lineHeight : "140%"
    },
    subTitleTxt : {
        fontSize : 34,
        fontWeight : 700,
        color : "#9F6BFF",
        letterSpacing : -0.3,
        marginTop : 16,
        marginBottom : 6,
        lineHeight : "120%"
    },
    contentTxt : {
        fontSize : 28,
        fontWeight : 400,
        color : "#62686D",
        letterSpacing : -0.3,
        textAlign : "center",
        lineHeight : "140%"
    },
    icon : {
        width : 116,
        height : 116,
    }
}

export default Content02BrowserComponent;