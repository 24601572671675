import React from 'react';

import QuestionOnIcon from '../../../assets/icon/sitter/mobile/question_on.svg';
import QuestionOffIcon from '../../../assets/icon/sitter/mobile/question_off.svg';



const Content07BrowserComponent = ({...props}) : React.ReactElement => {

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);    
    const [visible05, setVisible05] = React.useState(false);    

    return <div style={styles.screen}>
        <span style={styles.title}>
            자주 묻는 질문
        </span>

        <div style={{height : "4.6875vh"}}/>

        <div style={styles.divBoxPadding}>
            <div style={visible01? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible01(!visible01)}}>
                <div style={styles.rowCon}>
                    {
                        (visible01)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                    }
                    <span style={(visible01)? styles.titleTxt : styles.unSelectInfoTxt}>
                        중개 비용 및 중개 수수료가 있나요?
                    </span>
                </div>
                {visible01 ? (
                    <div style={styles.colCon}>
                        <span style={styles.contentTxt}>
                            없어요. 우주펫은 채팅 및 돌봄거래의 모든 과정에서 이용료 및 수수료를 받고 있지 않아요.
                        </span>
                    </div>
                ) : null}

            </div>
          

            <div style={visible02? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible02(!visible02)}}>
                <div style={styles.rowCon}>
                    {
                        (visible02)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                    }
                        <span style={(visible02)? styles.titleTxt : styles.unSelectInfoTxt}>
                            돌봄이 필요한 이웃은 어떻게 찾나요?
                        </span>
                </div>       
                    {
                        (visible02)? 
                        <div style={styles.colCon}>
                            <div style={styles.rowCon}>
                                <span style={styles.numTxt}>
                                    1. 
                                </span>
                                <span style={styles.contentTxt}>
                                &nbsp; 돌봄 요청글을 보고 이웃시터님이 이웃에게 연락할 수 있어요.
                                </span>
                            </div>
                            <div style={styles.rowCon}>
                                <span style={styles.numTxt}>
                                    2.
                                </span>
                                <span style={styles.contentTxt}>
                                &nbsp;돌봄이 필요한 이웃이 이웃시터님의 프로필을 보고 채팅을 통해 연락할 수 있어요.
                                </span>
                            </div>
                        </div>
                        : null
                    }                    
            </div>


            <div style={visible03? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible03(!visible03)}}>
                <div style={styles.rowCon}>
                    {
                        (visible03)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                    }
                    
                        <span style={(visible03)? styles.titleTxt : styles.unSelectInfoTxt}>
                            이웃시터가 뭔가요?
                        </span>
                </div>
                    {visible03 ? (
                        <div style={styles.colCon}>
                            <span style={styles.contentTxt}>
                                이웃시터란 우주펫에서 활동하는 펫시터를 의미해요.
                            </span>
                        </div>
                    ) : null}
            </div>

            <div style={visible04? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible04(!visible04)}}>
                <div style={styles.rowCon}>
                    {
                        (visible04)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                    }
                
                        <span style={(visible04)? styles.titleTxt : styles.unSelectInfoTxt}>
                            비용은 어떻게 받나요?
                        </span>
                </div>
                        {visible04 ? (
                            <div style={styles.colCon}>
                                <span style={styles.contentTxt}>
                                    채팅방 내에서 예약을 통해 사례비를 결제 받으면 일정이 생성돼요.
                                </span>
                                <span style={styles.contentTxt}>
                                    돌봄이 완료되면 보호자님이 결제한 사례비를 지급받을 수 있어요.
                                </span>
                            </div>
                    ) : null}
                <div style={styles.borderSty}/>
            </div>

            <div style={visible05? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible05(!visible05)}}>
                <div style={styles.rowCon}>
                    {
                        (visible05)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                    }
                  
                        <span style={(visible05)? styles.titleTxt : styles.unSelectInfoTxt}>
                            필수 교육은 무엇인가요?
                        </span>
                </div>
                        {visible05 ? (
                             <div style={styles.colCon}>
                                <span style={styles.contentTxt}>
                                    우주펫과 전문 교육 기관인 하울팟이 공동 제작한 우주펫 시터 대상 교육이에요. 
                                </span>
                                <span style={styles.contentTxt}>
                                    이웃시터 활동을 위해 교육을 필수로 수강해주셔야 하며, 
                                    수강 후 테스트에 통과한 지원자분들만 이웃시터로 활동하실 수 있어요.
                                </span>
                            </div>
                        ) : null}
                    </div>
        </div>
        

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : 50,
        paddingBottom : 50,
        paddingLeft:"6.1111vw",
        paddingRight:"6.1111vw"
    },
    title : {
        fontSize : 40,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft: "70px",
        lineHeight : "120%",
        
    },
    titleTxt : {
        fontSize : 28,
        fontWeight : 600,
        letterSpacing : -0.3,        
        color : "#000000",
        lineHeight : "140%",
   
    },
    unSelectInfoTxt : {
        fontSize : 28,
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#454A50",
        lineHeight : "140%",
             marginTop:"5px"
    },
    numTxt : {
        fontSize :24,
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
    },
    contentTxt : {
        fontSize : 24,
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        lineHeight : "150%",
        
    },
    unSelectedCon : {
        width : "90%",
        paddingTop : 14,
        paddingBottom : 14,
        paddingLeft: 24,
        paddingRight : 24,       
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",
        boxSizing : "border-box",
        borderBottom: "0.1px solid #E8E8E9" 
    },
    
    selectedCon : {
        width : "100%",
        paddingLeft: 24,
        paddingRight : 24,  
        paddingTop : 14,
        paddingBottom : 20,
        display : "flex",
        flexDirection : "column",
        alignItems : "flex-start",
        justifyContent : "flex-start",  
        boxSizing : "border-box",

    },
    rowCon : {
        display : "flex",
        flexDirection : 'row',
        alignItems : "center",
        justifyContent : "flex-start",
        marginBottom:"5px"  
    },
    colCon : {
        display : "flex",
        flexDirection : 'column',
        alignItems : "flex-start",
        justifyContent : "center" ,
        backgroundColor : "#FAFAFB",
        padding: "10px 0px 10px 20px",
        width:"90%",
        borderRadius:"16px",
        marginTop:"10px",
        marginBottom:"5px"
    },

    questionIcon : {
        width : 39,
        height : 39,
        boxSizing : "border-box",
        marginRight : 6,
    },
    divBoxPadding:{
        paddingLeft:"50px"
    },

}

export default Content07BrowserComponent;