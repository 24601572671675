import React from "react";
import { CDN } from "../../../keys";

import Logo from '../../../assets/icon/common/logo_color.svg';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component01 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const navigate = useNavigate();

    const onMain = () => {
        navigate("/");
    }

    const onSitter = () => {
        navigate("/sitter");
    }

    return <div style={styles.webScreen} >

        <div style={styles.component01Header} >
            <img src={Logo} alt="" style={styles.component01Logo} />

            <div  style={styles.component01RowCon}>
                <div style={styles.component01Btn} onClick={() => onMain()}>
                    <p style={styles.component01Txt} >
                        우주펫 소개
                    </p>
                </div>

                <div style={{width : 10}}/>

                <div style={styles.component01Btn}  onClick={() => onSitter()}>
                    <p style={styles.component01Txt} >
                        이웃시터 지원하기
                    </p>
                </div>
            </div>
        </div>

        <div style={{height : 7}}/>
        
        <div style={styles.componentCon}>
            <div style={{display : "flex", flexDirection : "column", marginTop : 110}}>
                <p style={styles.webTitleTxt}> 
                    펫호텔, 유치원을 찾는<br/>
                    동네 반려인 여기 있어요!
                </p>                
                <div style={{height : 7}}/>
                <p style={styles.webSubTitleTxt}> 
                    언제까지 고객을 기다려야 하나요?<br/>
                    이제 도움이 필요한 반려인을 직접 찾아보세요.
                </p>  
            </div>
            
            <img src={`${CDN}web/partner/browser/image_001.png`} style={styles.image01} alt=""/>
        </div>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webScreen: { width: "100vw", boxSizing: "border-box", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none", userSelect: "none", minWidth: "800px", },
    component01Logo: { width: "66px" },
    component01RowCon: { display:"flex", flexDirection:"row", justifyContent:"center",alignItems:"center"},
    component01Btn: { paddingTop:"22px", paddingLeft:"20px", paddingRight:"20px", paddingBottom:"22px"},
    component01Txt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "24px", fontWeight: "500", lineHeight: "28.8px", letterSpacing: "-0.02em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#454A50", },
    webTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "40px", fontWeight: "700", lineHeight: "56px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#1B1D1F", },
    webSubTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "24px", fontWeight: "400", lineHeight: "33.6px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#62686D", },
    component01Header: { display:"flex", flexDirection:"row", justifyContent:"space-between",alignItems:"center",boxSizing:"border-box",paddingLeft: isMobile? "6.1111vw":"9.3750vw", paddingRight: isMobile? "6.1111vw":"9.3750vw", },
    componentCon: { display:"flex", margin:0 , flexDirection:"row", justifyContent:"space-between",alignItems:"flex-start", paddingLeft:isMobile? "6.1111vw":"9.3750vw"},
    image01: { width:"869px", height:"880px",padding:0, marginRight:isMobile ? 0 :"4.7917vw"},
});


export default Component01;