import React from 'react';
import TitleLogo from '../../../assets/icon/partner/logo.svg';
import MenuIcon from '../../../assets/icon/partner/menu.svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const PartnerHeader = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 
    const navigate = useNavigate();

    const onMenu = () => {
        navigate("/partner/menu");
    }

    return <div style={styles.header} >
        <img src={TitleLogo} alt="우주펫 파트너" style={styles.headerLogo} />
        <img src={MenuIcon} alt="메뉴" style={styles.menu}  onClick={() => onMenu()}/>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    header: { paddingTop: isMobile ? '24px' : '2.8448vh', paddingBottom: isMobile ? '34px' : '2.0690vh', paddingLeft: isMobile ? '6.1111vw' : '7.5vw',  paddingRight: isMobile ? '6.1111vw' : '7.5vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',width: '100vw', zIndex: 1000, boxSizing: 'border-box', top: 0, left: 0, position: 'fixed', backgroundColor: 'white',  }, 
    headerLogo: { width: isMobile ? '38.0406vw' : '286.27px', cursor: 'pointer', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }, 
    menu: { width: '8.4375vw', height: '8.4375vw', cursor: 'pointer' },
  
});

export default PartnerHeader;