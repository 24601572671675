import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageComponent from '../../common/Image.component';

import QuestionColor from '../../../assets/icon/mobile/faq/quertion_color.svg';
import Question from '../../../assets/icon/mobile/faq/question.svg';

const MainWebQnaComponent = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const [data, setData] = React.useState<Array<boolean>>([false, false, false, false]);
    const titles = [
        "돌봄품앗이가 뭔가요?",
        "우주펫은 무료서비스인가요?",
        "현재 오픈된 지역은 어디인가요?",
        "반려동물을 키운 경험이 있거나, 본가에 반려동물이 있는데 서비스 이용이 가능한가요?"
    ]
    const contents = [
        "반려동물 돌봄이 필요할 때, 우리동네 견주/집사님과 돌봄을 주고 받는 것을 의미해요. 우주펫에서 친하게 지낼 이웃 반려인을 찾아보세요.",
        "우주펫의 품앗이 이웃 찾기는 무료 서비스에요. 부담없이 우리동네 반려인들과 소통을 시작해보세요.",
        "우주펫은 전국 어디서든 이용할 수 있어요.",
        "네, 현재 반려동물을 키우고 있지 않더라도 반려동물을 키운 경험만 있다면 우주펫 가입이 가능해요."
    ]

    const onQna = (index : number) => {
        setData((prev) => prev.map((value, idx) => (idx === index? !value: value)));
    }

    return <div>

        <div style={styles.webComponent07}>

            <div style={{height : 90}}/>

            <div style={styles.webTextContainer01}>
               <span style={styles.webTitleText}>자주 묻는 질문</span>
            </div>

            <div style={{height : 60}}/>

            {
                data.map((value, index, array) => {
                    return <div style={{width : "100%"}}onClick={() => onQna(index)}>

                        <div style={styles.webRowContainer02} >
                            <ImageComponent 
                                src={value? QuestionColor : Question}
                                alt=""
                                style={styles.webQuestionIcon}
                            />     

                            <span style={(value ? styles.webQnaTitleTextTrue :styles.webQnaTitleTextFalse )} >
                                {titles[index]}
                            </span>                   
                        </div>

                        {
                            value? 
                                <div style={styles.webQnaComponent} >
                                    <span style={styles.webQnaContentText} >
                                        {contents[index]}
                                    </span>
                                </div>
                                :
                                <div style={styles.webQnaDivider}></div>
                        }
                    </div>
                })
            }

            <div style={{height : 100}}/>

        </div>

    
    
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent07: {width:"100%", display:"flex", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start", backgroundColor:"white", padding: isMobile ? "0px 6.1111vw 0px 6.1111vw":"0px 9.3750vw 0px 9.3750vw"},
    webTextContainer01: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"},
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webRowContainer02: {width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"flex-start", padding:"1.8056vw 0px", margin:"0px"},
    webQuestionIcon: {width:"2.7083vw", height:"2.7083vw", marginRight:"0.5556vw", padding:"0px"},
    webQnaTitleTextTrue:{fontFamily:"Pretendard", fontSize:"1.94vw", fontWeight:"600", lineHeight:"2.72vw", letterSpacing:"-0.01em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#1B1D1F" },
    webQnaTitleTextFalse:{fontFamily:"Pretendard", fontSize:"1.94vw", fontWeight:"400", lineHeight:"2.72vw", letterSpacing:"-0.01em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#454A50" },
    webQnaComponent:{padding:"22px 18px", margin:"0px", borderRadius:"16px", backgroundColor:"#FAFAFB", display:"flex", boxSizing:"border-box"},
    webQnaContentText:{fontFamily:"Pretendard", fontSize:"1.67vw", fontWeight:"400", lineHeight:"2.5vw", letterSpacing:"-0.02em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D" },
    webQnaDivider:{width:"100%", height:"0.6px", backgroundColor:"#E8E8E9" },

});



export default MainWebQnaComponent;