import React from 'react';

import Num01 from '../../../assets/icon/sitter/mobile/sitter_info_num_01.svg'
import Num02 from '../../../assets/icon/sitter/mobile/sitter_info_num_02.svg'
import Num03 from '../../../assets/icon/sitter/mobile/sitter_info_num_03.svg'
import Num04 from '../../../assets/icon/sitter/mobile/sitter_info_num_04.svg'

import Info01 from '../../../assets/icon/sitter/mobile/sitter_info_01.svg'
import Info02 from '../../../assets/icon/sitter/mobile/sitter_info_02.svg'
import Info03 from '../../../assets/icon/sitter/mobile/sitter_info_03.svg'
import Info04 from '../../../assets/icon/sitter/mobile/sitter_info_04.svg'


const Content04Component = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <span style={styles.titleTxt}>
            이웃시터는 이렇게 활동해요
        </span>

        <div style={{height : "6.2500vh"}}/>

        <div style={styles.content}>
            <div style={styles.iconCon}>
                <img src={Num01} style={styles.numIcon} alt="1"/>
                <img src={Info01} style={styles.infoIcon} alt="1"/>
            </div>

            <div style={{height : "1.25vh"}}/>

            <span style={styles.contentTxt}>
                보호자 님의 돌봄 요청을 확인해요.
            </span>
        </div>

        <div style={{height : "3.125vh"}}/>

        <div style={styles.content}>
            <div style={styles.iconCon}>
                <img src={Num02} style={styles.numIcon} alt="1"/>
                <img src={Info02} style={styles.infoIcon} alt="1"/>
            </div>

            <div style={{height : "1.25vh"}}/>

            <span style={styles.contentTxt}>
                일정을 예약하고 사례비를 결제 받아요.
            </span>
        </div>

        <div style={{height : "3.125vh"}}/>

        <div style={styles.content}>
            <div style={styles.iconCon}>
                <img src={Num03} style={styles.numIcon} alt="1"/>
                <img src={Info03} style={styles.infoIcon} alt="1"/>
            </div>

            <div style={{height : "1.25vh"}}/>

            <span style={styles.contentTxt}>
                약속한 일정에 반려동물을 돌봐주세요.
            </span>
        </div>

        <div style={{height : "3.125vh"}}/>

        <div style={styles.content}>
            <div style={styles.iconCon}>
                <img src={Num04} style={styles.numIcon} alt="1"/>
                <img src={Info04} style={styles.infoIcon} alt="1"/>
            </div>

            <div style={{height : "1.25vh"}}/>

            <span style={styles.contentTxt}>
                돌봄을 완료하면 보호자 님이 
                결제한 사례비를 지급받아요.
            </span>

            <div style={{height : "1vh"}}/>

            <span style={styles.subContentTxt}>
                *수수료 및 서비스 이용료 없음
            </span>
        </div>

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        width : "100vw",
        backgroundColor : "#F9FAFF",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : "7.8125vh",
        paddingBottom : "10.9375vh",
        paddingLeft: "7.5vw",
        paddingRight : "7.5vw",
    },
    titleTxt : {
        fontSize : "6.8750vw",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "140%"
    },
    content : {
        width : "100%",
        boxSizing : "border-box",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        backgroundColor : "white",
        borderRadius : "10px",
        padding : 20
    },
    iconCon : {
        width : "100%",
        display : "flex",
        flexDirection : "row",
        justifyContent : "space-between",
        alignItems : "flex-start"
    },
    numIcon : {
        width : "6.25vw",
        height : "6.25vw",
    },
    infoIcon : {
        width : "11.8750vw",
        height : "11.8750vw",
    },
    contentTxt : {
        fontSize : "5vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        color : "#454A50",
        lineHeight : "140%"
    },
    subContentTxt : {
        fontSize : "3.4375vw",
        fontWeight : 400,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        color : "#9FA5AD"
    }
}

export default Content04Component;