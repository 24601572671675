import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import TitleLogo from '../../assets/icon/partner/logo.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PartnerWebHeader = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    const onStart = () => {
        if (isIOS) {

            var visitedAt = (new Date()).getTime(); // 방문 시간
            //window.open("https://apps.apple.com/kr/app/%EC%9A%B0%EC%A3%BC%ED%8E%AB-%ED%8C%8C%ED%8A%B8%EB%84%88/id6673888931?uo=4&at=11l6hc&ct=fnd");
        
            setTimeout(
                function() {
                    if ((new Date()).getTime() - visitedAt < 5000) {
                        window.location.href = "https://apps.apple.com/kr/app/%EC%9A%B0%EC%A3%BC%ED%8E%AB-%ED%8C%8C%ED%8A%B8%EB%84%88/id6673888931?uo=4&at=11l6hc&ct=fnd";
                    }
                 }, 500);
          
            setTimeout(function() { 
                window.location.href = "{커스텀 스킴 주소}";
            }, 0);       
        
        
        }
        else if (isAndroid) {
            //window.open("https://play.google.com/store/apps/details?id=com.universepet.universepetPartners.universepet_partners");        
            window.location.href = "intent://path/#Intent;scheme=universepetPartner;package=com.universepet.universepetPartners.universepet_partners";
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.universepet.universepetPartners.universepet_partners")
        }
    }
    
    return <div style={styles.header} >
        <img src={TitleLogo} alt="우주펫 파트너" style={styles.headerLogo}/>
        <div style={styles.headerBtn} onClick={() => onStart()}>
            <p style={styles.headerBtnTxt}>파트너 시작하기</p>
        </div>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    headerLogo: { width: "286.27px", cursor: "pointer", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none", userSelect: "none",  },  
    headerBtn: { margin: "0", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "0px", gap: "60px", width: "260px", height: "68px", borderRadius: "36px", flex: "none", order: "1", flexGrow: "0", backgroundColor: "#8B4DFF", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none", userSelect: "none", },
    headerBtnTxt: { padding: "0", margin: "0", fontFamily: "Pretendard", fontStyle: "normal", fontWeight: 700, fontSize: "26px", lineHeight: "31px", letterSpacing: "-0.03em", color: "#FFFFFF", flex: "none", order: "0", flexGrow: "0", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none", userSelect: "none", },
    header: { display: 'flex', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center', paddingTop: '2.8448vh', paddingBottom: '2.0690vh',  paddingLeft: '7.5vw', paddingRight: '7.5vw', width: '100vw',  zIndex: 1000, boxSizing: 'border-box', top: 0, left: 0,  position: 'fixed', backgroundColor: 'white', padding : isMobile? "24px 6.1111vw 34px  6.1111vw":"24px 9.3750vw 34px 9.3750vw" },
});

export default PartnerWebHeader;