import React from 'react';

import NaverBlog from '../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../assets/icon/component/footer/instagram.svg';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Footer = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }

     return (
        <div style={styles.footer2}>

            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", marginTop: 0, marginBottom : 60}}>
                <a style={styles.footerSemiBoldTitle} href="mailto:sungsoo@spacepet.info">제휴/IR문의</a>

                <div >
                    <img src={NaverBlog} alt="" style={styles.NaverBlogButton} onClick={() => pressNaverBlog()}/>
                    <img src={Instagram} alt="" onClick={() => pressInstagram()}/>
                </div>
            </div>

            <p style={styles.footerCompanyTitle}>주식회사 우주펫</p>
            
            <div style={styles.footerBottomCon_01}>

                <div style={styles.footerTextCon}>                    

                    <div style={styles.footerSemiTextCon}>
                        <p style={styles.footerSemiTitle}>대표 : 박성수</p>
                        <div style={styles.verticalDivider} />
                        <p style={styles.footerSemiTitle}>사업자등록번호 : 860-81-02850</p>
                    </div>

                    <div style={styles.footerSemiTextCon}>
                        <div style={styles.verticalDivider} />
                        <p style={styles.footerSemiTitle}>주소 : 서울특별시 강남구 역삼로 169 (역삼동, 명우 빌딩)</p>
                    </div>
                </div>                
            </div>


            <div style={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", marginTop: 0, marginBottom : 0}}>
                <div style={styles.footerSemiTextCon}>
                    <p style={styles.footerSemiTitle}>​‌고객센터 : 010 - 2276 - 0834</p>
                    <div style={styles.verticalDivider} />
                    <p style={styles.footerSemiTitle}>이메일 : spacepetofficial@gmail.com</p>
                </div>

                <div style={styles.footerSemiTextCon}>
                    <p style={{...styles.footerSemiBoldTitle,marginLeft : 10, cursor : "pointer" }} onClick={() => onTerm()} >​이용약관</p>
                    <div style={{width : "0.6250vw", cursor : "pointer"}} onClick={() => onPrivacyTerm()}/><p style={styles.footerSemiBoldTitle}>개인정보처리방침</p>
                </div>
            </div>           
            
            <div style={styles.bottomCon}>
                <p style={styles.footerTitleClc}>​spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
            </div>

        </div>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    footer2: { width: "100%", backgroundColor: "white", paddingLeft: "12.5%", paddingRight: "12.5%", paddingTop: "70px", paddingBottom: "70px", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between",},
    footerSemiBoldTitle: {fontFamily: "Pretendard", fontSize: "0.7292vw", fontWeight: 600, marginTop: "0px", marginBottom: "0px", color: "#797A7C", textDecoration: "none",},
    footerSemiTextCon: {display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px", marginBottom: "5px",},
    footerSemiTitle: {fontFamily: "Pretendard", fontSize: "0.7292vw", fontWeight: 500, color: "#726E6E", marginTop: "0px", marginBottom: "0px", },
    verticalDivider: { width: "1px", height: "0.6vw", marginLeft: "12px", marginRight: "12px", marginTop: "0px", backgroundColor: "#726E6E",},
    footerCompanyTitle: { fontFamily: "Pretendard", fontSize: "0.8333vw", fontWeight: "bold", marginBottom: "20px", marginTop: "0px",},
    footerBottomCon_01: {display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: "0px", },
    bottomCon: {display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", },
    footerTitleClc: {color: "#A4A5A5", fontFamily: "Pretendard", fontSize: "0.7292vw", fontWeight: 500, marginTop: "30px", marginBottom: "0px",},
    footerTextCon: { display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "flex-start", marginTop: "0px",},
    NaverBlogButton: {marginRight:"20px"},
});

export default Footer;