import React from 'react';
import ComponentImage from '../../../assets/images/main/web/component-02.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageComponent from '../../common/Image.component';


const MainWeb02Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 
    
    return (
       <>
       <div style={styles.webComponent}>     
            <div style={{height : 120}}/>
            <div style={styles.webRowContainer01}>
               
                <div style={styles.webTextContainer01}>
                    <div style={{height : 170}}/>

                    <span style={styles.webSemiTitleText}>
                        반려인을 돕는 이웃
                    </span>

                    <div style={{height : 6}}/>
                    <span style={styles.webTitleText}>
                        우리 동네 돌봄 이웃에게<br/>
                        도움 받아보기
                    </span>

                    <div style={{height : 12}}/>
                    <span style={styles.webContentText}>
                        반려동물을 키우다 누군가의 도움이 필요할 때,<br/>
                        <span style={styles.webContentBoldText} >돌봄∙산책</span>을 부탁할 이웃을 찾아보세요.
                    </span>
                </div>

                <ImageComponent
                    src={ComponentImage}
                    alt="컴포넌트 이미지"
                    style={styles.webImage02}
                />
            </div>
        </div>
       </>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent: {width: "100vw", display: "flex", flexDirection: "column",backgroundColor: "white", alignItems: "flex-start", justifyContent: "flex-start", boxSizing: "border-box", paddingLeft: isMobile ? "6.1111vw" : "9.3750vw", paddingRight: isMobile ? "0vw" : "0vw",},
    webRowContainer01: {width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"space-between", padding:"0px", margin:"0px"},
    webTextContainer01: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"},
    webSemiTitleText: {fontFamily:"Pretendard", fontSize:"2vw", lineHeight:"2.5vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF", padding:"0", margin:"0"},
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webContentText: {fontFamily:"Pretendard", fontSize:"1.75vw", lineHeight:"2.5vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" , color:"#62686D"},
    webContentBoldText: {fontFamily:"Pretendard", fontSize:"1.75vw", lineHeight:"2.5vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webImage02: {width:"55.5556vw", height:"55.5556vw", boxSizing:"border-box", margin:"0", padding:"0"},
});


export default MainWeb02Component