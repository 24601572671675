import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Main03TitleComponentProps {
    data: Array<boolean>;
    onUpdate: (newData: Array<boolean>) => void;
  }
  
  const Main03TitleComponent : React.FC<Main03TitleComponentProps> = ({ data, onUpdate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onButton = (index: number) => {
        const newValue = [false, false, false];
        newValue[index] = true;
        onUpdate(newValue); // 상위 컴포넌트에 상태 변경 전달
    };

    return (
       <>
        <div style={styles.component} >
            <div style={{height : 30}}/>

            <div style={styles.textContainer01}>
                <span style={styles.titleText}>
                    돌봄 이웃
                </span>                
            </div>

            <div style={{height : 18}}/>

            <div style={styles.buttonContainer01} >
                <div 
                  style={(data[0]) ? styles.selectButtonTrue : styles.selectButtonFalse}
                    onClick={() => onButton(0)}
                >
                    품앗이 이웃
                </div>
                <div 
                    style={(data[1]) ? styles.selectButtonTrue : styles.selectButtonFalse}
                    onClick={() => onButton(1)}
                >
                    이웃 펫시터
                </div>
                <div 
                    style={(data[2]) ? styles.selectButtonTrue : styles.selectButtonFalse}
                    onClick={() => onButton(2)}
                >
                    펫호텔∙유치원
                </div>
            </div>

            <div style={{height : 25}}/>
        </div>
       </>
    )
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    component: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:'flex-start'},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    buttonContainer01: {display:"flex", flexDirection:"row", justifyContent:"space-between", boxSizing:"border-box", alignItems:'flex-start', paddingLeft:"24px", paddingRight:"24px", width:"100%"},
    selectButtonTrue: {padding:"10px 12px 10px 12px", borderRadius:"36px", border:"0.8px solid #9F6BFF", fontFamily:"Pretendard",fontSize:"4.06vw", fontWeight:"600", lineHeight:"4.87vw", letterSpacing:"-0.02em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF", backgroundColor:"white"},
    selectButtonFalse: {padding:"10px 12px 10px 12px", borderRadius:"36px", border:"0.8px solid #E8E8E9", fontFamily:"Pretendard",fontSize:"4.06vw", fontWeight:"600", lineHeight:"4.87vw", letterSpacing:"-0.02em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#454A50", backgroundColor:"white"},
});

export default Main03TitleComponent