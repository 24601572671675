import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Main03TitleComponentProps {
    data: Array<boolean>;
    onUpdate: (newData: Array<boolean>) => void;
  }
  
  const MainWeb03TitleComponent : React.FC<Main03TitleComponentProps> = ({ data, onUpdate }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onButton = (index: number) => {
        const newValue = [false, false, false];
        newValue[index] = true;
        onUpdate(newValue); // 상위 컴포넌트에 상태 변경 전달
    };

    return (
       <>
       <div style={styles.webComponent}>

            <div style={{height : 88}}/>
            <div style={styles.webTextContainer01}>
                <span style={styles.webTitleText} >
                    돌봄 이웃
                </span>                
            </div>

            <div style={{height : 18}}/>

            <div style={styles.webButtonContainer01}>
                <div 
                    style={(data[0]) ? styles.webSelectButtonTrue : styles.webSelectButtonFalse}
                    onClick={() => onButton(0)}
                >
                    품앗이 이웃
                </div>
                <div 
                    style={(data[1]) ? styles.webSelectButtonTrue : styles.webSelectButtonFalse}
                    onClick={() => onButton(1)}
                >
                    이웃 펫시터
                </div>
                <div 
                    style={(data[2]) ? styles.webSelectButtonTrue : styles.webSelectButtonFalse}
                    onClick={() => onButton(2)}
                >
                    펫호텔∙유치원
                </div>
            </div>

            <div style={{height : 90}}/>
        </div>
       </>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent: {width: "100vw", display: "flex", flexDirection: "column",backgroundColor: "white", alignItems: "flex-start", justifyContent: "flex-start", boxSizing: "border-box", paddingLeft: isMobile ? "6.1111vw" : "9.3750vw", paddingRight: isMobile ? "0vw" : "0vw",},
    webTextContainer01: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start"},
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0"},
    webButtonContainer01: {display:"flex", flexDirection:"row", justifyContent:"flex-start", boxSizing:"border-box", alignItems:"flex-start", width:"100%", gap:"1.3889vw"},
    webSelectButtonTrue: {padding:"1.6667vw 2.7778vw", borderRadius:"2.5vw", border:"0.0556vw solid #9F6BFF", fontFamily:"Pretendard", fontSize:"1.8056vw", lineHeight:"2.1667vw", fontWeight:"600", letterSpacing:"-0.02em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF", backgroundColor:"white"},
    webSelectButtonFalse: {padding:"1.6667vw 2.7778vw", borderRadius:"2.5vw", border:"0.0556vw solid #E8E8E9", fontFamily:"Pretendard", fontSize:"1.8056vw", lineHeight:"2.1667vw", fontWeight:"600", letterSpacing:"-0.02em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#454A50", backgroundColor:"white"},
});

export default MainWeb03TitleComponent