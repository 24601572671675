import React from 'react';

import Logo from '../../../assets/icon/common/main_web_logo.svg';
import { AppStoreLink, PlayStoreLink,BrowserLink } from '../../../data/urls';
import { isIOS ,isBrowser} from 'react-device-detect';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainWebHeader = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isDeskTop = useMediaQuery(theme.breakpoints.down('md'));

    const styles = getStyles(isDeskTop); 

    const onStart = () => {
        if (isIOS) {
            window.open(AppStoreLink);
        }else if(isBrowser){
            window.open(BrowserLink);
        }
        else {
            window.open(PlayStoreLink);
        }
    }

    return ( 
        <div style={styles.webHeader}>
        <img 
            src={Logo}
            alt=""
            style={styles.webImage10}
        />

        <div className="downloadBtn" onClick={() => onStart()} >
        <div style={styles.headerButton} className="downloadBtn" >
            <span style={styles.headerButtonText} className="downloadBtn">앱 다운로드</span>
        </div>
        </div>
    </div>
    );
}

const getStyles = (isDeskTop: boolean): Record<string, React.CSSProperties> => ({
    webHeader: { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", zIndex: 1000, boxSizing: "border-box", top: 0, left: 0, position: "fixed", backgroundColor: "white", paddingTop: "24px", paddingBottom: "34px", cursor: "pointer", paddingLeft: isDeskTop ? "6.1111vw" : "9.3750vw"  , paddingRight:  isDeskTop ?  "6.1111vw" : "9.3750vw" },
    webImage10: {  width : "28.1250vw", height :"2.7778vw" },
    headerButton :{padding : "18.5px 72.5px", borderRadius: "80px", backgroundColor: "#9F6BFF"},
    headerButtonText :{fontFamily: "Pretendard" ,  fontSize:"1.8056vw", fontWeight:"500", lineHeight :"2.1549vw", letterSpacing :"-0.03em",textAlign:"left",  textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#fff", }
});


export default MainWebHeader;