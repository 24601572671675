import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const UcMainScreen = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(mobile); 

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

    const onClick = () => {
        if (isMobile()) {
            var linkQuery = searchParams.get("deepLink")
            window.open("https://cosmicnyang.page.link/" + linkQuery)
        }
        else {
            window.open("https://spacepetad1.onelink.me/MLvZ/challenge")
        }
    }

    return <>
        <Helmet>
            <title>우주펫 응가 치우기 챌린지</title>
            <meta name="우주펫 응가 치우기 챌린지" content="우주펫 응가 치우기 챌린지"/>
            <meta name="description" content="챌린지 참여하고 배변용품 받기"/>
            <meta property="og:type" content="website"/> 
            <meta property="og:title" content="우주펫 응가 치우기 챌린지"/>
            <meta property="og:url" content="https://spacepet.site/uc"/>
            <meta property="og:description" content="챌린지 참여하고 배변용품 받기"/>
            <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/%23%EC%9D%91%EC%B1%8C_%EC%9B%B9%EB%A7%81%ED%81%AC%20%EC%9D%B4%EB%AF%B8%EC%A7%80.png"/>
            <meta property="og:site_name" content="우주펫 응챌"/>
        </Helmet>

        <div style={styles.ucMainCon}>
            <div style={styles.con01} >
                <div  style={styles.con03} />

                <img src={require("../../assets/images/uc/main_content.png")} style={styles.mainConImage} alt=""/>
                
                <div style={styles.con02}>                  
                    <p style={styles.subText01}>* 우주펫은 반려인만 이용할 수 있어요. </p>
                    <div style={styles.button01}  onClick={() => {onClick()}}>
                        
                        <p style={styles.button01Txt} >배변용품 받으러 가기</p>
                    </div>      
                </div>      
            </div>    
        </div>
    </>

}

const getStyles = (mobile: boolean): Record<string, React.CSSProperties> => ({
    ucMainCon: { width: '100vw', height: '100vh', backgroundColor: '#FFF5F9', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '5vh', paddingBottom: '5vh', boxSizing: 'border-box', overflow: 'hidden', },
    con01: { display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' },
    con02: { width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'center' },
    con03: { height: '100%', display: 'flex', justifyContent: 'start', alignItems: 'start' },
    button01: { borderRadius: '12px', width: 'calc(100% - 40px)', height: '8.0000vh', backgroundColor: '#FC7B8B', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    button01Txt: { fontSize: 'large', fontWeight: 700, color: '#FFFFFF' },
    subText01: { fontSize: 'small', color: '#9fa5ad', fontWeight: 400, marginBottom: '100px' },
    mainConImage: { width:"320px", height:"441px" },
});

export default UcMainScreen;