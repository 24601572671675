import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Footer = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    const onStart = () => {
        if (isIOS) {
            window.open("https://apps.apple.com/kr/app/%EC%9A%B0%EC%A3%BC%ED%8E%AB-%ED%8C%8C%ED%8A%B8%EB%84%88/id6673888931?uo=4&at=11l6hc&ct=fnd");
        }
        else {
            window.open("https://play.google.com/store/apps/details?id=com.universepet.universepetPartners.universepet_partners");
        }
    }

    return <div style={styles.footerCon02}   onClick={() => onStart()}>
        <p  style={styles.footerTxt} >
            파트너 시작하기
        </p>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    footerCon02: { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", paddingLeft: "7.5vw", paddingRight: "7.5vw", zIndex: 999, boxSizing: "border-box", bottom: 0, left: 0, position: "fixed", width: "100vw", height: "10vh", backgroundColor: "#8B4DFF", },  
    footerTxt: { padding: 0, margin: 0, fontSize: "5.0000vw", fontFamily: "'Pretendard'", fontWeight: 700, letterSpacing: "-1%", lineHeight: "6.5000vw", color: "white", whiteSpace: "nowrap", textShadow: "none", },  
  
});

export default Footer;