import React from 'react';

import info from '../../../assets/icon/sitter/web/recuirt.svg';

const Content06BrowserComponent = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <span style={styles.titleTxt}>
            선발절차
        </span>
        <div style={styles.imgDiv}>
            <img src={info} style={styles.backgroundImage} alt="이미지"/>
        </div>

    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : 50,
        paddingBottom : 73,
        paddingLeft:"6.1111vw",
        paddingRight:"6.1111vw"
    },
    titleTxt : {
        fontSize : 40,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        paddingLeft:"70px"
    },
    backgroundImage : {
        width : "100%",
        marginTop : "6.25vh",
        lineHeight : "140%",
    },
    imgDiv:{
        display:"flex",
        justifyContent : "center",
        marginLeft :"4%",
        marginRight :"4%"
    },
}

export default Content06BrowserComponent;