import React from 'react';
import ComponentImage02 from '../../../assets/images/main/mobile/component_02.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Main02Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    return (
       <>
        <div style={styles.component}>

            <div style={{height : 50}}/>

            <div style={styles.textContainer01}>

                <span style={styles.semiTitleText} >
                    반려인을 돕는 이웃
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText} >
                    우리 동네 돌봄 이웃에게<br/>
                    도움 받아보기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    반려동물을 키우다 누군가의 도움이 필요할 때,<br/>
                    <span style={styles.contentBoldText} >돌봄∙산책</span>을 부탁할 이웃을 찾아보세요.
                </span>
            </div>

            <div style={{height : 37}}/>

            <img 
                src={ComponentImage02}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>
       </>
    )
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    component: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:'flex-start'},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    semiTitleText: {fontFamily:"Pretendard", fontSize:"5vw", lineHeight:"6vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:0,margin:0,color:"#9F6BFF"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    contentText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D"},
    contentBoldText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", },
    componentImage02: {width:"100vw"},
});


export default Main02Component