import React from 'react';
import TitleLogo from '../../../assets/icon/sitter/mobile/title_logo.svg';
import ExitIcon from '../../../assets/icon/partner/exit_grey80.svg';
import RightIcon from '../../../assets/icon/partner/circle_angle_right_grey60.svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const MenuComponent = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const navigate = useNavigate();

    const onInfo = () => {
        navigate("/");
    }

    const onSitter = () => {
        navigate("/sitter");
    }

    const onPartner = () => {
        navigate("/partner");
    }
    
    return <div style={styles.menuScreen} >
        <div style={styles.header}>
            <img src={TitleLogo} alt="우주펫 시터"  style={styles.headerLogo} />
            <img src={ExitIcon} alt="닫기"style={styles.menu} onClick={() => onSitter()}/>
        </div>

        <div style={{height : "10.7448vh"}}/>
        
        <div style={styles.menuButton} onClick={() => onInfo()}>
            <p style={styles.menuTxt} >우주펫 소개</p>
            <img src={RightIcon} alt='이동' style={styles.menuIcon} />
        </div>

        <div style={styles.menuButton} onClick={() => onSitter()}>
            <p style={styles.menuTxt} >펫시터 지원하기</p>
            <img src={RightIcon} alt='이동'  style={styles.menuIcon} />
        </div>

        <div style={styles.menuButton} onClick={() => onPartner()}>
            <p style={styles.menuTxt} >파트너 등록하기</p>
            <img src={RightIcon} alt='이동'  style={styles.menuIcon} />
        </div>
    
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    header: {display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", padding: "17px 24px 17px 24px", width:"100%",zIndex:"1000", boxSizing:"border-box", top:0, left:0, position:"fixed", backgroundColor:"white"},
    menuScreen: {paddingLeft:"7.5vw", paddingRight:"7.5vw"},
    headerLogo: { width: isMobile ? "38.0406vw" : "286.27px", cursor: "pointer", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none", userSelect: "none",  },  
    menu: { width: '8.4375vw', height: '8.4375vw', cursor: 'pointer' },
    menuButton: { borderBottom: "solid 0.6px #F4F4F5", paddingLeft: "0", paddingRight: "0", paddingTop: "6.25vw", paddingBottom: "6.25vw", margin: "0", boxSizing: "border-box", backgroundColor: "white", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", },  
    menuTxt: { padding: "0", margin: "0", fontFamily: "Pretendard", fontSize: "4.6875vw", lineHeight: "5.625vw", fontWeight: 500, letterSpacing: "-0.02em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", },  
    menuIcon: {width:"8.44vw", height:"8.44vw", cursor:"pointer"},

  
});


export default MenuComponent;