import React from 'react';
import PartnerHeader from './mobile/mobile.header.component';
import Component01 from './mobile/component_01';
import Footer from './mobile/footer.component';
import Component02 from './mobile/component_02';
import Component03 from './mobile/component_03';
import Component04 from './mobile/component_04';
import Component05 from './mobile/component_05';
import Component07 from './mobile/component_07';
import Component06 from './mobile/component_06';
import MobileFooter from '../common/mobile_footer.component';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PartnerMainMobileComponent = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile);

    return <div style={styles.screen}>
        <PartnerHeader />
        <Footer/>
        <Component01 />
        <Component02 />
        <Component03 />
        <Component04 />
        <Component05 />
        <Component06 />
        <Component07 />

        <MobileFooter/>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    screen: {
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
});


export default PartnerMainMobileComponent;