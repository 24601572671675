import React from 'react';
import Logo from '../../assets/icon/common/main_web_logo.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

interface MainSitterWebHeaderProps {
    openDialog: () => void;
}
const MainSitterWebHeader: React.FC<MainSitterWebHeaderProps> = ({ openDialog }) => {
 const navigate = useNavigate();
    
 
 const theme = useTheme();
 const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
 const styles = getStyles(isMobile); 
 
    const onStart = () => {  
        navigate("/sitter/write")
   
    }

    return (<div className="webHeader" style={styles.webHeader}> 
        <img 
            src={Logo}
            alt=""
            className="webImage10"
            style={styles.webImage10}
        />

        <div className="headerButton" style={styles.headerButton} onClick={openDialog}>
        {/* <div className="headerButton" style={styles.headerButton} onClick={() => onStart()}> */}
            <span className="headerButtonText" style={styles.headerButtonText}>이웃시터 지원하기</span>
        </div>
    </div>
    );
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        zIndex: 1000,
        boxSizing: "border-box",
        top: 0,
        left: 0,
        position: "fixed",
        backgroundColor: "white",
        paddingTop: "24px",
        paddingBottom: "24px",
        cursor: "pointer",
        paddingLeft: isMobile ? "6.1111vw" : "9.3750vw",
        paddingRight: isMobile ? "6.1111vw" : "9.3750vw"
    },
    
    webImage10: {
        width: "18.1250vw",
        height: "2.7778vw"
    },
    
    headerButton: {
        padding: "11px 30px",
        borderRadius: "80px",
        backgroundColor: "#9F6BFF"
    },
    
    headerButtonText: {
        fontFamily: "Pretendard",
        fontSize: "1.1vw",
        fontWeight: 700,
        lineHeight: "2.1549vw",
        letterSpacing: "-0.03em",
        textAlign: "left",
        textUnderlinePosition: "from-font",
        textDecorationSkipInk: "none",
        color: "#fff"
    }
});





export default MainSitterWebHeader;