import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AliceCarousel from 'react-alice-carousel';
import ContentImage01 from '../../../assets/image/mobile/content/06/home_content_06_01.png'
import ContentImage02 from '../../../assets/image/mobile/content/06/home_content_06_02.png'
import ContentImage03 from '../../../assets/image/mobile/content/06/home_content_06_03.png'
import ContentImage04 from '../../../assets/image/mobile/content/06/home_content_06_04.png'
import ContentImage05 from '../../../assets/image/mobile/content/06/home_content_06_05.png'

const Main04Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const items = [
        <img src={ContentImage01} alt="컨텐츠01" style={styles.componentImage03}  onClick={() => onItem(0)}/>,
        <img src={ContentImage02} alt="컨텐츠02" style={styles.componentImage03}  onClick={() => onItem(1)}/>,
        <img src={ContentImage03} alt="컨텐츠03" style={styles.componentImage03}  onClick={() => onItem(2)}/>,
        <img src={ContentImage04} alt="컨텐츠04" style={styles.componentImage03} onClick={() => onItem(3)}/>,
        <img src={ContentImage05} alt="컨텐츠05" style={styles.componentImage03}  onClick={() => onItem(4)}/>,
    ];

    const onMore = () => {
        window.open("https://blog.naver.com/spacepetofficial",  "_blank")
    }
    
    const onItem = (index : number) => {
        var links = [
            "https://blog.naver.com/spacepetofficial/222910857769",
            "https://blog.naver.com/spacepetofficial/222929495479",
            "https://blog.naver.com/spacepetofficial/222916631717",
            "https://blog.naver.com/spacepetofficial/222929495259",
            "https://blog.naver.com/spacepetofficial/222922143581"
        ];

        window.open(links[index],  "_blank")
    }
    
    return (
        <div style={styles.component02} >

            <div style={styles.textContainer01}>
                <span style={styles.titleText}>
                    우주펫 이웃들의 후기
                </span>
            </div>

            <div style={{height : 40}}/>

            <AliceCarousel 
                autoHeight
                mouseTracking 
                disableDotsControls
                disableButtonsControls                    
                autoWidth
                items={items}
                paddingLeft={27.5}
                paddingRight={27.5}
                autoPlay                
                autoPlayInterval={4000}
            />

            <div style={{height : 25}}/>

            <div style={styles.centerContainer} >
                <div style={styles.button01}  onClick={() => onMore()}>
                    <span style={styles.buttonText01} >더 많은 후기 보러 가기</span>
                </div>  
            </div>                      
        </div>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    componentImage03: {width:"73.1250vw", height:"79.8750vw", marginRight:"20vw"},
    component02: {paddingTop:"40px", paddingBottom:"48px", background:"linear-gradient(to bottom, #FFFFFF 50%, #EDE2FF 50%)"},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    centerContainer: {display:"flex",justifyContent:"center", alignItems:"center", width:"100%"},
    button01: {display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"27px", border:"1px solid #9F6BFF",backgroundColor:"white", width:"154px", height:"40px", paddingTop:"11.5px", paddingBottom:"11.5px"},
    buttonText01: {color:"#9F6BFF", fontFamily:"Pretendard", fontSize:"14px", fontWeight:"600", lineHeight:"16.71px", letterSpacing:"-0.03em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none",margin:"0px", padding:"0px"},
});

export default Main04Component