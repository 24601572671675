import React from "react";
import NaverBlog from '../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../assets/icon/component/footer/instagram.svg';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Footer = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onIR = () => {
        window.open("mailto:sungsoo@spacepet.info", "_blank")
    }

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }

    const onMain = () => {
        navigate("/")
    }

    const onSitter = () => {
        navigate("/sitter/write")
    }


    return <div style={styles.footerCon01}>

    <div style={styles.footerRowCon05}>
        <p style={styles.footerTxt01} onClick={() => onMain()}>우주펫 소개</p>
        <div style={{width : 24}}/>
        <p style={styles.footerTxt01} onClick={() => onSitter()}>이웃시터 지원하기</p>
    </div>

    <div style={{height : 68}}/>
    
    <div style={styles.footerRowCon01} >
        <p style={styles.footerTxt01} onClick={() => onIR()}>제휴/IR문의</p>

        <div style={styles.footerRowCon02} >
            <div style={styles.footerIconCon}  onClick={() => pressNaverBlog()}>
                <img src={NaverBlog} alt="" />
            </div>

            <div style={styles.footerIconCon} onClick={() => pressInstagram()}>
                <img src={Instagram} alt="" />
            </div>
        </div>
    </div>

    <div style={{height : 44}}/>

    <p style={styles.footerTxt02} >주식회사 우주펫</p>

    <div style={{height : 20}}/>

    <p style={styles.footerTxt03}>대표 : 박성수 | 사업자등록번호 : 860-81-02850</p>

    <div style={{height : 12}}/>

    <p style={styles.footerTxt03}>통신판매업신고번호 : 2023-서울강남-01239</p>

    <div style={{height : 12}}/>

    <p style={styles.footerTxt03}>주소 : 서울특별시 강남구 역삼로 165 (역삼동) 7층 c호</p>

    <div style={{height : 12}}/>

    <p style={styles.footerTxt03}>​‌고객센터 : 010. 2276. 0834</p>

    <div style={{height : 12}}/>

    <div style={styles.footerRowCon04}>
        <p style={styles.footerTxt03}>이메일 : spacepetofficial@gmail.com</p>

        <div style={styles.footerRowCon03} >
            <p style={styles.footerTxt04} onClick={() => onTerm()}>이용약관</p>
            <div style={{width : 16}}/>
            <p style={styles.footerTxt04} onClick={() => onPrivacyTerm()}>개인정보처리방침</p>
            <div style={{width : 16}}/>
            <p style={styles.footerTxt04} onClick={() => onGeometryTerm()}>위치기반 서비스 약관</p>
        </div>
    </div>

    <div style={{height : 66}}/>

    <p style={styles.footerTxt05} >spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
</div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    footerCon01: {width:"100%", display:"flex", flexDirection:"column", boxSizing:"border-box", backgroundColor:"#FFFFFF", padding: isMobile? "79px 6.1111vw 68px 6.1111vw":"79px 9.3750vw 68px 9.3750vw"},
    footerRowCon05: {display:"flex", flexDirection:"row", justifyContent:"flex-start",alignItems:"center" },
    footerTxt01: {padding:0, margin:0, fontFamily:"Pretendard", fontSize:"20px", fontWeight:"600", lineHeight:"23.87px", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none",color:"#797A7C"},
    footerRowCon01: {width:"100%", padding:0, margin:0, display:"flex", flexDirection:"row", justifyContent:"space-between",alignItems:"flex-start"},
    footerRowCon02: {display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"},
    footerIconCon: {padding:"18px", margin:0},
    footerTxt02: {padding:0, margin:0, fontFamily:"Pretendard", fontSize:"26px", fontWeight:"700", lineHeight:"31.2px", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#353535"},
    footerTxt03: {padding:0, margin:0, fontFamily:"Pretendard", fontSize:"20px", fontWeight:"400",lineHeight:"24px", letterSpacing:"-0.02em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D" },
    footerRowCon04:{width:"100%",display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"},
    footerRowCon03:{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center"},
    footerTxt04:{padding:0, margin:0, fontFamily:"Pretendard", fontSize:"18px", fontWeight:"600", lineHeight:"21.6px", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9FA5AD"},
    footerTxt05:{padding:0, margin:0, fontFamily:"Pretendard", fontSize:"16px", fontWeight:"400", lineHeight: "19.2px", letterSpacing:"-0.02em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#BDC1C5" },
});


export default Footer;