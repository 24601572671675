import React from "react";
import { CDN } from "../../../keys";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Component03 = ({...props}) : React.ReactElement => {

   
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return (
        <> 

            <div style={{height : "6.8966vh"}}/>

            <p style={styles.grdientTxt02}>
                파트너를 위한 혜택
            </p>

            <div style={{height : "1.7241vh"}}/>
            
            <p style={styles.titleTxt02}>
                우리 매장 상단 노출
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p style={styles.contentTxt}  >
                파트너에 가입한 매장은 반려인들에게<br/>
                내 매장을 가장 상단에 보여줄 수 있어요.
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_003.png`} alt="" style={styles.componentImg} />
           
            
        </>

    );
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    grdientTxt02: { color: "#9F6BFF", paddingLeft: "7.5vw", margin: 0, fontFamily: "'Pretendard'", fontSize: "5.0000vw", lineHeight: "6.0000vw", fontWeight: 700, letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", },  
    titleTxt02: { padding: 0, margin: 0, paddingLeft: "7.5vw", fontSize: "6.8750vw", fontFamily: "'Pretendard'", fontWeight: 700, letterSpacing: "-3%", lineHeight: "100%", color: "black", whiteSpace: "nowrap", textShadow: "none", },  
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width:"100vw" },
});


export default Component03;