import React from 'react';
import PartnerWebHeader from './partner.web.header.component';
import Component01 from './web/component_01';
import Component02 from './web/component_02';
import Component03 from './web/component_03';
import Component05 from './web/component_05';
import Component04 from './web/component_04';
import Component06 from './web/component_06';
import Component07 from './web/component_07';
import Footer from '../common/web_footer';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const PartnerMainTabletComponent = ({...props}) : React.ReactElement => {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile);

    return <div style={styles.webScreen}> 
        <PartnerWebHeader />
        <div style={{height : 133.09}}/>
        <Component01 />
        <Component02 />
        <Component03 />
        <Component04 />
        <Component05 />
        <Component06 />
        <Component07 />
        <Footer />
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webScreen: {
        width: '100vw',
        boxSizing: 'border-box',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        minWidth: 800,
    },
});

export default PartnerMainTabletComponent;