import React from 'react';

import TitleLogo from '../../../assets/icon/sitter/mobile/title_logo.svg';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '../../../assets/icon/partner/menu.svg';

const HeaderComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressMain = () => {
        navigate("/")
    }


    const onMenu = () => {
        navigate("/sitter/menu");
    }


    return <div style={styles.header}>
        <img src={TitleLogo} style={styles.titleLogo} alt="로고" onClick={() => pressMain()}/>
        <img src={MenuIcon} alt="메뉴" style={styles.menu}  onClick={() => onMenu()}/>
    </div>
}
 
const styles : Record<string, React.CSSProperties> = {
    header : {
        height : "18.7500vw",
        backgroundColor : "white",
        display : 'flex',
        top : 0,
        left : 0,
        position : "fixed",
        paddingTop: '2.8448vh',
        paddingBottom: '2.0690vh',
        paddingLeft: '7.5vw',
        paddingRight: '7.5vw',
        width: '100vw',
        zIndex: 1000, 
        boxSizing: 'border-box',
        flexDirection: 'row',justifyContent: 'space-between', 
        alignItems: 'center',  
    },
    titleCon : {
        display : "flex",
        boxSizing : "border-box",
        paddingBottom : 0,
    },
    titleLogo : {
        width : "30vw",
        height : "9.3750vw",
        cursor : "pointer"
    },   
    headerLogo: {  cursor: 'pointer', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none', userSelect: 'none' }, 
    menu: { width: '8.4375vw', height: '8.4375vw', cursor: 'pointer' },
  


}

export default HeaderComponent;