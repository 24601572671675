import React from 'react';
import PartnerHeader from '../mobile/mobile.header.component';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TextField } from '@mui/material';

const PhoneComponent = ({...props}) : React.ReactElement => {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const [invalid, setInvalid] = React.useState({
        phoneNumber : false,
    })
    const [values, setvalues] = React.useState({
        phoneNumber : "",
    });

    const onChangeText = (event : any) => {
        if (event.target.value.length > event.target.maxLength && event.target.maxLength !== -1) {
            event.target.value = event.target.value.slice(0, event.target.maxLength);
        }        

        const {value, name: inputName} = event.target;
        setvalues({...values, [inputName] : value})
    }

    const onChangeInvalid = (event : any) => {
        const { name : inputName } = event.target;
        setInvalid({...invalid, [inputName] : true})
    }

    const onApply = () => {        
        var url = "https://hooks.slack.com/services/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"
        var data = JSON.stringify({
            "text" : values.phoneNumber
        })

        axios.post(url,data)
            .then((result) => {
                window.open("https://spacepetpartner.page.link/event");
            })
    }

    return <div style={styles.phoneApplyScreen} >

        <PartnerHeader />
      
        <span style={styles.introTitleTxt}>
            사장님의 
            <span style={{color : "#8B4DFF"}}> 핸드폰 번호</span>를<br/>
            입력해 주세요.
        </span>

        <div style={{height : "40px"}}/>
  
        <span style={styles.phoneSemiTitle}>
            입력하신 번호로 '우주펫 파트너'의<br/>
            안내서를 보내드릴게요!
        </span>

        <div style={{height : "30px"}}/>

        <TextField        
            name={"phoneNumber"} 
            value={values.phoneNumber}
            inputMode="text"            
            onChange={(e) => onChangeText(e)}
            placeholder='휴대폰 번호를 입력해 주세요.'
            className="customInput"
            onClick={(e) => onChangeInvalid(e)}      
            sx={{
                ...styles.customInput,
                "&:focus": {border: "0.8px solid #454A50", borderRadius: "8px", maxLines: 5},
                "&::placeholder": {
                    fontSize: "4.375vw", fontWeight: 500, lineHeight: "130%", letterSpacing: "-2%", color: "#BDC1C5"
                },
                "&:invalid": {
                    border: "0.8px solid #FF5C5C", borderRadgius: "8px"
                },
                "&:user-invalid": {
                    border: "0.8px solid #FF5C5C", borderRadius: "8px"
                }
            }}        
        />

        <div style={{height : "30px"}}/>

        <div style={styles.partnerPhonebtn} onClick={() => onApply()}>
            <span style={styles.partnerPhoneTxt} >전송</span>
        </div>
    </div>
}




const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    phoneApplyScreen: {width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"},
    introTitleTxt: {fontFamily: "Pretendard", fontSize: "8.1250vw", fontWeight: 700, lineHeight: "11.3750vw", letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#1B1D1F"},
    phoneSemiTitle: {fontFamily: "Pretendard", fontSize: "4.6875vw", lineHeight: "6.5625vw", letterSpacing: "-0.01em", fontWeight: 400, textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#666666"},
    customInput: {width: "80%",  borderRadius: "8px", padding: "14px", boxSizing: "border-box", marginTop: "2.1875vh", marginBottom: "4.6875vh", fontSize: "4.375vw", fontWeight: 500, lineHeight: "130%", letterSpacing: "-2%", color: "#454A50", },  
    partnerPhonebtn: {width: "98px", height: "44px", padding: "15px 35px", gap: "2px", borderRadius: "8px", border: "0.8px 0px 0px 0px", backgroundColor: "#9F6BFF", color: "#9F6BFF", display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "center"},
    partnerPhoneTxt: {fontFamily: "Pretendard", fontSize: "16px", fontWeight: 700, lineHeight: "20.8px", letterSpacing: "-0.01em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#FFFFFF", boxSizing: "border-box"},
});


export default PhoneComponent;