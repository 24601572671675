import React from "react";
import { CDN } from "../../../keys";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component05 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

   
    return (
        <> 
            <div style={{height : "6.8966vh"}}/>

            <p style={styles.grdientTxt02}>
                사장님들의 시간을 절약하는
            </p>

            <div style={{height : "1.7241vh"}}/>

            <p style={styles.titleTxt02}>
                자동 알림톡 발송
            </p>

            <div style={{height : "1.3793vh"}}/>

            <p style={styles.contentTxt} >
                그동안 고객님에게 직접 연락하셨나요?<br/>
                우주펫에 예약 등록하면 사장님 대신<br/>
                자동으로 알림톡을 보내드려요.
            </p>

            <div style={{height : "1.3793vh"}}/>

            <img src={`${CDN}web/partner/mobile/image_005.png`} alt="" style={styles.componentImg} />
           
            
        </>

    );
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    grdientDiv: { background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #F9F5FF 70%)", width: "100vw", paddingTop: "40px", paddingBottom: "10px", },  
    grdientTxt01: { paddingLeft: "7.5vw", fontFamily: "'Pretendard'", fontSize: "6.2500vw", lineHeight: "7.5000vw", letterSpacing: "-0.01em", fontWeight: 800, textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#8B4DFF", },  
    grdientTxt02: { color: "#9F6BFF", paddingLeft: "7.5vw", margin: 0, fontFamily: "'Pretendard'", fontSize: "5.0000vw", lineHeight: "6.0000vw", fontWeight: 700, letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", },  
    titleTxt02: { padding: 0, margin: 0, paddingLeft: "7.5vw", fontSize: "6.8750vw", fontFamily: "'Pretendard'", fontWeight: 700, letterSpacing: "-3%", lineHeight: "100%", color: "black", whiteSpace: "nowrap", textShadow: "none", },  
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width:"100vw" },
});

export default Component05;