import React from "react";
import { CDN } from "../../../keys";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component04 = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    return <div style={{background : "#FFFFFF"}}>

        <div style={{height : 72}}/>

        <div style={styles.componentCon}>
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p style={styles.webColorSubTitleTxt}> 
                    반려인들이 이야기한
                </p> 
                <div style={{height : 6}}/>
                <p style={styles.webTitleTxt}> 
                    신뢰도 상승
                </p>                
                <div style={{height : 7}}/>
                <p style={styles.webSubTitleTxt}> 
                    파트너 가입시 인증 뱃지를 지급해 드려요.<br/>
                    반려인들은 인증 뱃지가 있는 매장을<br/>
                    더 신뢰하고 있어요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_004.png`} style={styles.componentImage} alt=""/>
        </div>
    
        <div style={{height : 72}}/>

    </div>
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    componentCon: { display:"flex", margin:0 , flexDirection:"row", justifyContent:"space-between",alignItems:"flex-start", paddingLeft:isMobile? "6.1111vw":"9.3750vw"},
    webColorSubTitleTxt: { padding: 0, margin: 0, fontFamily: "Pretendard", fontSize: "26px", fontWeight: 700, lineHeight: "31.2px", letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#9F6BFF" },  
    webTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "40px", fontWeight: "700", lineHeight: "56px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#1B1D1F", },
    webSubTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "24px", fontWeight: "400", lineHeight: "33.6px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#62686D", },
    componentImage: {width:"791px", height:"880px", padding:0 , marginRight: isMobile? "6.1111vw":"9.3750vw"},
  
});
export default Component04;