import React from "react";
import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Component06 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onButton = () => {
        window.open("/spacepet_partner_info.pdf")
    }

    return <div>
        
        <div style={styles.com6Con}>
            <p style={styles.webTitleTxt}> 
                우주펫 소개
            </p>    
        </div>
        
        <div style={styles.com6ColorCon}>
        
            <div style={styles.com6IconRowCon}>
            
                <div style={styles.com6IconCon}>
                    <img src={Icon01} alt="" style={styles.com6Icon}  />
                    
                    <div style={{height : 30}}/>
                    <p style={styles.com6infoTxt}> 
                        펫 돌봄 어플 월 평균 이용자 수
                    </p>
                    <div style={{height : 6}}/>
                    <p style={styles.webTitleTxt}> 
                        1위
                    </p>    
                
                </div>

                <div style={styles.com6IconCon}>
                    <img src={Icon02} alt="" style={styles.com6Icon}  />

                    <div style={{height : 30}}/>

                    <p style={styles.com6infoTxt}> 
                        반려인 정회원 수
                    </p>

                    <div style={{height : 6}}/>

                    <p style={styles.webTitleTxt}> 
                        약 50,000명
                    </p>  
                </div>

                <div style={styles.com6IconCon}>
                    <img src={Icon03} alt=""style={styles.com6Icon}   />
                    
                    <div style={{height : 30}}/>

                    <p style={styles.com6infoTxt}> 
                        월간 돌봄 신청 수
                    </p>

                    <div style={{height : 6}}/>

                    <p style={styles.webTitleTxt}> 
                        약 1,000건
                    </p> 
                </div>
            </div>

            <div style={{height : 40}}/>
            
            <p style={styles.com6InfoTxt}>* 24년 9월 기준</p>

            <div style={{height : 38}}/>
            
            <div style={styles.com6BtnRowCon}>
                <p style={styles.webTitleTxt}> 
                    우주펫 파트너에 대한 정보를 더 알고 싶으신가요?
                </p> 
                <div style={styles.com6Btn} onClick={() => onButton()}>
                    <p style={styles.com6BtnTxt} >
                        소개서 읽어보기
                    </p>
                </div>


            </div>

        </div>
        

    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "40px", fontWeight: "700", lineHeight: "56px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#1B1D1F" },  
    com6Con: { width: "100%", paddingTop: "90px", paddingBottom: "50px", backgroundColor: "#F9F5FF", paddingLeft: isMobile ? "6.1111vw" : "9.3750vw" },  
    com6ColorCon: { display: "flex", flexDirection: "column", boxSizing: "border-box", backgroundColor: "#F9F5FF", paddingTop: "98px", paddingBottom: "84px", paddingLeft: isMobile ? "6.1111vw" : "9.3750vw", paddingRight: isMobile ? "6.1111vw" : "9.3750vw" },  
    com6IconRowCon: { display: "flex", flexDirection: "row", boxSizing: "border-box", justifyContent: "space-evenly", alignItems: "center", margin: "0", padding: "0" },  
    com6IconCon: { width: "100%", display: "flex", flexDirection: "column", boxSizing: "border-box", justifyContent: "center", alignItems: "center", margin: "0", padding: "0" },  
    com6Icon: { width: "124px", height: "124px", margin: "0", padding: "0" },  
    com6infoTxt: { margin: "0", padding: "0", fontFamily: "'Pretendard'", fontSize: "30px", fontWeight: "500", lineHeight: "42px", letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#454A50" },  
    com6BtnRowCon: { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", paddingTop: "30px", paddingBottom: "30px", boxSizing: "border-box", margin: "0" },  
    com6Btn: { borderRadius: "70px", paddingTop: "22px", paddingBottom: "22px", paddingLeft: "95px", paddingRight: "95px", border: "solid 1px #9F6BFF", backgroundColor: "white", margin: "0" },  
    com6BtnTxt: { margin: "0", padding: "0", fontFamily: "'Pretendard'", fontSize: "26px", fontWeight: "600", lineHeight: "31.03px", letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#9F6BFF" },  
    com6InfoTxt: { alignSelf: "center", padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "22px", fontWeight: "400", lineHeight: "30.8px", letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#62686D" },  
});


export default Component06;