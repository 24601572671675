import React from 'react';

import BackgroundImage from '../../../assets/images/sitter/web/background.png';
import Dialog from "@mui/material/Dialog";
import WriteBrowserComponent from './write/write.browser.component';
import { Grid } from 'swiper';

interface Content01BrowserComponentProps {
    open : boolean;
    closeDialog: () => void;
}
const Content01BrowserComponent: React.FC<Content01BrowserComponentProps> = ({ open, closeDialog }) => {
    return (
        <>
        <div style={styles.screen}>
            <div style={styles.paddingLeftDiv}>
                <span style={styles.titleTxt}>
                    우리 동네, 펫시터<br />
                    지금 시작해보세요
                </span>

                <div style={{ height: "0.9375vh" }} />

                <span style={styles.contentTxt}>
                    우주펫에서 이웃시터가 되어<br />
                    우리동네 반려인들과 따뜻한 경험을 나눠보세요.
                </span>
            </div>

            <img src={BackgroundImage} style={styles.backgroundImage} alt="배경" />

   
        </div>


        <Dialog open={open}   maxWidth={false} 
            PaperProps={{
                style: {
                    borderRadius: "40px",
                    padding:"10px",
                    height: "40.2500vw",
                    width: "30.3333vw",
                },
            }}
            >

            <WriteBrowserComponent closeDialog={closeDialog} />
        </Dialog>
        </>
    );
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        height : "100vh",        
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : 270,
        boxSizing : "border-box",
    },
    titleTxt : {
        fontSize : 50   ,
        fontWeight : 700,
        letterSpacing : -0.3,
        lineHeight : "140%"
    },
    contentTxt : {
        fontSize : 30,
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        lineHeight : "140%"
    },
    backgroundImage : {
        width : "100%",
        position : "absolute", 
        bottom : 0,    
        zIndex : -10
    },
    paddingLeftDiv:{
        width:"700px",
        paddingLeft:"10vw"
    },
}

export default Content01BrowserComponent;