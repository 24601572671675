import React from 'react';
import HeaderComponent from '../../components/sitter/mobile/header.component';
import WriteDoneComponent from '../../components/sitter/mobile/writeDone/write_done.component';
import { BrowserView, MobileView } from 'react-device-detect';
import HeaderBrowserComponent from '../../components/sitter/browser/header.browser.component';
import WriteDoneBrowserComponent from '../../components/sitter/browser/writeDone/write_done.browser.component';


const SitterWriteDoneMobileScreen = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflow : "hidden"}}>
            <BrowserView>
                <div style={{width : "100%", margin : "auto"}}>
                    <HeaderBrowserComponent/>
                    <div style={{display:"flex" ,justifyContent:"center"}}>
                    <WriteDoneBrowserComponent/>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <HeaderComponent />
                <WriteDoneComponent />
            </MobileView>
        </div>
    )
}

export default SitterWriteDoneMobileScreen