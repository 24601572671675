import React from 'react';
import MenuComponent from '../../components/sitter/menu/menu.component';

const SitterMenu = ({...props}) : React.ReactElement => {

    return (
        <div style={{overflow : "hidden"}}>
            <MenuComponent />
        </div>
    )
}

export default SitterMenu