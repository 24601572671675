import React from 'react';
import { CDN } from '../../../keys';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component04 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.partnerIntroScreen04} >

        <div style={styles.introTxtCon}>
            <p style={styles.titleTxt02} >
                자동 알림톡 발송
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p style={styles.contentTxt} >
                그동안 고객님에게 직접 연락하셨나요?<br/>
                우주펫에 예약 등록하면 사장님 대신<br/>
                자동으로 알림톡을 보내드려요.
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_005.png`} alt=""style={styles.componentImg} />
            
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    partnerIntroScreen04: { width: "100vw", height: "120vh", display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: "12.5vw", paddingBottom: "1.5625vw", margin: "0", boxSizing: "border-box", backgroundColor: "#F9FAFF", },  
    introTxtCon: { display: "flex", flexDirection: "column", width: "100vw", boxSizing: "border-box", },  
    titleTxt02: { padding: 0, margin: 0, paddingLeft: '7.5vw', fontSize: '6.8750vw', fontFamily: 'Pretendard', fontWeight: 700, letterSpacing: '-3%', lineHeight: '100%', color: 'black', whiteSpace: 'nowrap', textShadow: 'none' },
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width: '100vw' },
});

export default Component04;