import React from 'react';

import TitleLogo from '../../../assets/icon/common/main_logo.svg';
import MenuIcon from '../../../assets/icon/common/menu.svg';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainMobileHeader = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onMenu = () => {
        navigate("/partner/menu");
    }

    return <div style={styles.header} >
        <img src={TitleLogo} alt="우주펫" style={styles.logoIcon} />
        <img src={MenuIcon} alt="메뉴" style={styles.menuIcon}  onClick={() => onMenu()}/>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    header: {display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", padding: "17px 24px 17px 24px", width:"100%",zIndex:"1000", boxSizing:"border-box", top:0, left:0, position:"fixed", backgroundColor:"white"},
    logoIcon: {width:"30vw", cursor:"pointer", WebkitUserSelect: "none", MozUserSelect: "none", msUserSelect: "none",},
    menuIcon: {width:"8.44vw", height:"8.44vw", cursor:"pointer"},
  
});

export default MainMobileHeader;