import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserView, MobileView } from 'react-device-detect';
import Main01Component from '../../components/main/mobile/main_01.component';
import Main02Component from '../../components/main/mobile/main_02.component';
import Main03TitleComponent from '../../components/main/mobile/main_03_title.component';
import Main03ContentComponent from '../../components/main/mobile/main_03_content.component';
import MainMobileHeader from '../../components/main/mobile/main_header.component';
import MainFooter from '../../components/main/mobile/main_footer.component';
import Main04Component from '../../components/main/mobile/main_04.component';
import Main05Component from '../../components/main/mobile/main_05.component';
import MainDownloadComponent from '../../components/main/mobile/main_download.component';
import MainQnaComponent from '../../components/main/mobile/main_qna.component';
import MainInfoComponent from '../../components/main/mobile/main_info.component';
import MainWeb01Component from '../../components/main/web/main_web_01.component';
import MainWeb02Component from '../../components/main/web/main_web_02.component';
import MainWeb03TitleComponent from '../../components/main/web/main_web_03_title.component';
import MainWeb03ContentComponent from '../../components/main/web/main_web_03_content.component';
import MainWeb05Component from '../../components/main/web/main_web_05.component';

import MainWeb04Component from '../../components/main/web/main_web_04.component';
import MainWebQnaComponent from '../../components/main/web/main_web_qna.component';
import MainWebDownloadComponent from '../../components/main/web/main_web_download.component';
import Footer from '../../components/common/web_footer';
import MainWebHeader from '../../components/main/web/main_web_header.component';

import styled from 'styled-components';

const Screen = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* IE, Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 엣지 */
    }
`;

const MainScreen = ({...props}) : React.ReactElement => {

    const [data, setData] = React.useState<Array<boolean>>([true, false, false]);

    return (
        <Screen >
            <Helmet>
                <title>우리 주변의 펫, 우주펫</title>
                <meta name="우리 주변의 펫, 우주펫" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta name="description" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta property="og:type" content="website"/> 
                <meta property="og:title" content="우리 주변의 펫, 우주펫"/>
                <meta property="og:url" content="https://spacepet.site/"/>
                <meta property="og:description" content="반려동물 돌봄? 우주펫에서 완벽 해결! 반려인 이웃, 펫시터, 펫호텔・유치원 직접 골라 맡겨보세요."/>
                <meta property="og:image" content="https://nhlfpiauavry9180994.cdn.ntruss.com/web/01.png"/>
                <meta property="og:site_name" content="우리 주변의 펫, 우주펫"/>
            </Helmet>

            <BrowserView>

                <MainWebHeader />

                <MainWeb01Component />
                <MainWeb02Component />
                <MainWeb03TitleComponent 
                    data={data}
                    onUpdate={(value) => {
                        setData(value)
                    }}
                />
                <MainWeb03ContentComponent 
                    data={data}
                />
                <MainWeb04Component />
                <MainWeb05Component />
                
                <MainWebDownloadComponent />
                <MainWebQnaComponent />

                <Footer />
                   
            </BrowserView> 
            
            <MobileView>
                <MainMobileHeader />
                <MainFooter />

                <Main01Component />
                <Main02Component /> 
                <Main03TitleComponent 
                    data={data}
                    onUpdate={(value) => {
                        setData(value)
                    }}
                />
                <Main03ContentComponent 
                    data={data}
                />
                <Main04Component />  
                <Main05Component />
                <MainDownloadComponent />

                <MainQnaComponent />
                <MainInfoComponent />
               
            </MobileView>
        </ Screen>
    )
}


export default MainScreen;