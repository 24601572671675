import React from 'react';

import Num01 from '../../../../assets/icon/sitter/mobile/sitter_info_num_01.svg'
import Num02 from '../../../../assets/icon/sitter/mobile/sitter_info_num_02.svg'
import Num03 from '../../../../assets/icon/sitter/mobile/sitter_info_num_03.svg'
import Num04 from '../../../../assets/icon/sitter/mobile/sitter_info_num_04.svg'

import AngleRight from '../../../../assets/icon/sitter/mobile/angle_right.svg';

import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CupertinoBrowserDialog from '../../../common/cupertino_browser_dialog.component';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';

const StyledInput = styled.input`
    width: 100%;
    border: 0.8px solid #e8e8e9;
    border-radius: 8px;
    padding: 14px;
    box-sizing: border-box;
    margin-top: 1vh;
    margin-bottom: 3vh;

    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -2%;
    color: #454a50;

    &:focus {
        border: 0.8px solid #454a50;
        border-radius: 8px;
        max-lines: 5;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -2%;
        color: #bdc1c5;
    }

    &:user-invalid,
    &:invalid {
        border: 0.8px solid #ff5c5c;
        border-radius: 8px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
    }

    &.error {
        border: 0.8px solid #ff5c5c;
        border-radius: 8px;

        &:focus {
        border: 0.8px solid #454a50;
        border-radius: 8px;
        }
    }
`;


const Checkbox = styled.input`
    width: 20px;
    height: 20px;
    border: 0.8px solid #E8E8E9;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    margin: auto;
    padding: auto;
    margin-right: 8px;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    box-sizing: border-box;

    &:checked {
        content: url("../../../assets/icon/common/check_white.svg");
        margin: auto;
        margin-right: 8px;
        padding: auto;
        border: 0.8px solid #8B4DFF;
        border-radius: 8px;
        background-color: #9F6BFF;
        box-sizing: border-box;
        padding-top: 5.98px;
        padding-bottom: 5.98px;
        padding-left: 4.79px;
        padding-right: 4.79px;
    }

    &.error {
        border: 0.8px solid #FF5C5C;

        &:checked:before {
            border: 0.8px solid #FF5C5C;
        }
    }
`;
const Radio = styled.input`
    margin-right: 8px;
    margin-left: 0;
    margin-top: 2px;
    margin-bottom: 0;
    box-sizing: border-box;
    align-self: center;
    width: 16.67px;
    height: 16.67px;
    position: relative;
    padding: 0;
    border: 0.8px solid #e8e8e9;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;

    &:checked {
        /* border: 0.8px solid #9F6BFF; */
    }

    &:checked::after {
        /* background-color: #9F6BFF; */
        border-radius: 100%;
        content: "ㅤ";
        position: absolute;
        width: 9px;
        height: 9px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

const LastRadio = styled(Radio)`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: flex-start;
`;



const CustomTextArea = styled.textarea`
    width: 100%;
    border: 0.8px solid #E8E8E9;    
    border-radius: 8px;
    padding: 14px ;
    box-sizing: border-box;
    margin-top: 1.6vh;
    margin-bottom: 0;

    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -2%;
    color: #454A50;

    &:focus {
        border: 0.8px solid #454A50;    
        border-radius: 8px;
    }

    &::placeholder {
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -2%;
        color: #BDC1C5;        
    }

    &:invalid, &.error {
        border: 0.8px solid #FF5C5C;
        border-radius: 8px;
    }
`;


interface Content01BrowserComponentProps {

    closeDialog?: () => void; 
}
const WriteBrowserComponent: React.FC<Content01BrowserComponentProps> = ({  closeDialog }) => {
  

    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isPress, setIsPress] = React.useState<boolean>(false);
    const [invalid, setInvalid] = React.useState({
        name : false,
        phoneNumber : false,
        birthDay : false,
        address : false,
        workDetail : false,
        location : false,
        possibleTimesDetail : false,
        dogCareDetail : false,
        catCareDetail : false,
        additionalDetail : false,
        channelsDetail : false,
    })
    const [values, setvalues] = React.useState({
        name : "",
        phoneNumber : "",
        birthDay : "",
        gender : [false, false],
        address : "",
        works : [false, false, false, false, false, false, false, false, false ,false],
        workDetail : "",
        care : [false, false],
        careAnimal : [false, false],
        location : "",
        possibleDays : [false, false, false],
        possibleTimes : [false, false, false, false, false],
        possibleTimesDetail : "",
        dogCare : [false, false],
        dogCareYears : [false, false, false, false],
        dogCareDetail : "",
        catCare : [false, false],
        catCareYears : [false, false, false, false],
        catCareDetail : "",
        careDetail : "",
        additionalDetail : "",
        smoke : [false, false],  
        channels : [false, false, false, false, false, false, false, false, false, false],  
        channelsDetail : "",
        accept : false,
        diffAccept : false,
    });
    const [channels, setChannel] = React.useState<Array<string>>([])
    const works = ["직장인", "주부", "학생", "프리랜서", "무직", "전문 펫시터", "수의대생", "수의사", "수의테크니션", "직접 입력"]
    const possibleDays = ["평균 15일 이상 가능해요", "7~15일 가능해요", "평균 7일 이하 가능해요"]
    const possibleTimes = ["오전 타임(오전 8:00~오후 12:00)", "오후 타임(오후 12:00~오후 6:00)", "저녁 타임(오후 6:00~오후 10:00)", "심야 타임(오후 10:00~오전 8:00)", "직접 입력"]

    const nameRef= React.useRef<HTMLDivElement>(null);
    const phoneRef= React.useRef<HTMLDivElement>(null);
    const birthRef= React.useRef<HTMLDivElement>(null);   
    const addressRef= React.useRef<HTMLDivElement>(null);
    const genderRef= React.useRef<HTMLDivElement>(null);
    const workRef= React.useRef<HTMLDivElement>(null);
    const careTypeRef= React.useRef<HTMLDivElement>(null);
    const careAnimalRef= React.useRef<HTMLDivElement>(null);
    const locationRef= React.useRef<HTMLDivElement>(null);
    const possibleDayRef= React.useRef<HTMLDivElement>(null);
    const possibleTimeRef= React.useRef<HTMLDivElement>(null);
    const dogCareRef= React.useRef<HTMLDivElement>(null);
    const dogCareTimeRef= React.useRef<HTMLDivElement>(null);
    const dogCareDetailRef= React.useRef<HTMLDivElement>(null);
    const catCareRef= React.useRef<HTMLDivElement>(null);
    const catCareTimeRef= React.useRef<HTMLDivElement>(null);
    const catCareDetailRef= React.useRef<HTMLDivElement>(null);
    const smokeRef = React.useRef<HTMLDivElement>(null);
    const channelRef = React.useRef<HTMLDivElement>(null);
    const additionalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        var temp = ["앱 스토어, 플레이 스토어 검색", "포털 검색 (네이버, 구글 등)", "인스타그램", "당근마켓", "페이스북", "네이버 카페 (강사모, 냥이네 등)", "블로그", "지인추천", "우주펫 앱"]
        temp.sort(() => Math.random() - 0.5)
        temp.push("기타 (직접 입력)")
        setChannel(temp)
    }, [])
   


    const onChangeText = (event : any) => {
        if (event.target.value.length > event.target.maxLength && event.target.maxLength !== -1) {
            event.target.value = event.target.value.slice(0, event.target.maxLength);
        }        

        const {value, name: inputName} = event.target;
        setvalues({...values, [inputName] : value})
    }

    const onChangeInvalid = (event : any) => {
        const { name: inputName } = event.target;
        setInvalid({...invalid, [inputName] : true})
    }

    const onChangeRadio = (type : string, index : number, max : number) => {
        var temps = Array.apply(null, new Array(max)).map(Boolean.prototype.valueOf, false)
        temps[index] = true
        setvalues({...values, [type] : temps})
    }

    const onChangePossibleTimes = (index : number) => {

        var temps = values.possibleTimes;       
        
        if (index === 4 && !temps[4]) {
            temps[0] = false;
            temps[1] = false;
            temps[2] = false;
            temps[3] = false;
            temps[4] = true;
        }
        else if (temps[4] && index !== 4) {
            temps[4] = false
            temps[index] = !temps[index]
        }
        else {
            temps[index] = !temps[index]
        }

        setvalues({...values, possibleTimes : temps})
    }

    const onPrivacy = () => {
        window.open("https://spacepet.notion.site/1acee7caac7380ed9576e8b4d37bfb85", "_blank")
    }

    const getCheck = () => {
       if (values.name === "") {       
        return false
       }
       else if (values.phoneNumber === "" || values.phoneNumber.length < 7) {
        return false
       }
       else if (values.birthDay === "" || values.birthDay.length < 8) {
        return false
       }
       else if (values.address === "") {
        return false
       }
       else if (!values.gender.includes(true)) {        
        return false
       }       
       else if (!values.works.includes(true)) {
        return false
       }
       else if (values.works[9] && values.workDetail === "") {
        return false
       }
       else if (!values.care.includes(true)) {
        return false
       }
       else if (!values.careAnimal.includes(true)) {
        return false
       }
       else if (!values.possibleDays.includes(true)) {
        return false
       }
       else if (!values.possibleTimes.includes(true)) {
        return false
       }
       else if (values.possibleTimes[4] && values.possibleTimesDetail === "") {
        return false
       }
       else if (!values.dogCare.includes(true)) {
        return false
       }
       else if (!values.catCare.includes(true)) {        
        return false
       }          
        
       if (values.dogCare[0]) {
            if (!values.dogCareYears.includes(true)) {
                return false
            }
       }


       if (values.catCare[0]) {
            if (!values.catCareYears.includes(true)) {
                return false
            }
       }


       if (values.additionalDetail === "") {
        return false
       }
       else if (!values.channels.includes(true)) {
        return false
       }
       else if (values.channels[9] && values.channelsDetail === "") {
        return false
       }
       
       if (!values.accept || !values.diffAccept) {
        return false
       }
        return true
    }

    const onApply = () => {
        setIsPress(true)

        if (!getCheck()) {
            if (values.name === "") {
                nameRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (values.phoneNumber === "" || values.phoneNumber.length < 7) {
                phoneRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (values.birthDay === "" || values.birthDay.length < 8) {
                birthRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }

            else if (values.address === "") {
                addressRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.gender.includes(true)) {        
                genderRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }       
            else if (!values.works.includes(true)) {
                workRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (values.works[9] && values.workDetail === "") {
                workRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.care.includes(true)) {
                careTypeRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.careAnimal.includes(true)) {
                careAnimalRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.possibleDays.includes(true)) {
                possibleDayRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.possibleTimes.includes(true)) {
                possibleTimeRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (values.possibleTimes[4] && values.possibleTimesDetail === "") {
                possibleTimeRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.dogCare.includes(true)) {
                dogCareRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }  
            else if (!values.catCare.includes(true)) {
                catCareRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }          
    
            if (values.dogCare[0]) {
                if (!values.dogCareYears.includes(true)) {
                    dogCareTimeRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                    return false
                }
            }
        
            if (values.catCare[0]) {
                if (!values.catCareYears.includes(true)) {
                    catCareTimeRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                    return false
                }
            }    
    
            if (values.additionalDetail === "") {
                additionalRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (!values.channels.includes(true)) {
                channelRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
            else if (values.channels[9] && values.channelsDetail === "") {
                channelRef.current?.scrollIntoView({ behavior: 'smooth', block : "center" });
                return false
            }
        }
        else {
            setModalOpen(true)
        }      
    }   

    const getWorks = () => {
        if (values.works[9]) {
            return values.workDetail
        }
        else {
            return works[values.works.indexOf(true)]
        }
    }

    const getChannels = () => {
        if (values.channels[9]) {
            return values.channelsDetail
        }
        else {
            return channels[values.channels.indexOf(true)]
        }
    }

    const getCare = () => {
        var result = "";

        if (values.care[0]) {
            result += "1"
        }
        else {
            result += "0"
        }

        if (values.care[1]) {
            result += "1"
        }
        else {
            result += "0"
        }

        return result
    }


    const getAvailableTime = () => {
        if (values.possibleTimes.indexOf(true) === 4) {
            return values.possibleTimesDetail
        }
        else if (values.possibleTimes.indexOf(true) !== -1) {
            var result = [];

            if (values.possibleTimes[0]) {
                result.push(possibleTimes[0])
            }
            if (values.possibleTimes[1]) {
                result.push(possibleTimes[1])
            }
            if (values.possibleTimes[2]) {
                result.push(possibleTimes[2])
            }
            if (values.possibleTimes[3]) {
                result.push(possibleTimes[3])
            }
            
            return result.join(",")
        }
        else {
            return ""
        }
    }



    const onRealApply = async() => {
        setModalOpen(false)

        if (!loading) {
            setLoading(true)

            const uri = "https://api.spacepet.info/sitter/post"
            const config = {
                headers: {                    
                    "Content-Type" : 'application/json'
                }
            };
            var body = JSON.stringify({
                "name" : values.name,
                "phoneNumber" : values.phoneNumber.toString(),
                "birthDate" : values.birthDay.toString(),
                "gender" : values.gender.indexOf(true) === 0? 1 : 0,
                "address" : values.address,
                "work" : getWorks(),
                "careType" : getCare(),
                "dogCare" : values.careAnimal[0],
                "catCare" : values.careAnimal[1],
                "availableArea" : values.location,
                "availableDays" : values.possibleDays.indexOf(true),
                "availableTime" : getAvailableTime(),
                "hadDog" : values.dogCare[0],
                "hadDogPeriod" : values.dogCareYears.indexOf(true),
                "hadDogExperience" : values.dogCareDetail,
                "hadCat" : values.catCare[0],
                "hadCatPeriod" : values.catCareYears.indexOf(true),
                "hadCatExperience" : values.catCareDetail,
                "additionalExperience" : values.additionalDetail,
                "smoker" : values.smoke[0],
                "channel" : getChannels()
            })

            await axios.post(uri, body, config).then((result) => {

                if (result.status === 202) {
                    navigate(`/sitter/done?name=${values.name}`)
                }
                else {
                    alert("전송 실패 : 잠시 후 다시 시도해주세요")
                }
              
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                alert("전송 실패 : 잠시 후 다시 시도해주세요")
                setLoading(false)
            })

            setLoading(false)
        }
    }


  
    return (
        <>
    <div style={styles.screen}>
        {closeDialog  ? (
            <div style={styles.divFlex}>
                <span style={styles.titleTxt}>
                    이웃시터 지원하기
                </span>
                    <IconButton onClick={closeDialog} sx={{ color: "inherit" }}>
                        <Typography
                            variant="h4" 
                            component="span"
                            sx={{
                            color: "#b0b0b0",
                            fontFamily: "Arial, sans-serif", 
                            fontWeight: 100,
                            lineHeight: 1, 
                            }}
                        >
                        ✕
                        </Typography>
                    </IconButton>
            </div>
        ):(
            <>
                <span style={{...styles.titleTxt, marginTop:"30px"}}>
                    이웃시터 지원하기
                </span>
                <div style={styles.infoCon}>
                    우주펫 이웃시터는 현재 반려동물을 양육하고 있지 않아도 지원가능해요
                </div>
            </>
        ) }
        <span style={styles.subTitleTxt}>
            * 지원서의 모든 항목은 필수에요.
        </span>

        {/* ----------------------------------------------- */}


        <div style={{...styles.rowCon,marginTop:"50px", }}>
            <img src={Num01} style={styles.numIcon} alt="목차"/>
            <span style={styles.numText}>
                기본 정보
            </span>
        </div>

        <div style={styles.divider}/>
  
        <span ref={nameRef} style={(isPress && values.name === "")? styles.errorTitle : styles.title}>
            이름
        </span>

        <div style={styles.subDivider}/>
        <StyledInput        
            name={"name"} 
            value={values.name}
            inputMode="text"            
            onChange={(e) => onChangeText(e)}
            placeholder='실명 입력'
            className={invalid.name && values.name === "" ? "error" : ""}
            onClick={(e) => onChangeInvalid(e)}          
        />

        <span ref={phoneRef} style={(isPress && (values.phoneNumber === "" || values.phoneNumber.length < 7))? styles.errorTitle : styles.title}>
            연락처
        </span>
       
        <div style={styles.subDivider}/>

        <span style={{...styles.subTitle , marginTop:"6px"}}>
        이웃시터로 활동할 본인 휴대폰 번호를 입력해주세요.<br/>입력한 휴대폰 번호로 카카오 알림톡이 발송돼요.
        </span>

        <StyledInput
            className={invalid.phoneNumber &&(values.phoneNumber === "" || values.phoneNumber.length < 7) ? "error": ""}
            onClick={(e) => onChangeInvalid(e)}
            type={"number"}
            maxLength={11}
            placeholder='ex) 01012345678'
            name={"phoneNumber"} 
            value={values.phoneNumber} 
            inputMode="tel"
            onChange={(e) => onChangeText(e)}
        />

        <span ref={birthRef} style={(isPress && values.birthDay.length !== 8)? styles.errorTitle : styles.title}>
            생년월일
        </span>

        <div style={styles.subDivider}/>

        <span style={{...styles.subTitle , marginTop:"6px"}}>
            만 19세 이상부터 지원 가능해요.
        </span>

        <StyledInput 
            className={(invalid.birthDay && (values.birthDay === "" || values.birthDay.length !== 8))? "error" : ""}
            onClick={(e) => onChangeInvalid(e)}
            type={"number"}
            maxLength={8}
            name={"birthDay"} 
            value={values.birthDay}
            placeholder='ex) 19990101'
            inputMode="numeric"
            onChange={(e) => onChangeText(e)}
        />

        <span ref={genderRef} style={(isPress && !values.gender.includes(true))? styles.errorTitle : styles.title}>
            성별
        </span>
        <div style={styles.subDivider}/>
      
        <div style={{...styles.expandRowCon}}>
                <div 
                    style={(values.gender[0])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("gender", 0, 2)}
                >
                    여성
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    style={(values.gender[1])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("gender", 1, 2)}
                >
                    남성
                </div>
        </div>

        <div style={styles.divider}/>

        <span ref={addressRef} style={(isPress && values.address === "")? styles.errorTitle : styles.title}>
            거주지
        </span>
        <div style={styles.subDivider}/>

        <span style={{...styles.subTitle}}>
            현재 거주하시는 지역을 알려주세요.<br/>*시/군/구 필수 기재
        </span>

        <StyledInput 
            className={(invalid.address && values.address === "")? "error" : ""}
            onClick={(e) => onChangeInvalid(e)}
            name={"address"} 
            value={values.address}
            placeholder='ex) 경기도 분당시 / 서울시 강남구'
            onChange={(e) => onChangeText(e)}
        />

        <span ref={workRef} style={((isPress && !values.works.includes(true)) || (isPress && values.works[9] && values.workDetail === ""))? styles.errorTitle : styles.title}>
            현재 하는 일
        </span>
        
        <div style={styles.subDivider12}/>

        {
            values.works.map((item, index) => {
                return <div style={{...styles.radioCon}} >
                    <div style={styles.noneCon} >
                        {index === 9 ? (
                            <LastRadio
                                type="radio"
                                name='works'
                                checked={item}
                                onChange={() => onChangeRadio("works", index, 10)}
                            />
                        ) : (
                            <Radio
                                type="radio"
                                name='works'
                                checked={item}
                                onChange={() => onChangeRadio("works", index, 10)}
                            />
                        )}
                    </div>
                    <div style={{width : "100%"}}>
                        <span style={styles.itemText} onClick={() => onChangeRadio("works", index, 10)}>
                            {works[index]}
                        </span>
                        {
                            (index === 9 && item)?
                            <StyledInput
                                className={(invalid.workDetail && values.workDetail === "")? "error" : ""}                               
                                name="workDetail"
                                value={values.workDetail}
                                placeholder={"직접 입력해주세요."}
                                onChange={(e) => onChangeText(e)}
                                onClick={(e) => onChangeInvalid(e)}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            })
        }

        {/* ----------------------------------------------- */}


      <div style={{...styles.rowCon,marginTop:"60px",marginBottom:"10px" }}>
            <img src={Num02} style={styles.numIcon} alt="목차"/>
            <span style={styles.numText}>
                활동 정보
            </span>
        </div>

        <div style={styles.divider}/>

        <div ref={careTypeRef} style={styles.rowCon}>
            <span style={(isPress && !values.care.includes(true))? styles.errorTitle : styles.title}>
                가능한 돌봄 유형
            </span>
            <span style={styles.subTitle}>
                {"(중복 선택 가능)"}
            </span>
        </div>

        <div style={styles.subDivider}/>

        <span style={styles.subTitle}>
            * 위탁 돌봄은 시터 님의 집에서 이웃의 반려동물을 돌봐주는 것을 의미해요.
        </span>
        <div style={styles.subDivider}/>

        <div style={styles.expandRowCon}>
                <div 
                    style={(values.care[0])? styles.customButtonSelected : styles.customButton}
                    onClick={() => setvalues({...values, care : [!values.care[0], values.care[1]]})}
                >
                    방문 돌봄
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    style={(values.care[1])? styles.customButtonSelected : styles.customButton}
                    onClick={() => setvalues({...values, care : [values.care[0], !values.care[1]]})}
                >
                    위탁 돌봄
                </div>
        </div>

        <div style={styles.divider}/>
        <div ref={careAnimalRef} style={styles.rowCon}>
            <span style={(isPress && !values.careAnimal.includes(true))? styles.errorTitle : styles.title}>
                돌봄 가능한 반려동물
            </span>
            <span style={styles.subTitle}>
                {"(중복 선택 가능)"}
            </span>
        </div>

        <div style={styles.subDivider}/>
        <div style={styles.expandRowCon}>
                <div 
                    style={(values.careAnimal[0])? styles.customButtonSelected : styles.customButton}
                    onClick={() => setvalues({...values, careAnimal : [!values.careAnimal[0], values.careAnimal[1]]})}
                >
                    강아지
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    style={(values.careAnimal[1])? styles.customButtonSelected : styles.customButton}
                    onClick={() => setvalues({...values, careAnimal : [values.careAnimal[0], !values.careAnimal[1]]})}
                >
                    고양이
                </div>
        </div>

        <div style={styles.divider}/>

        {/* <span ref={locationRef} style={(isPress && values.location === "")? styles.errorTitle : styles.title}>
            이동 가능 지역
        </span>

        <div style={styles.subDivider}/>

        <span style={styles.subTitle}>
        방문 시터님은 방문 가능한 지역을 적어주세요.<br/>위탁 시터님은 픽업 가능 여부와 해당 지역을 적어주세요.
        </span>

        <input 
            className={(invalid.location && values.location === "")? "customInput error" : "customInput"}
            name={"location"} 
            value={values.location}
            placeholder='ex) 서울시 강남구, 경기도 성남시'
            onChange={(e) => onChangeText(e)}
        /> */}

        <div ref={possibleDayRef} style={styles.rowCon}>
            <span style={(isPress && !values.possibleDays.includes(true))? styles.errorTitle : styles.title}>
                활동 가능 일수
            </span>
            <span style={styles.subTitle}>
                한 달 기준
            </span>
        </div>

        <div style={styles.subDivider12}/>

        {
            values.possibleDays.map((item, index) => {
                return <div style={styles.radioCon} >
                    <Radio  
                        type="radio"
                        name='possibleDays'
                        checked={item}
                        onChange={(e) => {
                            onChangeRadio("possibleDays", index, 3)
                        }}
                    />
                    <span style={styles.itemText} onClick={() => {onChangeRadio("possibleDays", index, 3)}}>
                        {possibleDays[index]}
                    </span>
                </div>
            })
        }

        <div style={styles.divider}/>

        <div ref={possibleTimeRef} style={styles.rowCon}>
            <span style={((isPress && !values.possibleTimes.includes(true)) || (isPress && values.possibleTimes[4] && values.possibleTimesDetail === ""))? styles.errorTitle : styles.title}>
                활동 가능 시간대
            </span>
            <span style={styles.subTitle}>
                {"(중복 선택 가능)"}
            </span>
        </div>
        <div style={styles.subDivider12}/>
        
        {
            values.possibleTimes.map((item, index) => {
                return <div style={styles.radioCon}>
                    <div style={styles.noneCon} >
                        <Checkbox  
                            type={"checkbox"}
                            className="checkBox"
                            checked={item}
                            onChange={(e) => {
                                onChangePossibleTimes(index)
                            }}
                        />
                    </div>
                    <div style={{width : "100%"}}>
                        <span style={styles.itemText} onClick={() => onChangePossibleTimes(index)}>
                            {possibleTimes[index]}
                        </span>
                        {
                            (index === 4 && item)?
                            <StyledInput
                                className={(invalid.possibleTimesDetail && values.possibleTimesDetail === "")? "error" : ""}
                                name={"possibleTimesDetail"} 
                                value={values.possibleTimesDetail}
                                onClick={(e) => onChangeInvalid(e)}
                                onChange={(e) => onChangeText(e)}
                                placeholder={"직접 입력해주세요."}
                            />
                            :
                            null
                        }
                    </div>
                </div>
            })
        }     

        {/* ----------------------------------------------- */}

        <div style={styles.largeDivider}/>

        <div style={styles.rowCon}>
            <img src={Num03} style={styles.numIcon} alt="목차"/>
            <span style={styles.numText}>
                양육경험 및 경력
            </span>
        </div>

        <div style={styles.divider}/>


        <span ref={dogCareRef} style={(isPress && !values.dogCare.includes(true))? styles.errorTitle : styles.title}>
            강아지 양육 경험 유무
        </span>

        <div style={styles.subDivider}/>

        <div style={styles.expandRowCon}>
                <div 
                    style={(values.dogCare[0])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("dogCare", 0, 2)}
                >
                    있어요
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    style={(values.dogCare[1])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("dogCare", 1, 2)}
                >
                    없어요
                </div>
        </div>

        

        {
            (values.dogCare[0])?
            <>
                <div style={styles.subDivider}/>

                <span ref={dogCareTimeRef} style={(isPress && !values.dogCareYears.includes(true))? styles.errorAnimalTitle : styles.animalTitle}>
                    강아지 양육 기간
                </span>

                <div style={styles.subDivider}/>

                <div style={styles.expandRowCon}>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='dogCareYears'
                            checked={values.dogCareYears[0]}
                            onChange={(e) => {
                                onChangeRadio("dogCareYears", 0, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("dogCareYears", 0, 4)}>
                            10년 이상
                        </span>
                    </div>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='dogCareYears'
                            checked={values.dogCareYears[1]}
                            onChange={(e) => {
                                onChangeRadio("dogCareYears", 1, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("dogCareYears", 1, 4)}>
                            5년 이상
                        </span>
                    </div>
                </div>

                <div style={styles.subDivider}/>

                <div style={styles.expandRowCon}>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='dogCareYears'
                            checked={values.dogCareYears[2]}
                            onChange={(e) => {
                                onChangeRadio("dogCareYears", 2, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("dogCareYears", 2, 4)}>
                            3년 이상
                        </span>
                    </div>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='dogCareYears'
                            checked={values.dogCareYears[3]}
                            onChange={(e) => {
                                onChangeRadio("dogCareYears", 3, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("dogCareYears", 3, 4)}>
                            3년 미만
                        </span>
                    </div>
                </div>

                {/* <div style={styles.largeDivider}/>

                <div ref={dogCareDetailRef} style={styles.rowCon}>
                    <span style={(isPress && values.dogCareDetail.length < 10 && values.dogCare[0])? styles.errorAnimalTitle : styles.animalTitle}>
                        강아지 반려 경험
                    </span>
                    <span style={(isPress && values.dogCareDetail.length < 10 && values.dogCare[0])? styles.errorSubTitle :  styles.subTitle}>
                        {"(10자 이상 입력)"}
                    </span>
                </div>

                <div style={styles.subDivider}/>

                <span  style={styles.subTitle}>
                    반려하셨던 강아지의 품종, 키우게 된 동기, 기억에 남는 사건 등에 대해 자세히 알려주세요. 자세할수록 합격 확률이 올라가요.
                </span>

                <textarea 
                    className={(invalid.dogCareDetail && values.dogCareDetail.length < 10)? "customTextArea error" : "customTextArea"}
                    name={"dogCareDetail"} 
                    value={values.dogCareDetail}
                    rows={5}
                    placeholder='강아지를 반려한 경험을 자세히 적어주세요'
                    onChange={(e) => onChangeText(e)}
                    onClick={(e) => onChangeInvalid(e)}
                /> */}
            </>
            :
            null
        }

              
        <div style={styles.divider}/>        

        <span ref={catCareRef}  style={(isPress && !values.catCare.includes(true))? styles.errorTitle : styles.title}>
            고양이 양육 경험 유무
        </span>

        <div style={styles.subDivider}/>

        <div style={styles.expandRowCon}>
                <div 
                    style={(values.catCare[0])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("catCare", 0, 2)}
                >
                    있어요
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    style={(values.catCare[1])? styles.customButtonSelected : styles.customButton}
                    onClick={() => onChangeRadio("catCare", 1, 2)}
                >
                    없어요
                </div>
        </div>

        <div style={styles.subDivider}/>

        {
            (values.catCare[0])?
            <>

                <span ref={catCareTimeRef} style={(isPress && !values.catCareYears.includes(true))? styles.errorAnimalTitle : styles.animalTitle}>
                    고양이 양육 기간
                </span>

                <div style={styles.subDivider}/>

                <div style={styles.expandRowCon}>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='catCareYears'
                            checked={values.catCareYears[0]}
                            onChange={(e) => {
                                onChangeRadio("catCareYears", 0, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("catCareYears", 0, 4)}>
                            10년 이상
                        </span>
                    </div>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='catCareYears'
                            checked={values.catCareYears[1]}
                            onChange={(e) => {
                                onChangeRadio("catCareYears", 1, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("catCareYears", 1, 4)}>
                            5년 이상
                        </span>
                    </div>
                </div>

                <div style={styles.subDivider}/>
                
                <div style={styles.expandRowCon}>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='catCareYears'
                            checked={values.catCareYears[2]}
                            onChange={(e) => {
                                onChangeRadio("catCareYears", 2, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("catCareYears", 2, 4)}>
                            3년 이상
                        </span>
                    </div>
                    <div style={styles.expandCon}>
                        <Radio  
                            type="radio"
                            name='catCareYears'
                            checked={values.catCareYears[3]}
                            onChange={(e) => {
                                onChangeRadio("catCareYears", 3, 4)
                            }}
                        />
                        <span style={styles.itemText} onClick={() => onChangeRadio("catCareYears", 3, 4)}>
                            3년 미만
                        </span>
                    </div>
                </div>

                {/* <div style={styles.largeDivider}/>

                <div ref={catCareDetailRef}  style={styles.rowCon}>
                    <span style={(isPress && values.catCareDetail.length < 10 && values.catCare[0])? styles.errorAnimalTitle : styles.animalTitle}>
                        고양이 반려 경험
                    </span>
                    <span style={(isPress && values.catCareDetail.length < 10 && values.catCare[0])? styles.errorSubTitle :  styles.subTitle}>
                        {"(10자 이상 입력)"}
                    </span>
                </div>

                <div style={styles.subDivider}/>

                <span style={styles.subTitle}>
                    반려하셨던 고양이의 품종, 키우게 된 동기, 기억에 남는 사건 등에 대해 자세히 알려주세요. 자세할수록 합격 확률이 올라가요.
                </span>

                <textarea 
                    className={(invalid.catCareDetail && values.catCareDetail.length < 10)? "customTextArea error" : "customTextArea"}
                    name={"catCareDetail"} 
                    value={values.catCareDetail} 
                    rows={5}
                    placeholder='고양이를 반려한 경험을 자세히 적어주세요'
                    onChange={(e) => onChangeText(e)}
                    onClick={(e) => onChangeInvalid(e)}
                /> */}

            </>
            :
            null

        }
        <div style={styles.divider}/>      

        <span ref={additionalRef} style={(isPress && values.additionalDetail === "")? styles.errorTitle : styles.title}>
            본인 소개 및 반려동물 관련 경험
        </span>

        <div style={styles.subDivider}/>

        <span style={styles.subTitle}>
            우주펫 이웃시터로 지원하게 된 이유 또는 활동하고 싶은 이유를 알려주세요. 반려동물과 관련한 경험과 경력을 함께 적어주시면 더욱 좋아요.
        </span>

        <CustomTextArea 
            className={(invalid.additionalDetail && values.additionalDetail === "")? "error" : ""}
            name={"additionalDetail"} 
            value={values.additionalDetail}
            rows={5}
            maxLength={1900}
            placeholder='ex) 고양이, 강아지를 정말 좋아해서 다른 강아지, 고양이 친구들에게 도움이 되어주고 싶어 지원했어요. 강아지를 10년 넘게 키웠기 때문에 누구보다 강아지를 잘 케어해줄 자신도 있고, 지인들 반려동물도 몇 번 돌봄해준 적이 있어서 어떤 부분을 챙겨야 하는지도 잘 알고 있어요.'
            onChange={(e) => onChangeText(e)}
            onClick={(e) => onChangeInvalid(e)}
            
        />


        {/* ----------------------------------------------- */}

        <div style={styles.largeDivider}/>

        <div style={styles.rowCon}>
            <img src={Num04} style={styles.numIcon} alt="목차"/>
            <span style={styles.numText}>
                기타 정보
            </span>
        </div>

        <div style={styles.divider}/>

        {/* <span ref={smokeRef} style={(isPress && !values.smoke.includes(true))? styles.errorTitle : styles.title}>
            흡연 여부
        </span>

        <div style={styles.divider}/>

        <div style={styles.expandRowCon}>
                <div 
                    className={(values.smoke[0])? "customButton selected" : "customButton"}
                    onClick={() => onChangeRadio("smoke", 0, 2)}
                >
                    흡연자에요
                </div>
                <div style={styles.buttonDivider} />
                <div 
                    className={(values.smoke[1])? "customButton selected" : "customButton"}
                    onClick={() => onChangeRadio("smoke", 1, 2)}
                >
                    비흡연자에요
                </div>
        </div>

        <div style={styles.largeDivider}/> */}

        <span ref={channelRef} style={((isPress && !values.channels.includes(true) || (isPress && values.channels[9] && values.channelsDetail === "")))? styles.errorTitle : styles.title}>
            유입 경로
        </span>

        <div style={styles.subDivider12}/>

        {
            values.channels.map((item, index) => {
                return <div style={styles.radioCon} >
                    <div style={styles.noneCon} >
                       {index === 9 ? (
                            <LastRadio
                            type="radio"
                            name="channels"
                            checked={item}
                            onChange={() => onChangeRadio("channels", index, 10)}
                            />
                        ) : (
                            <Radio
                            type="radio"
                            name="channels"
                            checked={item}
                            onChange={() => onChangeRadio("channels", index, 10)}
                            />
                        )}
                    </div>
                    <div style={{width : "100%"}}>
                        <span style={styles.itemText} onClick={() =>  onChangeRadio("channels", index, 10)}>
                            {channels[index]}
                        </span>
                        {
                            (index === 9 && item)?
                            <StyledInput
                                className={(invalid.channelsDetail && values.channelsDetail === "")? "error" : ""}
                                name={"channelsDetail"} 
                                value={values.channelsDetail}
                                onChange={(e) => onChangeText(e)}
                                onClick={(e) => onChangeInvalid(e)}
                                placeholder='직접 입력해주세요.'
                            />
                            :
                            null
                        }
                    </div>
                </div>
            })
        }

        <div style={styles.divider}/>

        <span style={styles.title}>
            동의 항목
        </span>

        <div style={styles.subDivider12}/>

        <div style={styles.rowCon}>
            <Checkbox
                type={"checkbox"}
                className={(isPress && !values.accept)? "error" : ""}
                checked={values.accept}
                onChange={(e) => {
                    setvalues({...values, accept : !values.accept})
                }}
            />
            <div style={styles.rowCon}>
                <span style={(isPress && !values.accept)? styles.errorAcceptText :  styles.acceptText} onClick={(e) => {
                    setvalues({...values, accept : !values.accept})
                }}>
                    개인정보 수집 및 이용에 동의합니다.
                </span>
                <img src={AngleRight} alt="개인정보" style={styles.angleRight}  onClick={() => onPrivacy()}/>
            </div>
        </div>

        <div style={styles.subDivider}/>

        <div style={styles.acceptRowCon}>
            <div style={styles.noneCon} >   
                <Checkbox                    
                    type={"checkbox"}
                    className={(isPress && !values.diffAccept)?  "error" : ""}
                    checked={values.diffAccept}
                    onChange={(e) => {
                        setvalues({...values, diffAccept : !values.diffAccept})
                    }}
                />
            </div>            

            <span style={(isPress && !values.diffAccept)? styles.errorAcceptText :  styles.acceptText} onClick={(e) => setvalues({...values, diffAccept : !values.diffAccept})}>
                신청서에 작성한 내용이 사실과 다를 경우 우주펫 이웃시터 활동이 불가할 수 있습니다
            </span>
        </div>

        <div style={styles.divider}/>

        <span style={styles.lastText}>
            * 모든 문항 답변 시 제출이 가능해요
        </span>

        <div style={styles.subDivider10}/>

        <div style={(getCheck())? styles.applyButton : styles.failButton } onClick={() => onApply()}>
            지원서 제출하기
        </div>


        <div style={styles.divider}/>


    </div>

    <CupertinoBrowserDialog 
        open={modalOpen}
        onApply={() => onRealApply()}
        onClose={() => setModalOpen(false)}
        title={"최종 제출"}
        content='지원서를 제출하시겠어요?'
        applyText='확인'
        exitText='취소'
    />

</>
    )
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : 40,
        paddingBottom : 40,
        boxSizing : "border-box",
        paddingLeft: 40,
        paddingRight : 40,        
    },
  
    rowCon : {
        display : "flex",
        flexDirection : "row",
        alignItems : "center",
        justifyContent : "flex-start",
 
    },
   
    titleTxt : {
        fontSize : "32px",
        color : "#000000",
        letterSpacing : "-3%",
        fontWeight : 700,
        fontFamily:"Pretendard",
        lineHeight:"140%",
    },
    title : {
        fontSize : 18,
        fontWeight : 700,
        letterSpacing : "-3%",
        color : "#000000",
        boxSizing : 'border-box',
        marginRight : 4,
        fontFamily:"Pretendard",
        lineHeight:"120%",
    },
    errorTitle : {
        fontSize : 18,
        fontWeight : 600,
        letterSpacing : -0.3,
        color : "#FF5C5C",
        boxSizing : 'border-box',
        marginRight :4
    },
    animalTitle : {
        fontSize : 14,
        fontWeight : 600,
        letterSpacing : -0.3,
        color : "#454A50",
        boxSizing : 'border-box',
        marginRight :4,
        marginTop:"10px"
    },
    errorAnimalTitle : {
        fontSize : 14,
        fontWeight : 600,
        letterSpacing : -0.3,
        color : "#FF5C5C",
        boxSizing : 'border-box',
        marginRight :4
    },
    subTitle : {
        fontSize : "13px",
        fontWeight : 400,
        letterSpacing :" -2%",
        color : "#9FA5AD",
        fontFamily:"Pretendard",
        lineHeight:"130%"
    },
    subTitleTxt : {
        fontSize : "20px",
        fontWeight : 400,
        letterSpacing :" -2%",
        color : "#9FA5AD",
        fontFamily:"Pretendard",
        lineHeight:"130%"
    },
    errorSubTitle : {
        fontSize : 12,
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#FF5C5C"
    },
  
    itemText : {
        fontSize : 14,
        fontWeight : 500,
        letterSpacing : -0.3,
        color : "#62686D",
    },
    acceptText : {
        fontSize : 15,
        fontWeight : 400,
        letterSpacing : "-2%",
        color : "#62686D"
    },
    errorAcceptText : {
        fontSize : 15,
        fontWeight : 400,
        letterSpacing : "-2%",
        color : "#FF5C5C"
    },
 
    numIcon : {
        width : 20,
        height : 20,
    },
    numText : {
        marginLeft : 8,
        boxSizing : "border-box",
        fontSize : 20,
        fontWeight : 700,
        letterSpacing : "-2%",
        lineHeight:"100%",
        color : "#9F6BFF",
        fontFamily: "Pretendard",
    },
    largeDivider : {
        height : 70
    },
    divider : {
        height : 30
    },
    subDivider : {
        height : 6
    },
    subDivider10 : {
        height :10
    },
    subDivider12 : {
        height : 12
    },
    radioCon : {
        width : "100%",
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",
        marginBottom : 14,
        marginLeft : 0,
        marginRight : 0,
        boxSizing : "border-box",
    },
    expandRowCon : {
        width : "99%",
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        marginTop:"6px",
    },
    expandCon : {
        flex : 1,
        display : "flex",
        flexDirection : "row",
    },
    buttonDivider : {
        width : 8
    },
    acceptRowCon : {
        display : 'flex',
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start"
    },
    lastText : {
        width : "100%",
        fontSize : 13,
        fontWeight : 400,
        color : "#9FA5AD",
        textAlign : "center",
        lineHeight : "130%",
        letterSpacing : "-2%",
        display : "flex",
        alignSelf : "center",
        alignItems: "center",
        justifyContent : "center",
        paddingTop:"10vh"
    },
    applyButton : {
        width : "100%",
        height:"1.8vw",
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
        borderRadius : "8px",
        backgroundColor : "#9F6BFF",
        paddingTop : 11.5,
        paddingBottom : 11.5,
        fontWeight : 700,
        fontSize : 16,
        color : "white",
    },
    failButton : {
        width : "100%",
        height:"1.8vw",
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
        borderRadius : "8px",
        backgroundColor : "#F4F4F5",
        paddingTop : 11.5,
        paddingBottom : 11.5,
        fontWeight : 700,
        fontSize : 16,
        color : "#E8E8E9",
    },
    infoCon : {
        paddingLeft : "2.1250vw",
        paddingRight : "2.1250vw",
        paddingTop : "2.1250vw",
        paddingBottom : "2.1250vw",
        borderRadius : "8px",
        backgroundColor : "#F9F5FF",
        color : "#9F6BFF",
        fontSize : "20px",
        fontWeight : 500,
        marginTop : "1.8750vw",
        marginBottom : "1vw"
    },
    noneCon :{
        margin : "0px",
        padding : "0px",
        display : "flex",
        
    },
    angleRight :{
        width : "13px",
        height : "13px",
        marginTop : 0,
        marginBottom : 0,
        marginRight : 0,
        marginLeft : "10px",
        cursor : "pointer",
    },

    customButton: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '13.5px',
        paddingBottom: '13.5px',
        margin: 'auto',
        boxSizing: 'border-box',
        border: '0.8px solid #E8E8E9',
        borderRadius: '8px',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '14px',
        lineHeight: '120%',
        letterSpacing: '-2%',
        color: '#62686D',
        fontWeight: 500,
        // verticalAlign: 'bottom',
    },
    customButtonSelected: {
        border: '0.8px solid #9F6BFF',
        borderRadius: '8px',
        fontWeight: 600,
        lineHeight: '120%',
        color: '#9F6BFF',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '13.5px',
        paddingBottom: '13.5px',
        margin: 'auto',
        boxSizing: 'border-box',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '14px',
        letterSpacing: '-2%',
        
    },
    divFlex:{
        display:"flex",
        width:"100%",
        justifyContent:"space-between",
        alignItems:"center"
    },

    
    
}










export default WriteBrowserComponent;