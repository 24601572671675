import React from 'react';

import TitleLogo from '../../../assets/icon/sitter/mobile/title_logo.svg';
import { useNavigate } from 'react-router-dom';


const HeaderBrowserComponent = ({...props}) : React.ReactElement => {

    const navigate = useNavigate();

    const pressMain = () => {
        navigate("/")
    }
  
    return <div style={styles.header}>
        <img src={TitleLogo} style={styles.titleLogo} alt="로고" onClick={() => pressMain()}/>
        <p style={styles.menuTxt} onClick={() => pressMain()} >우주펫 소개</p>
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    header : {
        maxWidth : "100%",
        backgroundColor : "white",
        display : 'flex',
        top : 0,
        position : "fixed",
        zIndex : 1000,
        boxSizing : "border-box",
        left: "50%",
        transform: "translate(-50%, 0)",
        width:"100%",
        justifyContent : "space-between",
        flexDirection: "row",
        alignItems: "center",
        padding: "24px 9.375vw",
        cursor: "pointer",
    },

    titleCon : {
        display : "flex",
        boxSizing : "border-box",
        paddingBottom : 0,
    },
    titleLogo : {
        width : "200px",
        height : "72px",
        cursor : "pointer"
    },
    menuTxt:{
        color:"#62686D",
        fontFamily:"Pretendard",
        fontWeight:"600",
        fontSize:"26px",
        lineHeight:"120%",
        letterSpacing:"-2%",
        marginRight:"50px"
    },
}



export default HeaderBrowserComponent;