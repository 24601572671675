import React from 'react';

import BackgroundImage from '../../../assets/images/sitter/web/condition_background.png'

const Content05BrowserComponent = ({...props}) : React.ReactElement => {

    return <div style={styles.screen}>
        <div style={styles.divFlex}> 

            <div style={styles.divFlexColumn}> 
                <span style={styles.titleTxt}>
                    지원 자격
                </span>
                <span style={styles.contentTxt}>
                    강아지/고양이를 
                </span>
                <span style={styles.contentTxt}>
                    사랑하는 만 19세 이상 성인
                </span>
            </div>
            <div style={{ ... styles.divFlexColumn, marginRight:"30px"}}> 
            <img src={BackgroundImage} style={styles.backgroundImage} alt="이미지"/>
            </div>

        </div>
    </div>
}

const styles : Record<string, React.CSSProperties> = {
    screen : {
        backgroundColor : "white",
        display : "flex",
        flexDirection : "column",
        boxSizing : "border-box",
        paddingTop : "7.8125vh",
        paddingBottom : "6.7188vh",
        paddingLeft:"6.1111vw",
        paddingRight:"6.1111vw"
    },
    titleTxt : {
        fontSize : 40,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "140%",
        fontFamily:"Pretendard",
        marginBottom:"30px",
        paddingLeft:"80px"
    },
    contentTxt : {
        fontSize : 40,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "150%",
        fontFamily:"Pretendard",
        color:"#1B1D1F",
        paddingLeft:"80px"
    },
    backgroundImage : {
        width : "100%",
        marginTop : 44,
        
    },
    divFlex : {
        display:"flex" , 
        flexDirection:"row",
        justifyContent:"space-between",
    },
    divFlexColumn:{
        display:"flex" , 
        flexDirection:"column",
    },
}

export default Content05BrowserComponent;