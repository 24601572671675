import React from 'react';
import { CDN } from '../../../keys';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Logo from '../../../assets/icon/logo/mobile_download_logo.svg';
import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';
import AngleLeft from '../../../assets/icon/common/angle_right_white.svg';


const Component05 = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.partnerIntroScreen05} >

        <div>

            <div style={styles.centerCon} >
                <img src={Logo} alt="" style={styles.partnerIntro05Logo} />
            </div>

            <div style={{height : "34.6875vw"}}/>
            
            <div style={styles.partnerIntro05IconCon}>
            
                <div style={styles.partnerIntro05IconColCon}>
                    <img src={Icon01} alt="" style={styles.partnerIntro05Icon} />
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05SemiTxt}>펫 돌봄 어플<br/>월 이용자 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05MainTxt}>1위</p>
                </div>
                
                <div style={styles.partnerIntro05IconColCon}>
                    <img src={Icon02} alt="" style={styles.partnerIntro05Icon} />
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05SemiTxt}>반려인<br/>정회원 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05MainTxt}>약 5만명</p>
                </div>

                <div style={styles.partnerIntro05IconColCon}>
                    <img src={Icon03} alt="" style={styles.partnerIntro05Icon} />
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05SemiTxt}>월간<br/>돌봄 신청 수</p>
                    <div style={{height : "3.1250vw"}} />
                    <p style={styles.partnerIntro05MainTxt}>약 1천건</p>
                </div>
                

            </div>
        </div>

        <div style={styles.centerCon} >
        
            <span style={styles.introCom6Txt}>
                펫 돌봄 어플 1위 <span style={{color : "#8B4DFF"}}>우주펫</span>에<br/>
                우리 매장을 등록해 보세요!
            </span>

            <div style={{height : "16.2500vw"}}/>

            {/* <div className="introCom6Btn">
                <span className='introCom6BtnTxt'>지금 무료로 매장 등록하기</span>
                <div style={{width : "1.5625vw"}}/>
                <img src={AngleLeft} alt="" className="introCom6AngleIcon"/>
            </div> */}


        </div>
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    partnerIntroScreen05: { width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", paddingTop: "15.625vw", paddingBottom: "15.625vw", margin: "0", boxSizing: "border-box", },  
    centerCon: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", },  
    partnerIntro05Logo: {width:"38.4375vw", display:"flex", alignSelf:"center"},  
    partnerIntro05IconCon: { width: "100vw", paddingLeft: "10.9375vw", paddingRight: "10.9375vw", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", boxSizing: "border-box", margin: "0", },  
    partnerIntro05IconColCon: { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", },  
    partnerIntro05Icon: { width:"15.6250vw", height:" 15.6250vw"},  
    partnerIntro05SemiTxt: { fontFamily: "Pretendard", fontWeight: 400, fontSize: "4vw", lineHeight: "4.775vw", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", padding: "0", margin: "0", },  
    partnerIntro05MainTxt: { fontFamily: "Pretendard", fontWeight: 600, fontSize: "5vw", lineHeight: "5.9656vw", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", padding: "0", margin: "0", },  
    introCom6Txt: { fontFamily: "Pretendard", fontSize: "5.625vw", lineHeight: "7.875vw", fontWeight: 700, letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#1B1D1F", },  
});


export default Component05;