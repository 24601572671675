import React from 'react';
import { isIOS } from 'react-device-detect';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AppStoreLink, PlayStoreLink } from '../../../data/urls';

const MainFooter = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    const onStart = () => {
        if (isIOS) {
            window.open(AppStoreLink);
        }
        else {
            window.open(PlayStoreLink);
        }
    }

    return <div style={styles.footerButton} className='footerButton' onClick={() => onStart()}>
        돌봄 부탁하기
    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    footerButton: {display:"flex", flexDirection:"row",justifyContent:"center", alignItems:"center",zIndex:"999", boxSizing:"border-box", bottom:0,left:0, position:"fixed", width:"100%", paddingTop:"18.5px", paddingBottom:"18.5px", backgroundColor:"#9F6BFF", fontFamily:"Pretendard", fontSize:"5vw", fontWeight:"700", lineHeight:"6.5vw", letterSpacing:"-0.01em", textAlign:"center", color:"white",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", },
  
});

export default MainFooter;