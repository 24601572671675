import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Footer = ({...props}) : React.ReactElement => {

    const navigate = useNavigate()  

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const styles = getStyles(isMobile); 

    const onStart = () => {
       //navigate("/partner/apply")
       window.open("https://spacepetpartner.page.link/event");
    }

    return <div style={styles.footerCon02}  onClick={() => onStart()}>
        <p style={styles.footerTxt} >
            매장 무료로 등록하기
        </p>
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    footerCon02: { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: '7.5vw', paddingRight: '7.5vw', zIndex: 999, boxSizing: 'border-box', bottom: 0, left: 0, position: 'fixed', width: '100vw', height: '10vh', backgroundColor: '#8B4DFF' },
    footerTxt: { padding: 0, margin: 0, fontSize: '5.0000vw', fontFamily: 'Pretendard', fontWeight: 700, letterSpacing: '-1%', lineHeight: '6.5000vw', color: 'white', whiteSpace: 'nowrap', textShadow: 'none' },
});


export default Footer;