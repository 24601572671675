import React from "react";


interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string; 
  }


export const ImageComponent: React.FC<ImageProps> = ({  src,style }) => {
   
    const handleError = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.currentTarget as HTMLImageElement;

       
        const width = style?.width ? `${style.width}` : "";
        const height = style?.height ? `${style.height}` : "";

        img.style.visibility = "hidden"; 
        img.style.width = width || "";
        img.style.height = height || "";
    };

    const handleLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.currentTarget as HTMLImageElement;

        // 정상 이미지일 때만 원래 스타일 적용
        img.style.visibility = "visible";
        img.style.width = "";
        img.style.height = "";
        Object.assign(img.style, style || {});
    
    };


    return <>
        <img
            src={src}
            style={style}
            onLoad={handleLoad}
            onError={handleError}
        />
    </>

        
}




export default ImageComponent;