import React from "react";
import Icon01 from '../../../assets/icon/partner/users_table.svg';
import Icon02 from '../../../assets/icon/partner/users_character.svg';
import Icon03 from '../../../assets/icon/partner/animal_table.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Component06 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const onButton = () => {
        window.open("/spacepet_partner_info.pdf")
    }
   
    return (
        <div style={styles.componentIntro} >    

            <p style={styles.componentIntroTitleTxt}>
                우주펫 소개
            </p>

            <div style={{height : "6.8966vh"}}/>
            
            <div style={styles.subIntroCon}>
                <img src={Icon01} alt="" style={{width: "16.8219vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p style={styles.introTxt} >
                    펫 돌봄 어플 월 평균 이용자 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p  style={styles.introContentTxt}>
                    1위
                </p>
            
            </div>

            <div style={{height : "10.3448vh"}}/>

            <div style={styles.subIntroCon}>
                <img src={Icon02} alt="" style={{width: "20.3125vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p style={styles.introTxt} >
                    반려인 정회원 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p  style={styles.introContentTxt}>
                    약 50,000명
                </p>
            </div>
            
            <div style={{height : "10.3448vh"}}/>

            <div style={styles.subIntroCon}>
                <img src={Icon03} alt="" style={{width: "16.8219vw"}}/>
                <div style={{height : "3.4483vh"}}/>
                <p style={styles.introTxt} >
                    월간 돌봄 신청 수
                </p>
                <div style={{height : "0.5172vh"}}/>
                <p  style={styles.introContentTxt}>
                    약 1,000건
                </p>
            </div>

            <div style={{height : "5.1724vh"}}/>
            
            <p style={styles.introSemiTxt}>
                * 24년 9월 기준
            </p>            

            <div style={{height : "10.3448vh"}}/>
            
            <p style={styles.introSemiTxt02}>
                우주펫 파트너에 대한 정보를<br/>더 알고 싶으신가요?
            </p>

            <div style={{height : "3.4483vh"}}/>
            
            <div style={styles.component06Btn} onClick={() => onButton()}>
                소개서 읽어보기
            </div>


        </div>

    );
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    componentIntro: { width: "100vw", backgroundColor: "#F9F5FF", paddingLeft: "7.5vw", paddingRight: "7.5vw", paddingTop: "6.8966vh", paddingBottom: "6.8966vh", margin: 0, boxSizing: "border-box", },  
    componentIntroTitleTxt: { fontFamily: "'Pretendard'", fontWeight: 700, fontSize: "6.8750vw", lineHeight: "9.6250vw", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "black", margin: 0, padding: 0, },  
    subIntroCon: { width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", },  
    introTxt: { fontFamily: "'Pretendard'", fontSize: "5.6250vw", lineHeight: "7.8750vw", fontWeight: 500, letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#454A50", textShadow: "none", margin: 0, padding: 0, },  
    introContentTxt: { fontFamily: "'Pretendard'", fontSize: "6.8750vw", lineHeight: "9.6250vw", fontWeight: 700, letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#1B1D1F", textShadow: "none", margin: 0, padding: 0, },  
    introSemiTxt: { fontFamily: "'Pretendard'", fontSize: "4.6875vw", lineHeight: "6.5625vw", fontWeight: 400, letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#62686D", textShadow: "none", margin: 0, padding: 0, },  
    introSemiTxt02: { fontFamily: "'Pretendard'", fontSize: "5.6250vw", lineHeight: "7.8750vw", fontWeight: 800, letterSpacing: "-0.03em", textAlign: "center", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", color: "#1B1D1F", textShadow: "none", margin: 0, padding: 0, },  
    component06Btn: { width: "100%", marginTop: 0, marginBottom: 0, paddingTop: "4.3750vw", paddingBottom: "4.3750vw", borderRadius: "36px", border: "solid 0.8px #9F6BFF", backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "'Pretendard'", fontWeight: 600, fontSize: "5.0000vw", lineHeight: "6.0000vw", letterSpacing: "-0.03em", textAlign: "center", color: "#9F6BFF", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", boxSizing: "border-box", cursor: "pointer", },  


});

export default Component06;