import React from 'react';
import Dialog from '@mui/material/Dialog';

export interface SimpleDialogProps {
    open: boolean;
    title : string;
    content : string;
    applyText : string;
    exitText : string;
    onClose: () => void;
    onApply: () => void;    
}
  
function CupertinoBrowserDialog(props: SimpleDialogProps) {
    const { onClose, open, onApply, title, content, applyText, exitText } = props;

    const handleClose = () => {
        onClose();
    };

    const handleApply = () => {
        onApply();
    }

    return (
        <Dialog 
            onClose={handleClose} 
            open={open}            
            PaperProps={{
                style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    overflow : 'hidden',
                    width : "100%",
                    margin : "20px"
                },
            }}
        >
            <div style={styles.screen}>
                <span style={styles.title}>{title}</span>
                <span style={styles.content}>{content}</span>

                <div style={styles.rowCon}> 
                    <div style={styles.exitButton} onClick={() => handleClose()}>
                        {exitText}
                    </div>

                    <div style={{width : 8}}/>

                    <div style={styles.applyButton} onClick={() => handleApply()}>
                        {applyText}
                    </div>
                </div>
            </div>   
        </Dialog>
    );
}


const styles : Record<string, React.CSSProperties> = {
    screen : {        
        borderRadius : "14px",
        paddingTop : 20,
        paddingBottom : 20,
        paddingLeft : 14,
        paddingRight : 14,
        boxSizing : "border-box",
        display : "flex",
        flexDirection : "column",
        backgroundColor : "white",
        margin : 0,
        
    },
    title : {
        color : "black",
        fontWeight : 700,
        fontSize : 18,
        lineHeight : "120%",
        letterSpacing : "-2%",
        textAlign : "center",
        opacity : "100%",
    },
    content : {
        color : "black",
        fontWeight : 400,
        fontSize : 14,
        lineHeight : "120%",
        letterSpacing : "-2%",
        textAlign : "center",
        opacity : "100%",
        marginTop : 16,
        marginBottom : 22,
        marginLeft : 0,
        marginRight : 0,
    },

    rowCon : {
        width : "100%",
        display : "flex",
        flexDirection : "row",
        alignItems : "center",
        justifyContent : "center",
    },
    exitButton : {
        flex : 1,
        height : 44,
        fontSize : 14,
        fontWeight : 500,
        color : "#62686D",
        letterSpacing : "-2%",
        lineHeight : "120%",
        textAlign : "center",
        display : "flex",
        alignItems : "center",
        justifyContent : "center",
        backgroundColor : "#F4F4F5",
        borderRadius : "8px",
    },
    applyButton : {
        flex : 1,
        height : 44,
        fontSize : 14,
        fontWeight : 600,
        color : "white",
        letterSpacing : "-2%",
        lineHeight : "120%",
        textAlign : "center",
        alignItems : "center",
        display : "flex",
        justifyContent : "center",
        backgroundColor : "#9F6BFF",
        borderRadius : "8px",
    },   

}
      

export default CupertinoBrowserDialog