import React from 'react';
import ComponentImage01 from '../../../assets/images/main/mobile/component_01.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Main01Component = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return (
       <>
        <div style={styles.component} >

            <div style={{height : "10.7448vh"}}/>
      
            <div style={styles.textContainer01} >
                <span style={styles.titleText} >
                    우리 아이 돌봐줄 사람<br/>
                    여기 다 있어요!
                </span>

                <div style={{height : 12}}/>
                <span style={styles.contentText} >
                    출장, 여행 등으로 강아지 고양이<br/>
                    돌봐줄 사람 필요할 때 쉽고 빠르게 찾아보세요!
                </span>

                <div style={{height : 6}}/>
                <span style={styles.contentMainColorBoldText} >
                    #반려인  #펫시터  #펫호텔∙유치원
                </span>
            </div>

            <div style={{height : 6}}/>
            <img 
                src={ComponentImage01}
                style={styles.componentImage01} 
                alt="imageComponent"
            />

        </div>
       </>
    )
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    component: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:'flex-start'},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    contentText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D"},
    contentMainColorBoldText: {fontFamily:"Pretendard", fontSize:"4.06vw", lineHeight:"5.2vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF"},
    componentImage01: {width:"100vw", alignItems:"center", paddingLeft:"77px", paddingRight:"77px", boxSizing:"border-box"},
  
});

export default Main01Component