import React from 'react';

import QuestionOnIcon from '../../../assets/icon/sitter/mobile/question_on.svg';
import QuestionOffIcon from '../../../assets/icon/sitter/mobile/question_off.svg';



const Component07 = ({...props}) : React.ReactElement => {
   
    

    const [visible01, setVisible01] = React.useState(false);
    const [visible02, setVisible02] = React.useState(false);
    const [visible03, setVisible03] = React.useState(false);
    const [visible04, setVisible04] = React.useState(false);
    const [visible05, setVisible05] = React.useState(false);
    const [visible06, setVisible06] = React.useState(false);

    return <div style={styles.com7ColorCon}> 

        <span style={styles.title}>
            자주 묻는 질문
        </span>

        <div style={{height : 70}}/>

        <div style={visible01? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible01(!visible01)}}>
            <div style={styles.rowCon}>
                {
                    (visible01)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible01)? styles.titleTxt : styles.unSelectInfoTxt}>
                        돌봄 요청이 무엇인가요?
                    </span>
                   
                    
                </div>
            </div>
        </div>

        {
            (visible01)? 
            <span style={styles.contentTxt}>
                돌봄 요청은 <span style={styles.contentWeightTxt}>돌봄이 필요한 우주펫 회원들이 작성한 글</span>이에요.<br/>
                돌봄이 필요한 일정과 반려동물의 정보, 내용 등을 확인하고 제안을 보낼 수 있어요<br/>
                매장의 제안을 받은 보호자님이 우리 매장을 선택할 가능성이 더 올라가요.
            </span>
            : null
        }

        <div style={visible02? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible02(!visible02)}}>
            <div style={styles.rowCon}>
                {
                    (visible02)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible02)? styles.titleTxt : styles.unSelectInfoTxt}>
                    자동 카카오 알림톡은 어떻게 설정할 수 있나요?
                    </span>
                                     
                </div>
            </div>
        </div>

        {
            (visible02)? 
            <div style={styles.colCon}>
                <span style={styles.contentTxt}>
                    별도의 설정을 하지 않아도 우주펫 파트너 앱에서 예약 일정을 등록하면 고객의 휴대폰 번호로 자동 알림톡이 발송돼요.<br/>
                    등록한 일정이 시작하기 한 시간 전에는 고객이 예약을 잊지 않도록 리마인드 알림도 보내드려요.
                </span>
            </div>
            : null
        }   


        <div style={visible03? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible03(!visible03)}}>
            <div style={styles.rowCon}>
                {
                    (visible03)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible03)? styles.titleTxt : styles.unSelectInfoTxt}>
                        카카오 알림톡 발송 내용을 수정할 수는 없나요?
                    </span>
                    
                    
                </div>
            </div>
        </div>

        {
            (visible03)? 
            <span style={styles.contentTxt}>
                예약 일정 상에 변경 사항이 생겼다면 일정을 수정해주세요.<br/>
                돌봄 일시, 이용 서비스, 이용 금액, 안내 사항 등 수정된 사항에 대해서도 카카오 알림톡이 자동 발송돼요.<br/>
                다만, 정해진 알림톡 형태 외 커스텀하여 발송하기는 어려운 점 양해 부탁드려요.
            </span>
            : null
        }

        <div style={visible04? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible04(!visible04)}}>
            <div style={styles.rowCon}>
                {
                    (visible04)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible04)? styles.titleTxt : styles.unSelectInfoTxt}>
                        기존에 등록되어 있는 우리 매장 정보는 무엇인가요?
                    </span>
                    
                    
                </div>
            </div>
        </div>

        {
            (visible04)? 
            <span style={styles.contentTxt}>
                사장님 매장이 더 많은 반려인 회원들에게 소개될 수 있도록 우주펫 팀이 직접 네이버, 인스타그램 등 공개되어 있는 정보를 확인하여 사전에 수집하여 올려놓은 정보예요.<br/>
                일부 잘못된 정보가 있을 수 있는 점 양해 부탁드리며, 잘못된 정보는 우주펫 파트너 앱에서 수정하실 수 있어요.
            </span>
            : null
        }

        <div style={visible05? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible05(!visible05)}}>
            <div style={styles.rowCon}>
                {
                    (visible05)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible05)? styles.titleTxt : styles.unSelectInfoTxt}>
                        우주펫을 통해 문의가 왔다는 것을 어떻게 알 수 있나요?
                    </span>                    
                </div>
            </div>
        </div>

        {
            (visible05)? 
            <div style={styles.colCon}>
                <span style={styles.contentTxt}>
                    우주펫 파트너에 매장 번호를 등록하면 우주펫이 안심번호를 발급해드려요.<br/>
                    발급된 안심번호로 전화 문의를 한 경우 통화 연결 시 “우주펫에서 온 돌봄 문의 전화예요.” 라는 멘트가 나와요.
                    <br/>
                    <span style={styles.contentSemiTxt}>
                        * 단, 등록된 번호가 핸드폰 번호가 아닌 경우 해당 멘트가 나오지 않을 수 있어요.
                    </span>
                </span>

                
            </div>
            : null
        }
                    

        <div style={visible06? styles.selectedCon : styles.unSelectedCon} onClick={() => {setVisible06(!visible06)}}>
            <div style={styles.rowCon}>
                {
                    (visible06)? <img src={QuestionOnIcon} style={styles.questionIcon} alt="?"/> : <img src={QuestionOffIcon} style={styles.questionIcon} alt="?"/>
                }
                <div style={styles.colCon}>
                    <span style={(visible06)? styles.titleTxt : styles.unSelectInfoTxt}>
                        우주펫 파트너 앱에서 후기를 받을 수 있나요?
                    </span>                   
                </div>               
            </div>            
        </div>

        {
            (visible06)? 
            <span style={styles.contentTxt}>
                우주펫 앱을 사용중인 고객과 일정이 완료되면 후기를 받을 수 있어요. 만약 고객이 우주펫 회원이 아니더라도 추후 우주펫에 가입하면 완료된 일정에 대해서 언제든 후기를 작성할 수 있어요.<br/>
                또한, 기존에 매장이 받은 네이버 블로그 후기가 있다면 우주펫 파트너 앱에서 노출 여부를 관리할 수 있어요. 우주펫 후기와 네이버 블로그 후기로 매장의 신뢰도를 높여보세요.
            </span>
            : null
        }

        

    </div>
}

const styles : Record<string, React.CSSProperties> = {

    title : {
        fontSize : "40px",
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "120%"
    },
    titleTxt : {
        fontSize : "28px",
        fontWeight : 600,
        letterSpacing : -0.3,        
        color : "#000000",
        lineHeight : "140%"
    },
    unSelectInfoTxt : {
        fontSize : "28px",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#454A50",
        lineHeight : "140%"
    },
    contentTxt : {
        fontSize : "24px",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#62686D",
        lineHeight : "150%",
        paddingLeft: "18px",
        paddingRight : "18px",  
        paddingTop : "22px",
        paddingBottom : "22px",
        borderRadius : "16px",
        backgroundColor : "#FAFAFB",
    },
    contentWeightTxt : {
        fontSize : "24px",
        fontWeight : 700,
        letterSpacing : -0.3,
        color : "#62686D",
        lineHeight : "150%"
    },
    contentSemiTxt : {
        fontSize : "21px",
        fontWeight : 400,
        letterSpacing : -0.3,
        color : "#9FA5AD",
        marginTop : "10px",
        lineHeight : "150%"
    },
    unSelectedCon : {
        width : "100%",
        paddingLeft: "18px",
        paddingRight : "18px",  
        paddingTop : "22px",
        paddingBottom : "22px",   
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",
        boxSizing : "border-box"
    },
    selectedCon : {
        width : "100%",
        backgroundColor : "#FFFFFF",
        paddingLeft: "18px",
        paddingRight : "18px",  
        paddingTop : "22px",
        paddingBottom : "22px",
        display : "flex",
        flexDirection : "row",
        alignItems : "flex-start",
        justifyContent : "flex-start",  
        boxSizing : "border-box"
    },
    rowCon : {
        display : "flex",
        flexDirection : 'row',
        alignItems : "flex-start",
        justifyContent : "flex-start"  
    },
    colCon : {
        display : "flex",
        flexDirection : 'column',
        alignItems : "flex-start",
        justifyContent : "flex-start",
        marginLeft : 6,
    },
    questionIcon : {
        width : "39px",
        height : "39px",
     
    }, 
    com7ColorCon : {
      backgroundColor:"#fff",
      boxSizing:"border-box",
      display:"flex",
      flexDirection:"column",
      padding : "90px 6.1111vw 120px 6.1111vw"
     
    }

}

export default Component07;