import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import NaverBlog from '../../../assets/icon/component/footer/naver_blog.svg';
import Instagram from '../../../assets/icon/component/footer/instagram.svg';
import { useNavigate } from 'react-router-dom';

const MainInfoComponent = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 


    const navigate = useNavigate();

    const pressNaverBlog = () => {
        window.open("https://blog.naver.com/spacepetofficial", "_blank")
    }

    const pressInstagram = () => {
        window.open("https://www.instagram.com/space.pet_official/", "_blank")
    }

    const onTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f2a4f4c06d887ad055?pvs=4", "_blank")
    }

    const onPrivacyTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381489396faa837ef7569?pvs=4", "_blank")
    }

    const onGeometryTerm = () => {
        window.open("https://spacepet.notion.site/14aee7caac7381f98be0daae621c0c23?pvs=4", "_blank")
    }

    const pressSitter = () => {
        navigate("/sitter")
    }

    const pressMain = () => {
        navigate("/")
    }



    return (
        <div style={styles.homeFooterCon} >

            <div style={styles.footerCon} >
                <div style={styles.footerBtnCon}>
                    <img src={NaverBlog} style={styles.footerBtn} alt="" onClick={() => pressNaverBlog()} />
                    <img src={Instagram} style={styles.footerBtn}  alt="" onClick={() => pressInstagram()} />
                </div>

                <div style={styles.footerTxtCon} >
                    <span style={styles.footerText02} onClick={() => pressMain()}>우주펫 소개</span>
                    <div style={{width : "10px"}}></div>
                    <span style={styles.footerText02}  onClick={() => pressSitter()}>이웃시터 소개</span>
                </div>
            </div>


            <a style={styles.footerBtn01} href="mailto:sungsoo@spacepet.info"><p style={styles.footerBtn02} >제휴/IR문의</p></a>
            
            <p style={styles.footerText01}>주식회사 우주펫</p>

            <div style={{height : "3vw"}}/>
            
            <div style={styles.footerTextCon}>
                <p style={styles.footerContentTxt}>대표 :&nbsp;</p>
                <p style={styles.footerContentTxt}> 박성수</p>
                
                <div style={styles.footerVertDivider}/>
                <p style={styles.footerContentTxt}>사업자등록번호 :&nbsp;</p>
                <p style={styles.footerContentTxt}>860-81-02850</p>
            </div>

            <div style={styles.footerTextCon1}>
                <p style={styles.footerContentTxt}>통신판매업신고번호 : &nbsp;</p>
                <p style={styles.footerContentTxt}>2023-서울강남-01239</p>
            </div>
            
            <div style={styles.footerTextCon1}>
                <p style={styles.footerContentTxt}>주소 :&nbsp;</p>
                <p style={styles.footerContentTxt}>서울특별시 강남구 역삼로 165 (역삼동) 7층 c호</p>
            </div>
   
            <div style={styles.footerTextCon}>
                <p style={styles.footerContentTxt}>고객센터 :&nbsp;</p>
                <p style={styles.footerContentTxt}>010.2276.0834</p>
            </div>
   
            <div style={styles.footerTextCon}>
                <p style={styles.footerContentTxt}>이메일 :&nbsp;</p>
                <p style={styles.footerContentTxt}>spacepetofficial@gmail.com</p>
            </div>

            <div style={{height : "12.5000vw"}}/>
            
            <div style={styles.footerTextCon}>
                <p style={styles.footerTextBtn} onClick={() => onTerm()}>이용약관&nbsp;&nbsp;</p>
                <p style={styles.footerTextBtn} onClick={() => onPrivacyTerm()}>개인정보처리방침&nbsp;&nbsp;</p>
                <p style={styles.footerTextBtn} onClick={() => onGeometryTerm()}>위치기반 서비스 약관&nbsp;&nbsp;</p>
            </div>
            
            <p style={styles.footerClcTxt}>spacepet.site © 2021 ALL RIGHTS RESERVED.</p>
            
            <div style={{height : "12.5000vw"}}/>

        </div>
    )
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    homeFooterCon: {width:" 100%", height:"auto", padding:"9.3750vw 0px 15.6250vw 7.5000vw", boxSizing:"border-box"},
    footerCon: { display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", paddingRight: "7.5000vw" },
    footerBtnCon: { display: "flex", flexDirection: "row", marginTop: "0px", marginBottom: "5vw" },
    footerBtn: { width: "6.8750vw", height: "auto", marginRight: "20px" },
    footerTxtCon: { display: "flex", flexDirection: "row", justifyContent: "center" },
    footerText02: { fontSize: "3.4375vw", lineHeight: "200%", color: "#62686D", fontWeight: 600, cursor: "pointer" },
    footerBtn01: { marginTop: "0px", marginBottom: "5vw", color: "#797A7C", fontWeight: 600, display: "flex", flexDirection: "row", textDecoration: "none" },
    footerText01: { fontWeight: 600, color: "#393939", fontSize: "3.7500vw", marginTop: "0px", marginBottom: "16px", lineHeight: "120%" },
    footerTextCon: { display: "flex", flexDirection: "row", alignItems: "center", justifyItems: "center", marginTop: "0px", marginBottom: "1.8750vw" },
    footerContentTxt: {color: "#726E6E", fontWeight: 500, fontSize: "3.1250vw", lineHeight: "120%", marginTop: 0, marginBottom: 0},
    footerVertDivider: {width: "0.1563vw", height: "2.5000vw", backgroundColor: "#726E6E", marginLeft: "2.5000vw", marginRight: "2.5000vw"},
    footerTextCon1: {display: "flex", flexDirection: "row", alignItems: "flex-start", justifyItems: "center", marginTop: 0, marginBottom: "1.8750vw"},
    footerTextBtn: {margin: "0 0 0 0", color: "#9FA5AD", fontWeight: 600, fontSize: "3.1250vw", letterSpacing: "-2%"},
    footerClcTxt: {color: "#AAAAAA", marginTop: "6.2500vw", marginBottom: 0, fontWeight: 500, fontSize: "2.5vw"},
    footerBtn02: {marginTop: 0, marginBottom: 0, color: "#797A7C", fontWeight: 600, fontSize: "3.7500vw", textDecoration: "none"},


});


export default MainInfoComponent