import React from 'react';
import AliceCarousel from 'react-alice-carousel';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Image01 from '../../../assets/images/main/web/component-0401.png';
import Image02 from '../../../assets/images/main/web/component-0402.png';
import Image03 from '../../../assets/images/main/web/component-0403.png';
import Image04 from '../../../assets/images/main/web/component-0404.png';
import Image05 from '../../../assets/images/main/web/component-0405.png';
import ImageComponent from '../../common/Image.component';



const Main04Component = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const [isDragging, setIsDragging] = React.useState(false);
    const [mouseDown, setMouseDown] = React.useState(false);
    const [paddingLeft, setPaddingLeft] = React.useState(9.375); // default for larger screens

    const updatePadding = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 1440) {
          // 6.1111vw를 숫자로 계산
          setPaddingLeft((screenWidth * 6.1111) / 100);
        } else {
          // 9.3750vw를 숫자로 계산
          setPaddingLeft((screenWidth * 9.3750) / 100);
        }
      };
  
    React.useEffect(() => {
      updatePadding(); // initial padding calculation
      window.addEventListener('resize', updatePadding); // update padding on window resize
  
      return () => {
        window.removeEventListener('resize', updatePadding); // clean up listener
      };
    }, []);

     // 드래그 시작
    const handleMouseDown = () => {
        setMouseDown(true);  // 마우스가 눌렸을 때
        setIsDragging(false); // 드래그 상태 초기화
    };


    // 마우스 버튼을 놓을 때
    const handleMouseUp = (e : any) => {
        if (isDragging) {
            e.preventDefault();  // 드래그 중에는 클릭이 발생하지 않도록 방지
            setTimeout(() => {
                setIsDragging(false); // 드래그 상태 초기화
            }, 500)
        }
        setMouseDown(false); // 마우스를 놓으면 마우스 상태 초기화
    };
    // 클릭 이벤트
    const handleClick = (e : any, index : number) => {

        if (isDragging) {
            console.log('드래그 중에 클릭이 발생했습니다. 실행되지 않음.');
            return;  // 클릭 이벤트를 막는다
        }

        console.log('클릭 이벤트 실행');

        if (index === 0) {
            window.open("https://blog.naver.com/spacepetofficial/222910857769",  "_blank")
        }
        else if (index === 1) {
            window.open("https://blog.naver.com/spacepetofficial/222929495479",  "_blank")
        }
        else if (index === 2) {
            window.open("https://blog.naver.com/spacepetofficial/222916631717",  "_blank")
        }
        else if (index === 3) {
            window.open("https://blog.naver.com/spacepetofficial/222929495259",  "_blank")
        }
        else if (index === 4) {
            window.open("https://blog.naver.com/spacepetofficial/222922143581",  "_blank")
        }
    };

    const dragStart = (e : any) => {
        setIsDragging(true);
        e.preventDefault();
    }

    const items = [        
        <ImageComponent         
            src={Image01} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            style={styles.webImage06}
        />,
        <ImageComponent         
            src={Image02} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            style={styles.webImage06}
        />,
        <ImageComponent         
            src={Image03} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            style={styles.webImage06}
        />,
        <ImageComponent         
            src={Image04} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            style={styles.webImage06}
        />,
        <ImageComponent         
            src={Image05} 
            alt="" 
            onMouseDown={handleMouseDown} 
            onMouseUp={handleMouseUp} 
            onClick={(e) => handleClick(e, 0)} 
            onDragStart={dragStart}         
            style={styles.webImage06}
        />,
    ];    

    const onMore = () => {
        window.open("https://blog.naver.com/spacepetofficial",  "_blank")
    }

    console.log(isDragging);

    return (
        <div style={styles.webComponent04}>
            
            <div style={styles.webTextContainer02}>
                <span style={styles.webTitleText}>
                    우주펫 유저들의 후기
                </span>
            </div>

            <div style={{height : 75}}/>

            <AliceCarousel
                autoHeight
                mouseTracking 
                disableDotsControls
                disableButtonsControls                
                autoWidth
                items={items}
                paddingLeft={paddingLeft}
                paddingRight={paddingLeft}
            />

            <div style={{height : 65}}/>
            
            <div style={styles.webButtonContainer02}>
                <div 
                    style={styles.webSelectButtonTrue}
                    onClick={() => onMore()}
                >
                    더 많은 후기 보러 가기
                </div>
            </div>

            <div style={{height : 68}}/>
        </div>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webComponent04: {paddingTop:"40px", paddingBottom:"48px", background: "linear-gradient(to bottom, #FFFFFF 50%, #EDE2FF 50%)"},
    webTextContainer02: {display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding: isMobile? "0px 0px 0px 6.1111vw":"0px 0px 0px 9.3750vw" },
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left",textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webButtonContainer02: {display: "flex", flexDirection:"row", justifyContent:"flex-start", boxSizing:"border-box", alignItems:"flex-start", width:"100%", gap:"1.3889vw" , paddingLeft: isMobile? "6.1111vw":"9.3750vw" },
    webSelectButtonTrue: {padding:"1.6667vw 2.7778vw", borderRadius:"2.5vw", border:"0.0556vw solid #9F6BFF", fontFamily:"Pretendard", fontSize:"1.8056vw", lineHeight:"2.1667vw", fontWeight:"600", letterSpacing:"-0.02em", textAlign:"center", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF", backgroundColor:"white"},
    webImage06: {width:"27.0833vw", height:"26.1111vw", margin:"0px", marginRight:"2.7778vw", padding:"0px", display:"flex", boxSizing:"border-box"},
  
});


export default Main04Component