import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentImage from '../../../assets/images/main/web/component-06.png'
import ComponentImage01 from '../../../assets/images/main/web/component-04.png';
import ComponentImage02 from '../../../assets/images/main/web/component-05.png';
import ImageComponent from '../../common/Image.component';


const MainWeb05Component = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return (
        <>  

            <div style={{position : 'relative'}}>
                <ImageComponent src={ContentImage} alt="" style={styles.webImage05}/>
                <span  style={styles.webAbsoulteTitleText} >
                    우주펫 더 알아보기
                </span>
            </div>
                              
            <div style={styles.webComponent03}>
                <div style={{height : 72}}/>

                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01} >
                        <div style={{height : 114}}/>
                        <span style={styles.webSemiTitleText}>
                            산책친구
                        </span>
    
                        <div style={{height : 6}}/>
                        <span style={styles.webTitleText} >
                            우리아이와 잘 맞는<br/>
                            산책친구 만나기
                        </span>
    
                        <div style={{height : 16}}/>
                        <span style={styles.webContentText} >
                            우주펫에서 산책 친구를 만나보세요.<br/>
                            거리∙시간대∙보호자 등에 맞게 원하는 친구를 찾을 수 있어요.
                        </span>
                    </div>
    
                    <ImageComponent 
                        src={ComponentImage01}
                        style={styles.webImage04} 
                        alt="imageComponent"
                    />
                </div>
                <div style={{height : 72}}/>
            </div>

            <div style={styles.webComponent02}>
                <div style={{height : 72}}/>
                <div style={styles.webRowContainer01}>
                    <div style={styles.webTextContainer01}>
                        <div style={{height : 114}}/>

                        <span style={styles.webSemiTitleText}>
                            응가챌린지
                        </span>

                        <div style={{height : 6}}/>

                        <span style={styles.webTitleText} >
                            반려동물 배변용품<br/>
                            쓴 만큼 돌려받기
                        </span>

                        <div style={{height : 16}}/>

                        <span style={styles.webContentText} >
                        사용한&nbsp;배변용품을&nbsp;인증하고&nbsp;쓴&nbsp;만큼&nbsp;받아보세요.<br/>
                        우주펫이&nbsp;집&nbsp;앞으로&nbsp;배변용품을&nbsp;보내드립니다!
                        </span>
                    </div>

                    <ImageComponent 
                        src={ComponentImage02}
                        style={styles.webImage04} 
                        alt="imageComponent"
                    />
                </div>

                <div style={{height : 72}}/>
            </div>
        </>
    )
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    webImage05: {width:"100vw", margin:"0px", padding:"0px", display:"flex", boxSizing:"border-box"},
    webAbsoulteTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font",textDecorationSkipInk:"none",  margin:0, position:"absolute", zIndex:"1000",top:"13.57vw", padding:isMobile? "0px 0px 0px 6.1111vw":"0px 0px 0px 9.3750vw"},
    webComponent03:{width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"#F9F5FF",alignItems:"flex-start", justifyContent:"flex-start", boxSizing:"border-box", outline:"none",  padding: isMobile ? "0px 0px 0px 6.1111vw" : "0px 0px 0px 9.3750vw"},
    webRowContainer01: {width:"100%", display:"flex", flexDirection:"row", alignItems:"flex-start", justifyContent:"space-between", padding:"0px",margin:"0px"},
    webSemiTitleText: {fontFamily:"Pretendard", fontSize:"2vw", lineHeight:"2.5vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#9F6BFF", padding:"0", margin:"0"},
    webTitleText: {fontFamily:"Pretendard", fontSize:"2.4vw", lineHeight:"3.6vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0" },
    webContentText: {fontFamily:"Pretendard", fontSize:"1.75vw", lineHeight:"2.5vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:"0", margin:"0", color:"#62686D" },
    webComponent02: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:"flex-start", boxSizing:"border-box", paddingLeft: isMobile? "6.1111vw":"9.3750vw", paddingRight:isMobile? "0vw":"0vw" },
    webImage04:{width:"54.2361vw", height:"61.1111vw", boxSizing:'border-box', padding:"0px", margin:"0px", marginRight: isMobile?"6.1111vw" : "9.375vw" },
});


export default MainWeb05Component