import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import BackgroundImage from '../../../assets/images/sitter/mobile/review_background.png';

import './sitter.styles.css';

const Content03BrowserComponent = ({...props}) : React.ReactElement => {

    const [slideIndex, setSlideIndex] = React.useState<number>(0);
    const [indicator, setIndicator] = React.useState<string>("indicator");
    const [paddingCheck, setPaddingCheck] = React.useState<boolean>(false);
    const styles = getStyles(paddingCheck); 

    const data = [
        { "content" : "우주펫에서 펫시팅을 하면 반려인 분들이 감사하다며 선물도 챙겨주셔서 이웃간의 정을 느낄 수 있었어요!", "user" : "서울시 중구 OOO 시터님" },
        { "content" : "수수료가 없어서 좋고 보호자와 소통을 할 수 있는 점이 가장 좋은 것 같아요. 돌봄 요청하는 보호자와 충분히 대화를 나누고 돌볼 수 있다는 점이 좋아요.", "user" : "경기도 광주시 OOO 시터님" },
        { "content" : "우주펫은 보호자 소개글이나, 채팅을 통해 보호자에 대해 파악 할 수 있어서 좋더라구요.", "user" : "경기도 시흥시 OOO 시터님" }
    ]

    const items = [
           
        <div style={styles.contentScreen}>          
                <span style={styles.contentTxt}>
                    {data[0].content}
                </span>
                <span style={styles.userTxt}>
                    {data[0].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>,


            <div style={styles.contentScreen}>            
                <span style={styles.contentTxt}>
                    {data[1].content}
                </span>
                <span style={styles.userTxt}>
                    {data[1].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>,
       
            <div style={styles.contentScreen}>            
                <span style={styles.contentTxt}>
                    {data[2].content}
                </span>
                <span style={styles.userTxt}>
                    {data[2].user}
                </span>

                <img src={BackgroundImage} style={styles.backgroundImage} alt="배경"/>
            </div>
      
    ];


    

    const setIndexChange = (idx : number) => {
     
        const indexString = ["a", "b", "c"]
        const animationString = indexString[slideIndex] + indexString[idx]

        console.log(animationString)

        if (idx !== slideIndex) {
            setSlideIndex(idx)
            setIndicator(`indicator ${animationString}`)
        }
  
    }
  

    // 드래그 시작 시 패딩을 없애기
    const handleMouseDown = () => {
        if (!paddingCheck) { 
            setPaddingCheck(true);
        }
    };


    return  <>
        <div style={styles.screen}>
            <span style={styles.titleTxt}>
                이웃시터들의 후기
            </span>

        <div style={styles.screenRow}>
        <div style={{ width: '100%'}}>
            <div onMouseDown={handleMouseDown}  style={{ width:"100%" , marginLeft: paddingCheck  ? "" : "50px"}}>
                <AliceCarousel 
                    paddingLeft={paddingCheck  ? 180 :0}
                    activeIndex={slideIndex}
                    infinite={true}
                    animationDuration={500}
                    mouseTracking
                    disableDotsControls
                    disableButtonsControls
                    items={items}
                    autoHeight
                    autoWidth
                    onSlideChanged={(e) => {
                        setIndexChange(e.item)
                    }}
                />
            </div>
            </div>
            <div style={{height : 20}}/>

            <div style={styles.indicatorCon}>
                <div className={indicator}/>
            </div>   
            </div>                     
    </div>
    </>
}

    const getStyles = (paddingCheck: boolean): Record<string, React.CSSProperties> => ({
    screen : {    
        display : "flex",
        flexDirection : "column",
        justifyContent : "flex-start",
        alignItems : "flex-start",
        paddingTop : 40,
        paddingBottom : 82,
        paddingRight:"6.1111vw",
        paddingLeft: paddingCheck ? "" : "6.1111vw",
        boxSizing : "border-box",
    },
    screenRow : {
        display : "flex",
        flexDirection : "column",

    },
    titleTxt : {
        fontSize : 40,
        fontWeight : 700,
        letterSpacing : -0.3,
        alignSelf : "flex-start",
        lineHeight : "140%",       
        paddingLeft: paddingCheck ? "8.2vw" : "2.1111vw",
        paddingBottom:"40px"
    },
    contentScreen : {
        width : "820px",
        height : "290px",
        boxSizing : "border-box",
        display : "flex",
        flexDirection : "column",
        justifyContent : "center",
        alignItems : "flex-start",
        paddingLeft : 40,
        paddingRight : 40,
        paddingTop : 40,
        paddingBottom : 40,
        backgroundColor : "transparent",
    },    
    contentTxt : {
        fontSize : 28,
        fontWeight : 400,
        color : "#62686D",
        lineHeight : "150%",
        paddingBottom:"10px"
    },
    userTxt : {
        fontSize : 22,
        fontWeight : 400,
        color : "#9FA5AD",
        lineHeight : "140%",
    },
    indicatorCon : {
        width : 136,
        height : 4,
        backgroundColor : "#F4F4F5",
        borderRadius : 20,
        marginLeft: paddingCheck ? "8.6vw" : "2.5vw",
    },
    backgroundImage : {
        width : "97%",
        height : "263px",
        position : "absolute",
        top : 0,
        left : 0,
        zIndex : -1,
    }
});

export default Content03BrowserComponent;