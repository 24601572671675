import React from "react";
import { CDN } from "../../../keys";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Component02 = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.gradientCon}>

        <div style={{height : 95}}/>
        
        <div style={styles.componentCon}>
            <p style={styles.webColorTitleTxt}> 
                우주펫 파트너가 되면
            </p>
        </div>

        <div style={{height : 20}}/>
        
        <div style={styles.componentCon}>
            <div style={{display : "flex", flexDirection : "column", marginTop : 108}}>
                <p style={styles.webColorSubTitleTxt}> 
                    우주펫에서만 할 수 있는
                </p> 
                <div style={{height : 6}}/>
                <p style={styles.webTitleTxt}> 
                    반려인에게 직접 제안
                </p>                
                <div style={{height : 7}}/>
                <p style={styles.webSubTitleTxt} > 
                    돌봄 요청한 고객에게 직접 제안하세요!<br/>
                    파트너는 돌봄이 필요한 반려인에게<br/>
                    먼저 돌봄을 제안할 수 있어요.
                </p>  
            </div>

            <img src={`${CDN}web/partner/browser/image_002.png`}  style={styles.componentImage}  alt=""/>
        </div>
    
        <div style={{height : 42}}/>

    </div>
}


const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    gradientCon: { background: "linear-gradient(to top, white 88%, #F9F5FF)" },
    componentCon: { display:"flex", margin:0 , flexDirection:"row", justifyContent:"space-between",alignItems:"flex-start", paddingLeft:isMobile? "6.1111vw":"9.3750vw"},
    webColorTitleTxt: { fontFamily: "Pretendard", fontSize: "40px", fontWeight: 800, lineHeight: "48px", letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#8B4DFF" },  
    webColorSubTitleTxt: { padding: 0, margin: 0, fontFamily: "Pretendard", fontSize: "26px", fontWeight: 700, lineHeight: "31.2px", letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#9F6BFF" },  
    webTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "40px", fontWeight: "700", lineHeight: "56px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#1B1D1F", },
    webSubTitleTxt: { padding: "0", margin: "0", fontFamily: "'Pretendard'", fontSize: "24px", fontWeight: "400", lineHeight: "33.6px", letterSpacing: "-0.03em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", color: "#62686D", },
    componentImage: {width:"791px", height:"880px", padding:0 , marginRight: isMobile? "6.1111vw":"9.3750vw"},

});


export default Component02;