import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
 
import Component030101 from '../../../assets/images/main/mobile/component_030101.png';
import Component030102 from '../../../assets/images/main/mobile/component_030102.png';
import Component030103 from '../../../assets/images/main/mobile/component_030103.png';
import Component030201 from '../../../assets/images/main/mobile/component_030201.png';
import Component030202 from '../../../assets/images/main/mobile/component_030202.png';
import Component030203 from '../../../assets/images/main/mobile/component_030203.png';
import Component030301 from '../../../assets/images/main/mobile/component_030301.png';
import Component030302 from '../../../assets/images/main/mobile/component_030302.png';
import Component030303 from '../../../assets/images/main/mobile/component_030303.png';

interface Main03ContentComponentProps {
    data: Array<boolean>;
}
  
const Main03ContentComponent : React.FC<Main03ContentComponentProps> = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    if (data[0]) {
        return <>
            <div style={styles.component} >
                <div style={{height : 30}}/>

                <div style={styles.textContainer01}>
                
                    <span style={styles.componentTitleText}>서로 도와주는 이웃 반려인</span>

                    <div style={{height : 32}}/>

                    <span style={styles.semiTitleText}>
                        주고받는 돌봄품앗이
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        이웃 반려인들과<br/>
                        돌봄  주고받기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        여기 품앗이를 원하는 이웃들이 모여있어요.<br/>
                        우리 동네 사람들과 도움을 주고받아 보세요.
                    </span>
                </div>

                <img 
                    src={Component030101}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>

            <div style={styles.component01}>
                <div style={styles.textContainer01}>
                    <div style={{height : 40}}/>

                    <span style={styles.semiTitleText}>
                        품앗이 이웃 찾는 방법 
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        우리 동네<br/>
                        품앗이 이웃 찾기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        먼저 품앗이 이웃을 찾는 글을 작성하고,<br/>
                        우리 동네 품앗이 이웃을 만나보세요!
                    </span>
                </div>

                <img 
                    src={Component030102}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>
            
            <div style={styles.component}>
                <div style={styles.textContainer01}>
                    <div style={{height : 40}}/>

                    <span style={styles.semiTitleText}>
                        품앗이 일정 공유
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        품앗이 이웃과<br/>
                        돌봐줄 약속 잡기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        품앗이 나눌 이웃을 찾으셨나요?<br/>
                        원하는 날짜에 약속을 잡아보세요!
                    </span>
                </div>

                <img 
                    src={Component030103}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>       
        </>
    }
    else if (data[1]) {
        return <>
            <div style={styles.component}>
                <div style={{height : 30}}/>

                <div style={styles.textContainer01}>
                    <span style={styles.componentTitleText}>전문성을 갖춘 펫시터</span>

                    <div style={{height : 32}}/>

                    <span style={styles.semiTitleText}>
                        우리 동네 전문 이웃시터
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        전문적인 펫시터에게<br/>
                        아이 돌봄 부탁하기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        전문적으로 돌봐주는 펫시터들이 모여있어요.<br/>
                        심사를 통과한 시터들이니 믿고 부탁해 보세요!
                    </span>
                </div>

                <img 
                    src={Component030201}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>

            <div style={styles.component01}>
                <div style={styles.textContainer01}>
                    <div style={{height : 40}}/>

                    <span style={styles.semiTitleText}>
                        돌봄 요청 글 작성
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        이웃들에게<br/>
                        돌봄・산책 요청하기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        돌봐줄 사람이 필요하다면 도움을 요청해 보세요.<br/>
                        도와줄 수 있는 사람을 더 빨리 찾을 수 있어요.
                    </span>
                </div>

                <img 
                    src={Component030202}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>
            
            <div style={styles.component}>
                <div style={styles.textContainer01}>
                    <div style={{height : 40}}/>

                    <span style={styles.semiTitleText}>
                        이웃 시터 찾는 방법 
                    </span>

                    <div style={{height : 6}}/>

                    <span style={styles.titleText}>
                        우리 동네<br/>
                        이웃 시터 찾기
                    </span>

                    <div style={{height : 12}}/>

                    <span style={styles.contentText}>
                        우주펫에서 원하는 시터를 찾아 연락해 보세요.<br/>
                        <span style={styles.contentBoldText}>방문∙위탁</span>이 가능한 시터들이 있답니다.
                    </span>
                </div>

                <img 
                    src={Component030203}
                    style={styles.componentImage02}
                    alt="imageComponent"
                />
            </div>       
        </>
    }
    else {
        return <>
        <div style={styles.component}>
            <div style={{height : 30}}/>

            <div style={styles.textContainer01}>
                <span style={styles.componentTitleText}>체계적인 펫호텔・유치원</span>

                <div style={{height : 32}}/>

                <span style={styles.semiTitleText}>
                    펫호텔・유치원 한 눈에 비교
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText}>
                    원하는 조건의 펫호텔・유치원<br/>
                    한 눈에 찾기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    우리 동네 펫호텔・유치원을 한 눈에 비교할 수 있어<br/>
                    원하는 조건을 쉽고 빠르게 찾을 수 있어요.
                </span>
            </div>

            <img 
                src={Component030301}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>

        <div style={styles.component01} >

            <div style={styles.textContainer01}>

                <div style={{height : 40}}/>

                <span style={styles.semiTitleText}>
                    요금표 정리
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText}>
                    깔끔하게 정리된<br/>
                    매장별 요금 비교하기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    매장별 요금표 비교 너무 복잡하지 않았나요?<br/>
                    우주펫에서 한 눈에 매장 요금 비교해 보세요!
                </span>
            </div>

            <img 
                src={Component030302}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>
        
        <div style={styles.component}>
            <div style={styles.textContainer01}>
                <div style={{height : 40}}/>

                <span style={styles.semiTitleText}>
                    쉽고 빠른 예약 요청
                </span>

                <div style={{height : 6}}/>

                <span style={styles.titleText}>
                    간편 문의로<br/>
                    간단하게 예약 요청하기
                </span>

                <div style={{height : 12}}/>

                <span style={styles.contentText}>
                    전화로 이것저것 이야기하지 않아도<br/>
                    간단한 정보만으로 10초만에 문의할 수 있어요.
                </span>
            </div>

            <img 
                src={Component030303}
                style={styles.componentImage02}
                alt="imageComponent"
            />
        </div>       
    </>
    }

}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    component: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"white", alignItems:"flex-start", justifyContent:'flex-start'},
    textContainer01: {display:"flex",flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", padding:"0px 24px 0px 24px"},
    componentTitleText:{fontFamily:"Pretendard", fontSize:"7.19vw", lineHeight:"8.63vw", fontWeight:"300", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:0, margin:0},
    semiTitleText: {fontFamily:"Pretendard", fontSize:"5vw", lineHeight:"6vw", fontWeight:"700", letterSpacing:"-0.01em", textAlign:"left", textUnderlinePosition:"from-font", textDecorationSkipInk:"none", padding:0,margin:0,color:"#9F6BFF"},
    titleText: {fontFamily:"Pretendard", fontSize:"6.87vw", lineHeight:"9.62vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none"},
    contentText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"400", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", color:"#62686D"},
    componentImage02: {width:"100vw"},
    contentBoldText: {fontFamily:"Pretendard", fontSize:"4.37vw", lineHeight:"6.13vw", fontWeight:"700", letterSpacing:"-0.03em", textAlign:"left", padding:0, margin:0, textUnderlinePosition:"from-font", textDecorationSkipInk:"none", },
    component01: {width:"100vw", display:"flex", flexDirection:"column", backgroundColor:"#F9FAFF", alignItems:"flex-start", justifyContent:"flex-start"},
});

export default Main03ContentComponent