import React from 'react';
import { CDN } from '../../../keys';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Component03 = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.partnerIntroScreen03} >

        <div  style={styles.introTxtCon} >
            <p style={styles.grdientTxt04} >
                파트너 매장을 위한 혜택
            </p>

            <div style={{height : "1.8750vw"}}/>

            <p style={styles.titleTxt02}>
                우리 매장 상단 노출
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p style={styles.contentTxt}>
                파트너 매장은 ‘호텔・유치원 찾기' 리스트 가장 상단에 위치해 연락받을 확률이 높아져요.
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_004.png`} alt="" style={styles.componentImg}/>
    </div>
}



const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    partnerIntroScreen03: { width: "100vw", height: "120vh", display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: "12.5000vw", paddingBottom: "1.5625vw", margin: "0", boxSizing: "border-box", },
    introTxtCon: {display:"flex", flexDirection:"column", width:"100vw", boxSizing:"border-box"},
    grdientTxt04: { color: "#9F6BFF", paddingLeft: "7.5vw", paddingRight: "7.5vw", margin: "0", fontFamily: "'Pretendard'", fontSize: "5.0000vw", lineHeight: "6.0000vw", fontWeight: 700, letterSpacing: "-0.01em", textAlign: "left", textUnderlinePosition: "from-font", textDecorationSkipInk: "none", textShadow: "none", }, 
    titleTxt02: { padding: 0, margin: 0, paddingLeft: '7.5vw', fontSize: '6.8750vw', fontFamily: 'Pretendard', fontWeight: 700, letterSpacing: '-3%', lineHeight: '100%', color: 'black', whiteSpace: 'nowrap', textShadow: 'none' },
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width: '100vw' },
});


export default Component03;