import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {  Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { CDN } from "../../../keys";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';


const StyledSwiper = styled(Swiper)`
    width: 100%;
    height: 100%;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
    width: 52.1063vw;
    height: 60.6897vh;
    padding: 0;
    margin: 0;
    box-sizing: border-box;

    img {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
    }
`;

const Component01 = ({...props}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    const [activeIndex, setActiveIndex] = React.useState(0);

    return (
        <> 
            <div style={{height : "10.7448vh"}}/>

            <p style={styles.titleTxt01} >
                펫호텔,유치원을 찾는<br/>
                동네 반려인 여기 있어요!
            </p>
            
            <div style={{height : "1.8750vw"}}/>

            <p style={styles.contentTxt}>
                언제까지 고객을 기다려야 하나요?<br/>
                이제 도움이 필요한 반려인을 직접 찾아보세요.
            </p>

            <div style={{height : "3vh"}}/>

            <StyledSwiper
                slidesPerView={1.5}
                centeredSlides={true}
                pagination={{ clickable: true }}
                modules={[Navigation]}
                loop={true}
                onActiveIndexChange={(value) => {
                    setActiveIndex(value.activeIndex % 9);
                }}
            >
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-001.png`} alt=""  style={activeIndex === 2 ? styles.selectedItem : styles.unSelectedItem}/>
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-002.png`} alt=""  style={activeIndex === 3 ? styles.selectedItem : styles.unSelectedItem} />
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-003.png`} alt=""  style={activeIndex === 4 ? styles.selectedItem : styles.unSelectedItem} />
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-004.png`} alt=""  style={activeIndex === 5 ? styles.selectedItem : styles.unSelectedItem}/>
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-005.png`} alt=""  style={activeIndex === 6 ? styles.selectedItem : styles.unSelectedItem} />
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-006.png`} alt=""  style={activeIndex === 7 ? styles.selectedItem : styles.unSelectedItem} />
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-007.png`} alt=""  style={activeIndex === 8 ? styles.selectedItem : styles.unSelectedItem}/>
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-008.png`} alt=""  style={activeIndex === 0 ? styles.selectedItem : styles.unSelectedItem} />
                </StyledSwiperSlide>
                <StyledSwiperSlide>
                    <img src={`${CDN}web/partner/swiper-009.png`} alt=""  style={activeIndex === 1 ? styles.selectedItem : styles.unSelectedItem}/>
                </StyledSwiperSlide>
            </StyledSwiper>
        </>

    );
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    titleTxt01: { padding: 0, margin: 0, paddingLeft: "7.5vw", fontSize: "6.8750vw", fontFamily: "'Pretendard'", fontWeight: 700, letterSpacing: "-3%", lineHeight: "9.6250vw", color: "black", whiteSpace: "nowrap", textShadow: "none", },  
    contentTxt: { padding: 0, margin: 0, paddingLeft: "7.5vw", paddingRight: "7.5vw", fontSize: "4.3750vw", fontFamily: "'Pretendard'", fontWeight: 400, letterSpacing: "-3%", lineHeight: "6.1250vw", color: "#62686D", whiteSpace: "normal", textShadow: "none", },  
    selectedItem: { transform: "scale(1)",opacity: 1 },
    unSelectedItem: { transform: "scale(0.85)", transformOrigin: "center", opacity: 0.5,}
});


export default Component01;