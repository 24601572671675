import React from 'react';
import { CDN } from '../../../keys';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const Component02 = ({...props}) : React.ReactElement => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = getStyles(isMobile); 

    return <div style={styles.partnerIntroScreen02}>

        <div>
            <p style={styles.grdientTxt03} >
                우주펫 파트너가 되면
            </p>

            <div style={{height : "12.5000vw"}}/>

            <p style={styles.titleTxt02} >
                반려인에게 직접 제안
            </p>

            <div style={{height : "2.5000vw"}}/>

            <p style={styles.contentTxt} >
                돌봄 요청한 고객에게 직접 제안하세요!<br/>
                파트너는 돌봄이 필요한 반려인에게<br/>
                먼저 돌봄을 제안할 수 있어요
            </p>
        </div>

        <img src={`${CDN}web/partner/mobile/image_002.png`} alt="" style={styles.componentImg}  />
    </div>
}

const getStyles = (isMobile: boolean): Record<string, React.CSSProperties> => ({
    partnerIntroScreen02: { width: '100vw', height: '120vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingTop: '12.5000vw', paddingBottom: '1.5625vw', margin: 0, boxSizing: 'border-box', background: 'linear-gradient(to top, white, 88%, #F9F5FF)' },
    grdientTxt03: { paddingLeft: '7.5vw', paddingRight: '7.5vw', fontFamily: 'Pretendard', fontSize: '6.2500vw', lineHeight: '7.5000vw', letterSpacing: '-0.01em', fontWeight: 800, textAlign: 'left', textUnderlinePosition: 'from-font', textDecorationSkipInk: 'none', textShadow: 'none', color: '#8B4DFF', margin: 0 },
    titleTxt02: { padding: 0, margin: 0, paddingLeft: '7.5vw', fontSize: '6.8750vw', fontFamily: 'Pretendard', fontWeight: 700, letterSpacing: '-3%', lineHeight: '100%', color: 'black', whiteSpace: 'nowrap', textShadow: 'none' },
    contentTxt: { padding: 0, margin: 0, paddingLeft: '7.5vw', paddingRight: '7.5vw', fontSize: '4.3750vw', fontFamily: 'Pretendard', fontWeight: 400, letterSpacing: '-3%', lineHeight: '6.1250vw', color: '#62686D', whiteSpace: 'normal', textShadow: 'none' },
    componentImg: { width: '100vw' },
});

export default Component02;